import React, { useEffect, useState, KeyboardEvent } from 'react';

import { ReactComponent as ChevronDownIcon } from '@/assets/icons/bx-chevron-down.svg';
import { ReactComponent as ChevronLeftIcon } from '@/assets/icons/bx-chevron-left.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/bx-x.svg';
import { Button } from '@/components/Elements';
import { PhaseNavigation } from '@/components/ProjectManagements/PhaseNavigation/Components/PhaseNavigation';

import { phaseInfo, Comment, Reply } from '../interfaces/PRSTProductionManagementInterface';

import { useAccount } from './AccountContext';
import { ReactComponent as BoldIcon } from './assets/bx-bold.svg';
import { ReactComponent as DotsHorizontalIcon } from './assets/bx-dots-horizontal-rounded.svg';
import { ReactComponent as FontcolorIcon } from './assets/bx-font-color.svg';
import { ReactComponent as ImageAddIcon } from './assets/bx-image-add.svg';
import { ReactComponent as ItalicIcon } from './assets/bx-italic.svg';
import { ReactComponent as UnderlineIcon } from './assets/bx-underline.svg';
import { ReactComponent as CommentAvatarIcon } from './assets/Comment_Avatar.svg';
import { ImageCreateView } from './ImageCreateView';
import { useUI } from './UIContext';

export const ImageView = () => {
  const {
    selectedProject,
    selectedPost,
    selectedAccount,
    accounts,
    imageComments,
    imageCommentReplys,
    contentComments,
    // contentCommentReplys,
    addContentComments,
    addContentCommentReplys,
  } = useAccount();
  const status: number = selectedPost?.status || 1;

  const [projectStatus, setProjectStatus] = useState<number>(status);

  const { setIsProjectDetail, imageComment, setImageComment, contentComment, setContentComment } = useUI();

  const [comment, setComment] = useState('');
  const [reply, setReply] = useState('');
  const { addImageComments, addImageCommentReplys } = useAccount();
  const [replyWindow, setReplyWindow] = useState<number | null>(null);
  const [replyWindow2, setReplyWindow2] = useState<number | null>(null);
  const [isComposing, setIsComposing] = useState(false);

  const phase: phaseInfo[] = [
    { name: 'イメージ案制作', value: 'create', isCurrent: projectStatus == 1, isDone: 1 < projectStatus },
    { name: 'イメージ案承認', value: 'selection', isCurrent: projectStatus == 2, isDone: 2 < projectStatus },
    { name: 'コンテンツ制作', value: 'check', isCurrent: projectStatus == 3, isDone: 3 < projectStatus },
    { name: 'コンテンツ承認', value: 'doing', isCurrent: projectStatus == 4, isDone: 4 < projectStatus },
    { name: '完了', value: 'done', isCurrent: projectStatus == 4, isDone: 4 < projectStatus },
  ];

  const handleClick1 = () => {
    setIsProjectDetail(false);
  };

  const handleImageCommentsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
  };

  const handleImageCommentReplysChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReply(e.target.value);
  };

  const handleContentCommentsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
  };

  const handleContentCommentReplysChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReply(e.target.value);
  };

  const handleAddImageComment = () => {
    if (comment !== '') {
      console.log('imageComments', imageComments);
      console.log('送信ボタンが押されました。');
      const newImageComments: Comment = {
        accountName: '',
        comment: comment,
        replys: [],
      };
      addImageComments(newImageComments);
      setComment('');
    } else {
      return;
    }
  };

  const handleReplyImageComment = (index: number) => {
    if (reply !== '') {
      const newReply: Reply = {
        accountName: '',
        reply: reply,
      };
      addImageCommentReplys(newReply, index);
      setReplyWindow(null);
      setReply('');
    } else {
      setReplyWindow(null);
      setReply('');
    }
  };

  const handleCloseReplys = (index: number) => {
    setReplyWindow2(index);
    setReplyWindow2(null);
  };

  const handleReplyInput = (index: number) => {
    setReplyWindow(index);
  };

  const handleOpenReply = (index: number) => {
    setReplyWindow2(index);
  };

  const handleCompositionStart = () => {
    setIsComposing(true);
  };

  const handleCompositionEnd = () => {
    setIsComposing(false);
  };

  const handleAddContentComment = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey && !isComposing && comment !== '') {
      event.preventDefault();
      console.log('contentComments', contentComments);
      console.log('送信ボタンが押されました。');
      const newContentComments: Comment = {
        accountName: '',
        comment: comment,
        replys: [],
      };
      addContentComments(newContentComments);
      setComment('');
    } else {
      return;
    }
  };

  const handleReplyContentComment = (index: number) => {
    if (reply !== '') {
      const newReply: Reply = {
        accountName: '',
        reply: reply,
      };
      addContentCommentReplys(newReply, index);
      setReplyWindow(null);
      setReply('');
    } else {
      setReplyWindow(null);
      setReply('');
    }
  };

  useEffect(() => {
    console.log('---ImageView.tsx---');
    console.log('選択されたアカウント（selectedAccount）:', selectedAccount);
    console.log('選択されたプロジェクト（selectedProject）:', selectedProject);
    console.log('選択されたプロジェクト（selectedPost）:', selectedPost);
    console.log('作成されたアカウント全て（accounts）:', accounts);
    console.log('プロジェクトのステータス（projectStatus）:', projectStatus);
    console.log('追加された全てのコメント', imageComments);
    console.log('追加された全ての返信', imageCommentReplys);
    setProjectStatus(status);
  }, [
    selectedAccount,
    accounts,
    selectedProject,
    selectedPost,
    projectStatus,
    status,
    imageComments,
    imageCommentReplys,
  ]);

  const CommentWindow = () => {
    return (
      <>
        <div className="absolute left-[1036px] top-0 z-20 w-[406px] bg-white px-[24px] pt-[40px] shadow-[0_10px_10px_rgba(0,0,0,0.04),0_20px_25px_rgba(0,0,0,0.1)]">
          <div className="mb-[22px] flex w-full justify-end">
            <button className="mr-[0px]">
              <CloseIcon width={24} height={24} fill="#9CA3AF" onClick={() => setImageComment(false)} />
            </button>
          </div>
          <form className="mb-[24px] h-[184px] w-[358px]">
            <label htmlFor="comment" className="h-[28px] w-[220px] text-xl font-bold leading-7">
              イメージ案へのコメント
            </label>
            <div className="my-[24px] h-[108px] w-[358px] rounded-md border border-[#007CC2] px-[16px] py-[8px]">
              <div className="h-[92px] w-[326px]">
                <textarea
                  className="block h-[58px] w-full resize-none border-0 bg-transparent px-[0px] pb-[0px] pt-[4px] ring-0 placeholder:text-gray-500 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm sm:leading-4"
                  placeholder="新規コメント"
                  value={comment}
                  onChange={handleImageCommentsChange}
                  defaultValue={''}
                />
                <div className="flex h-[34px] w-[326px] items-end justify-between">
                  <div className="flex h-[24px] w-[164px] items-center justify-between">
                    <button className="h-[16px] w-[16px]">
                      <ImageAddIcon width={16} height={16} fill="#000000" />
                    </button>
                    <div className="h-[24px] w-[1px] border-l border-[#0000001A] "></div>
                    <div className="flex h-[17px] w-[117px] items-center justify-between">
                      <button className="h-[16px] w-[16px]">
                        <BoldIcon width={16} height={16} fill="#000000" />
                      </button>
                      <button className="h-[16px] w-[16px]">
                        <ItalicIcon width={16} height={16} fill="#000000" />
                      </button>
                      <button className="h-[16px] w-[16px]">
                        <UnderlineIcon width={17} height={17} fill="#000000" />
                      </button>
                      <button className="h-[16px] w-[16px]">
                        <FontcolorIcon width={17} height={17} fill="#000000" />
                      </button>
                    </div>
                  </div>
                  <Button
                    onClick={handleAddImageComment}
                    className="h-[34px] w-[54px] whitespace-nowrap rounded-md px-[13px] py-[9px] text-sm font-medium leading-4"
                  >
                    送信
                  </Button>
                </div>
              </div>
            </div>
            <div className="h-[1px] w-[358px] border-t-[1px] border-[#DDDDDD]"></div>
          </form>
          <div className="w-[358px]">
            {imageComments.map((imageComment, index) => (
              <div key={index} className="mb-[24px] py-[8px]">
                <div className="mb-[8px] flex h-[60px] w-[358px] justify-between py-[8px]">
                  <div>
                    <CommentAvatarIcon width={24} height={24} />
                  </div>
                  <div className="h-[44px] w-[322px]">
                    <div className="flex justify-between">
                      <div className="h-[20px] text-sm font-medium leading-5 text-gray-900">Whitney Francis</div>
                      <div className="text-sm font-normal leading-5 text-gray-500">1時間前</div>
                    </div>
                    <p className="text-sm font-normal leading-5 text-gray-700">{imageComment.comment}</p>
                  </div>
                </div>
                {imageComment.replys.length > 0 ? (
                  replyWindow2 === index ? (
                    <div>
                      <button className="text-sm font-medium leading-5" onClick={() => handleCloseReplys(index)}>
                        <div className="flex h-[24px] w-[116px] justify-between">
                          <ChevronDownIcon width={24} height={24} fill="#6B7280" />
                          <div className="h-[20px] w-[84px] items-center text-sm font-medium leading-5 text-gray-500">
                            返信を閉じる
                          </div>
                        </div>
                      </button>
                      <div>
                        {imageComments[index].replys.map((reply, i) => (
                          <div key={i} className="mb-[8px] w-[358px] py-[8px] pl-[24px]">
                            <div className="mb-[8px] flex justify-between py-[8px]">
                              <div>
                                <CommentAvatarIcon width={24} height={24} />
                              </div>
                              <div className="w-[298px]">
                                <div className="flex justify-between">
                                  <div className="h-[20px] text-sm font-medium leading-5 text-gray-900">
                                    Whitney Francis
                                  </div>
                                  <div className="text-sm font-normal leading-5 text-gray-500">1時間前</div>
                                </div>
                                <p className="text-sm font-normal leading-5 text-gray-700">{reply.reply}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <button
                        className="text-sm font-medium leading-5 text-[#007CC2]"
                        onClick={() => handleOpenReply(index)}
                      >
                        <div className="flex h-[24px] w-[109px]">
                          <ChevronDownIcon width={24} height={24} fill="#007CC2" />
                          <div className="h-[20px] w-[77px] items-center text-sm font-medium leading-5">
                            返信（{imageComment.replys.length}）
                          </div>
                        </div>
                      </button>
                    </div>
                  )
                ) : null}
                {replyWindow === index ? (
                  <div className="h-[124px] w-[358px] py-[8px] pl-[24px]">
                    <div className="h-[108px] w-[334px] rounded-md border border-[#007CC2] px-[16px] py-[8px]">
                      <div className="h-[92px] w-[302px]">
                        <textarea
                          className="block h-[58px] w-full resize-none border-0 bg-transparent px-[0px] pb-[0px] pt-[4px] ring-0 placeholder:text-gray-500 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm sm:leading-4"
                          placeholder="返信"
                          value={reply}
                          onChange={handleImageCommentReplysChange}
                          defaultValue={''}
                        />
                        <div className="flex h-[34px] w-[302px] items-end justify-between">
                          <div className="flex h-[24px] w-[164px] items-center justify-between">
                            <button className="h-[16px] w-[16px]">
                              <ImageAddIcon width={16} height={16} fill="#000000" />
                            </button>
                            <div className="h-[24px] w-[1px] border-l border-[#0000001A] "></div>
                            <div className="flex h-[17px] w-[117px] items-center justify-between">
                              <button className="h-[16px] w-[16px]">
                                <BoldIcon width={16} height={16} fill="#000000" />
                              </button>
                              <button className="h-[16px] w-[16px]">
                                <ItalicIcon width={16} height={16} fill="#000000" />
                              </button>
                              <button className="h-[16px] w-[16px]">
                                <UnderlineIcon width={17} height={17} fill="#000000" />
                              </button>
                              <button className="h-[16px] w-[16px]">
                                <FontcolorIcon width={17} height={17} fill="#000000" />
                              </button>
                            </div>
                          </div>
                          <Button
                            onClick={() => handleReplyImageComment(index)}
                            className="h-[34px] w-[54px] whitespace-nowrap rounded-md px-[13px] py-[9px] text-sm font-medium leading-4"
                          >
                            送信
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {(imageComments[index].replys.length === 0 || replyWindow2 === index) && replyWindow !== index ? (
                  <div className="flex h-[24px] w-[60px] justify-between">
                    <div className="h-[24px] w-[24px]"></div>
                    <button
                      className="w-[28px] text-sm font-semibold leading-5 text-gray-500"
                      onClick={() => handleReplyInput(index)}
                    >
                      返信
                    </button>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        </div>
      </>
    );
  };

  const CommentWindow1 = () => {
    return (
      <>
        <div className="absolute left-[1036px] top-0 z-20 w-[406px] bg-white px-[24px] pt-[40px] shadow-[0_10px_10px_rgba(0,0,0,0.04),0_20px_25px_rgba(0,0,0,0.1)]">
          <div className="mb-[22px] flex w-full justify-end">
            <button className="mr-[0px]">
              <CloseIcon width={24} height={24} fill="#9CA3AF" onClick={() => setContentComment(false)} />
            </button>
          </div>
          <form className="mb-[24px] h-[121px] w-[358px]">
            <label htmlFor="comment" className="h-[28px] w-[220px] text-xl font-bold leading-7">
              コンテンツへのコメント
            </label>
            <div className="my-[24px] h-[45px] w-[358px] rounded-md border border-[#007CC2] px-[16px] py-[8px]">
              <div className="h-[92px] w-[326px]">
                <textarea
                  className="block h-[58px] w-full resize-none border-0 bg-transparent px-[0px] pb-[0px] pt-[4px] ring-0 placeholder:text-gray-500 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm sm:leading-4"
                  placeholder="新規コメント"
                  value={comment}
                  onChange={handleContentCommentsChange}
                  onKeyDown={handleAddContentComment}
                  onCompositionStart={handleCompositionStart}
                  onCompositionEnd={handleCompositionEnd}
                  defaultValue={''}
                />
              </div>
            </div>
            <div className="h-[1px] w-[358px] border-t-[1px] border-[#DDDDDD]"></div>
          </form>
          <div className="w-[358px]">
            {contentComments.map((contentComment, index) => (
              <div key={index} className="mb-[24px] py-[8px]">
                <div className="mb-[8px] flex h-[60px] w-[358px] justify-between py-[8px]">
                  <div>
                    <CommentAvatarIcon width={24} height={24} />
                  </div>
                  <div className="h-[44px] w-[322px]">
                    <div className="flex justify-between">
                      <div className="h-[20px] text-sm font-medium leading-5 text-gray-900">Whitney Francis</div>
                      <div className="text-sm font-normal leading-5 text-gray-500">1時間前</div>
                    </div>
                    <p className="text-sm font-normal leading-5 text-gray-700">{contentComment.comment}</p>
                  </div>
                </div>
                {contentComment.replys.length > 0 ? (
                  replyWindow2 === index ? (
                    <div>
                      <button className="text-sm font-medium leading-5" onClick={() => handleCloseReplys(index)}>
                        <div className="flex h-[24px] w-[116px] justify-between">
                          <ChevronDownIcon width={24} height={24} fill="#6B7280" />
                          <div className="h-[20px] w-[84px] items-center text-sm font-medium leading-5 text-gray-500">
                            返信を閉じる
                          </div>
                        </div>
                      </button>
                      <div>
                        {contentComments[index].replys.map((reply, i) => (
                          <div key={i} className="mb-[8px] w-[358px] py-[8px] pl-[24px]">
                            <div className="mb-[8px] flex justify-between py-[8px]">
                              <div>
                                <CommentAvatarIcon width={24} height={24} />
                              </div>
                              <div className="w-[298px]">
                                <div className="flex justify-between">
                                  <div className="h-[20px] text-sm font-medium leading-5 text-gray-900">
                                    Whitney Francis
                                  </div>
                                  <div className="text-sm font-normal leading-5 text-gray-500">1時間前</div>
                                </div>
                                <p className="text-sm font-normal leading-5 text-gray-700">{reply.reply}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <button
                        className="text-sm font-medium leading-5 text-[#007CC2]"
                        onClick={() => handleOpenReply(index)}
                      >
                        <div className="flex h-[24px] w-[109px]">
                          <ChevronDownIcon width={24} height={24} fill="#007CC2" />
                          <div className="h-[20px] w-[77px] items-center text-sm font-medium leading-5">
                            返信（{contentComment.replys.length}）
                          </div>
                        </div>
                      </button>
                    </div>
                  )
                ) : null}
                {replyWindow === index ? (
                  <div className="h-[124px] w-[358px] py-[8px] pl-[24px]">
                    <div className="h-[108px] w-[334px] rounded-md border border-[#007CC2] px-[16px] py-[8px]">
                      <div className="h-[92px] w-[302px]">
                        <textarea
                          className="block h-[58px] w-full resize-none border-0 bg-transparent px-[0px] pb-[0px] pt-[4px] ring-0 placeholder:text-gray-500 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm sm:leading-4"
                          placeholder="返信"
                          value={reply}
                          onChange={handleContentCommentReplysChange}
                          defaultValue={''}
                        />
                        <div className="flex h-[34px] w-[302px] items-end justify-between">
                          <div className="flex h-[24px] w-[164px] items-center justify-between">
                            <button className="h-[16px] w-[16px]">
                              <ImageAddIcon width={16} height={16} fill="#000000" />
                            </button>
                            <div className="h-[24px] w-[1px] border-l border-[#0000001A] "></div>
                            <div className="flex h-[17px] w-[117px] items-center justify-between">
                              <button className="h-[16px] w-[16px]">
                                <BoldIcon width={16} height={16} fill="#000000" />
                              </button>
                              <button className="h-[16px] w-[16px]">
                                <ItalicIcon width={16} height={16} fill="#000000" />
                              </button>
                              <button className="h-[16px] w-[16px]">
                                <UnderlineIcon width={17} height={17} fill="#000000" />
                              </button>
                              <button className="h-[16px] w-[16px]">
                                <FontcolorIcon width={17} height={17} fill="#000000" />
                              </button>
                            </div>
                          </div>
                          <Button
                            onClick={() => handleReplyContentComment(index)}
                            className="h-[34px] w-[54px] whitespace-nowrap rounded-md px-[13px] py-[9px] text-sm font-medium leading-4"
                          >
                            送信
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {(contentComments[index].replys.length === 0 || replyWindow2 === index) && replyWindow !== index ? (
                  <div className="flex h-[24px] w-[60px] justify-between">
                    <div className="h-[24px] w-[24px]"></div>
                    <button
                      className="w-[28px] text-sm font-semibold leading-5 text-gray-500"
                      onClick={() => handleReplyInput(index)}
                    >
                      返信
                    </button>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="relative">
        <div className="mb-[30px] mt-[16px] flex h-[45px] w-[1181px] items-center justify-between border-b border-gray-200 py-[11px] pl-[16px] pr-[40px]">
          <div className="flex h-[24px] w-[1149px] items-center">
            <div className="flex h-[24px] w-[628px] items-center justify-between">
              <button onClick={handleClick1}>
                <ChevronLeftIcon width={24} height={24} fill="#9CA3AF" />
              </button>
              <div className="flex h-[24px] w-[297px] items-center justify-between">
                <div className="flex h-[24px] w-[136px] items-center">
                  <p className="h-[12px] w-[136px] whitespace-nowrap align-middle text-xs font-normal leading-none text-[#374151]">
                    {selectedProject?.name}
                  </p>
                </div>
                <div className="flex h-[24px] w-[146px] items-center justify-between">
                  <p className="h-[24px] w-[70px] text-base font-bold leading-6 text-gray-700">
                    {selectedPost?.postTitle}
                  </p>
                  <p className="h-[20px] w-[68px] whitespace-nowrap rounded bg-red-100 px-[10px] py-[2px] text-xs font-medium leading-4 text-red-800">
                    {selectedPost?.postType}
                  </p>
                </div>
              </div>
              <p className="h-[20px] w-[247px] whitespace-nowrap text-sm font-normal leading-5 text-gray-500">
                {selectedPost?.postScheduleDate}
              </p>
            </div>
          </div>
          <div>
            <DotsHorizontalIcon width={24} height={24} className="ml-[24px]" />
          </div>
        </div>
        {/* <StepPanel_ImageApproving /> */}
        {projectStatus > 0 && <div className="px-[24px]">{PhaseNavigation(phase)}</div>}
        <ImageCreateView />
      </div>
      {imageComment ? <div className="absolute">{CommentWindow()}</div> : null}
      {contentComment ? <div className="absolute">{CommentWindow1()}</div> : null}
    </>
  );
};
