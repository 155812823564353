import React, { createContext, useContext, useState, useEffect } from 'react';

import {
  CreationAccountMeasure,
  CreationAccountRow,
  GetCreationAccountMeasuresOrderEnum,
  GetCreationAccountMeasuresOutput,
  GetCreationAccountMeasuresRowsEnum,
  PostCreationAccountInput,
  PostMeasureInput,
} from '@/api/creation';
import { getCreationAccountMeasures, getCreationAccounts, postCreationAccount, postMeasure } from '@/hooks/Creation';

import { Comment, AccountContextType, Post, Reply } from '../interfaces/PRSTProductionManagementInterface';

export const AccountContext = createContext<AccountContextType | undefined>(undefined);

type Props = {
  children?: React.ReactNode;
};

export const AccountProvider: React.FC<Props> = ({ children }) => {
  const [accounts, setAccounts] = useState<CreationAccountRow[]>([]);
  const [projectPageData, setProjectPageData] = useState<Omit<GetCreationAccountMeasuresOutput, 'rows'> | null>(null);
  const [projects, setProjects] = useState<CreationAccountMeasure[]>([]);
  const [posts, setPosts] = useState<Post[]>([]);
  const [selectedProject, setSelectedProject] = useState<CreationAccountMeasure | null>(null);
  const [selectedAccount, setSelectedAccount] = useState<CreationAccountRow | null>(null);
  const [selectedPost, setSelectedPost] = useState<Post | null>(null);
  const [imageComments, setImageComments] = useState<Comment[]>([]);
  // const [selectedImageComment, setSelectedImageComment] = useState<Comment | null>(null);
  const [imageCommentReplys, setImageCommentReplys] = useState<Reply[]>([]);
  const [contentComments, setContentComments] = useState<Comment[]>([]);
  const [contentCommentReplys, setContentCommentReplys] = useState<Reply[]>([]);

  const addAccount = (accountInput: PostCreationAccountInput) => {
    postCreationAccount(accountInput).then(async () => {
      const response = await getCreationAccounts();
      setAccounts(response);
      setSelectedAccount(
        response.find((account) => account.name === accountInput.name && account.name === accountInput.sns) || null
      );
    });
    console.log('---AccountContext.tsx---');
    console.log('新しく作成されたアカウント（account）:', accountInput);
  };

  const selectAccount = (selectAccount: PostCreationAccountInput) => {
    setSelectedAccount(
      accounts.find((account) => account.name === selectAccount.name && account.sns === selectAccount.sns) || null
    );
    console.log('---AccountContext.tsx---');
    console.log('選択されたアカウント（selectedAccount）:', selectedAccount);
  };

  const updateProjects = (
    accountId: string,
    rows?: GetCreationAccountMeasuresRowsEnum,
    page?: number,
    order?: GetCreationAccountMeasuresOrderEnum,
    orderBy?: string
  ) => {
    getCreationAccountMeasures(accountId, rows, page, order, orderBy).then((response) => {
      setProjects(response.rows);
      setProjectPageData(response);
    });
  };

  const addProject = (measureInput: PostMeasureInput) => {
    postMeasure(measureInput).then(async () => {
      if (selectedAccount) {
        updateProjects(selectedAccount.id);
      }
    });

    console.log('---AccountContext.tsx---');
    console.log('選択されたアカウント（selectedAccount）:', selectedAccount);
    console.log('選択されたプロジェクト（selectedProject）:', selectedProject);
  };

  const addPost = (post: Post) => {
    setPosts((prev) => [...prev, post]);
    setSelectedPost(post);
    console.log('---AccountContext.tsx---');
    console.log('選択されたアカウント（selectedAccount）:', selectedAccount);
    console.log('選択されたアカウント（selectedProject）:', selectedProject);
    console.log('選択されたプロジェクト（selectedPost）:', selectedPost);
  };

  const selectProject = (measureId: string) => {
    // TODO: measureIdが追加されたら書き換える
    setSelectedProject(projects.find((project) => project.name === measureId) || null);
    console.log('---AccountContext.tsx---');
    console.log('選択されたアカウント（selectedProject）:', selectedProject);
  };

  const changeSelectedProject = (project: CreationAccountMeasure) => {
    setSelectedProject(project);
    console.log('---AccountContext.tsx---');
    console.log('選択されたプロジェクト（selectedProject）:', selectedProject);
  };

  const addSelectedPost = (post: Post) => {
    setPosts((prev) => [...prev, post]);
  };

  const changeSelectedPost = (post: Post) => {
    setSelectedPost(post);
    console.log('---AccountContext.tsx---');
    console.log('選択されたプロジェクト（selectedPost）:', selectedPost);
  };

  const addImageComments = (comment: Comment) => {
    setImageComments((prev) => [...prev, comment]);
    console.log('追加されたイメージコメント全て', imageComments);
  };

  const addImageCommentReplys = (reply: Reply, index: number) => {
    imageComments[index].replys.push(reply);
    setImageCommentReplys((prev) => [...prev, reply]);
  };

  const addContentComments = (comment: Comment) => {
    setContentComments((prev) => [...prev, comment]);
    console.log('追加されたコンテンツコメント全て', contentComments);
  };

  const addContentCommentReplys = (reply: Reply, index: number) => {
    contentComments[index].replys.push(reply);
    setContentCommentReplys((prev) => [...prev, reply]);
  };
  // const addSelectedImageComment = (comment: Comment) => {
  //   setSelectedImageComment(comment);
  // };

  useEffect(() => {
    (async () => {
      const accounts = await getCreationAccounts();
      setAccounts(accounts);
      setSelectedAccount(accounts.length > 0 ? accounts[0] : null);
      if (accounts.length > 0) {
        updateProjects(accounts[0].id);
      } else {
        setProjects([]);
      }
    })();
    console.log('---AccountContext.tsx---');
    console.log('現在登録されているアカウント全て（accounts）:', accounts);
  }, []);

  return (
    <AccountContext.Provider
      value={{
        accounts,
        addAccount,
        selectedAccount,
        setSelectedAccount,
        selectAccount,
        projects,
        updateProjects,
        projectPageData,
        selectedProject,
        addProject,
        selectProject,
        changeSelectedProject,
        posts,
        addPost,
        selectedPost,
        addSelectedPost,
        changeSelectedPost,
        // imageComments: [],
        imageComments,
        addImageComments,
        // contentComments: [],
        imageCommentReplys,
        addImageCommentReplys,
        contentComments,
        addContentComments,
        contentCommentReplys,
        addContentCommentReplys,
        // selectedImageComment,
        // addSelectedImageComment,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export const useAccount = () => {
  const context = useContext(AccountContext);
  if (!context) {
    throw new Error('useAccount must be used within an AccountProvider');
  }
  return context;
};
