import {
  CreationAccountMeasure,
  CreationAccountRow,
  GetCreationAccountMeasuresOrderEnum,
  GetCreationAccountMeasuresOutput,
  GetCreationAccountMeasuresRowsEnum,
  PostCreationAccountInput,
  PostMeasureInput,
} from '@/api/creation';

export interface phaseInfo {
  name: string;
  value: 'create' | 'selection' | 'check' | 'doing' | 'done';
  isCurrent: boolean;
  isDone: boolean;
}

export interface Images {
  image: string;
  imageDescription: string;
}

export interface Reply {
  accountName: string;
  reply: string;
}
export interface Comment {
  accountName: string;
  comment: string;
  replys: Reply[];
}
// export interface Contents {
//   images: Images[];
//   text: string;
// }

// export interface ImageProposal {
//   images: Images[];
//   text: string;
// }

export enum postScheduleDate {
  postScheduleDate = '2021年2月12日（金）',
}

export enum PostType {
  slect = '選択してください',
  Reel = 'リール',
  Story = 'ストーリー',
  Feed = 'フィード',
}

export enum SNSType {
  slect = '選択してください',
  Instagram = 'Instagram',
  Twitter = 'Twitter',
  YouTube = 'YouTube',
  TikTok = 'TikTok',
  Other = 'その他',
}

export interface Post {
  postTitle: string;
  postType: PostType;
  postScheduleDate: postScheduleDate;
  status: number;
  // imageProposal: ImageProposal[];
  // contents: Contents[];
  imageApproved: boolean;
  contentApproved: boolean;
}

export interface AccountContextType {
  accounts: CreationAccountRow[];
  addAccount: (accountInput: PostCreationAccountInput) => void;
  selectAccount: (selectAccount: PostCreationAccountInput) => void;
  selectedAccount: CreationAccountRow | null;
  setSelectedAccount: (account: CreationAccountRow | null) => void;
  projects: CreationAccountMeasure[];
  updateProjects: (
    accountId: string,
    rows?: GetCreationAccountMeasuresRowsEnum,
    page?: number,
    order?: GetCreationAccountMeasuresOrderEnum,
    orderBy?: string
  ) => void;
  projectPageData: Omit<GetCreationAccountMeasuresOutput, 'rows'> | null;
  addProject: (measureInput: PostMeasureInput) => void;
  selectProject: (measureId: string) => void;
  changeSelectedProject: (project: CreationAccountMeasure) => void;
  selectedProject: CreationAccountMeasure | null;
  posts: Post[];
  addPost: (post: Post) => void;
  selectedPost: Post | null;
  addSelectedPost: (post: Post) => void;
  changeSelectedPost: (post: Post) => void;
  imageComments: Comment[];
  imageCommentReplys: Reply[];
  contentComments: Comment[];
  contentCommentReplys: Reply[];
  // contentComments: ImageComment;
  addImageComments: (comment: Comment) => void;
  addImageCommentReplys: (reply: Reply, index: number) => void;
  addContentComments: (comment: Comment) => void;
  addContentCommentReplys: (reply: Reply, index: number) => void;
  // selectedImageComment: Comment | null;
  // addSelectedImageComment: (comment: Comment) => void;
  // addContentComments: (comment: ImageComment) => void;
  // replys: reply[];
}

export interface AccounProviderProps {
  children: React.ReactNode;
}
