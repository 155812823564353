import { ReactComponent as ChevronLeftIcon } from '@/assets/icons/bx-chevron-left.svg';
import { ReactComponent as PencilImage } from '@/assets/icons/bx-pencil.svg';
import { Button } from '@/components/Elements';

import { useAccount } from './AccountContext';
import { ReactComponent as DotsHorizontalIcon } from './assets/bx-dots-horizontal-rounded.svg';
import { ReactComponent as InformationImage } from './assets/Information carousel_Two Color 1.svg';
import { StepPanel_ImageApproving } from './StepPanel_ImageApproving';

export const ContentView = () => {
  const { projects } = useAccount();

  const latestProjectName = projects.length > 0 ? projects[projects.length - 1].name : '';
  const latestPostTitle = projects.length > 0 ? projects[projects.length - 1].name : '';

  return (
    <div>
      <div className="mb-[30px] mt-[16px] flex h-[45px] w-[1181px] items-center justify-between border-b border-gray-200 py-[11px] pl-[16px] pr-[40px]">
        <div className="flex h-[24px] w-[1149px] items-center">
          <div className="flex h-[24px] w-[628px] items-center justify-between">
            <ChevronLeftIcon width={24} height={24} fill="#9CA3AF" />
            <div className="flex h-[24px] w-[297px] items-center justify-between">
              <div className="flex h-[24px] w-[136px] items-center">
                <p className="h-[12px] w-[136px] whitespace-nowrap align-middle text-xs font-normal leading-none text-[#374151]">
                  {latestProjectName}
                </p>
              </div>
              <div className="flex h-[24px] w-[146px] items-center justify-between">
                <p className="h-[24px] w-[70px] text-base font-bold leading-6 text-gray-700">{latestPostTitle}</p>
                <p className="h-[20px] w-[68px] whitespace-nowrap rounded bg-red-100 px-[10px] py-[2px] text-xs font-medium leading-4 text-red-800">
                  フィード
                </p>
              </div>
            </div>
            <p className="h-[20px] w-[247px] whitespace-nowrap text-sm font-normal leading-5 text-gray-500">
              投稿予定日：2021年2月12日(金) 18:00
            </p>
          </div>
        </div>
        <div>
          <DotsHorizontalIcon width={24} height={24} className="ml-[24px]" />
        </div>
      </div>
      <StepPanel_ImageApproving />
      {/* <div>ステップパネルを入れる</div> */}
      <div className="w-[1133px] px-[24px] py-[25px]">
        <div className="flex h-[525px] w-[1133px] items-center justify-center rounded bg-[#FFFFFF] p-[24px] shadow-[0_1px_2px_rgba(0,0,0,0.06),0_1px_3px_rgba(0,0,0,0.1)]">
          <div className="h-[478px] w-[1085px]">
            <div className="mb-[24px] h-[28px] w-[92px]">
              <p className="text-lg font-semibold leading-7 text-gray-500">コンテンツ</p>
            </div>
            <div className="flex h-[326px] w-[1085px] items-center justify-center py-[48px]">
              <div className="h-[230px] w-[252px] pb-[4px]">
                <div className="mb-[24px] h-[164px] w-[252px]">
                  <InformationImage width={252} height={136} />
                  <div className="mt-[8px] flex h-[20px] w-[252px] justify-center">
                    <p className="w-[252px] text-center text-[14px] font-normal leading-5 text-[#6B7280]">
                      コンテンツがまだ制作されていません。
                    </p>
                  </div>
                </div>
                <Button startIcon={<PencilImage fill="#FFFFFF" className="h-[20px] w-[20px]" />}>
                  コンテンツを制作する
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
