import { useEffect, useState, useRef } from 'react';

import { AnalysisSpiritPostContentTikTok, AnalysisSpiritPostInsightTikTok, SnsType } from '@/api/analysisSpirit';
import { ReactComponent as ChenverDown } from '@/assets/icons/chevron-down.svg';
import { ReactComponent as ChenverUp } from '@/assets/icons/chevron-up.svg';
import NoImage from '@/assets/images/NoImage.png';
import { fetchInfluencerApplicantDetail } from '@/hooks/Influencer';
import { getImageUrlIfRelative } from '@/utils/image';

import { postProps } from '../../../interfaces/analysisInterface';
import ModalCommon from '../modals/modalCommon';
import { MediaProps, tableBodyCommonProps, TikTokContent } from '../modals/modalInterface';
import { SummaryPostCommon } from '../modals/tabs/summaryCommon';

export interface tableBodyProps {
  icon: string;
  id: string;
  applyingInfluencerId: string;
  postedAt: string;
  mediaType: string;
  image: string;
  engagementRate: string;
  numberOfEngagements: number;
  goods: number;
  comment: number;
  repost: number;
  sumViewNum: number;
  postCount: number;
  mediaProps: MediaProps;
}

interface ascInfoProps {
  id: boolean;
  viewCount: boolean;
  engagementRate: boolean;
  engagementCount: boolean;
  goodCount: boolean;
  commentCount: boolean;
  shareCount: boolean;
}

export interface PostTiktokProps {
  postTasks: postProps[];
}

export default function PostTiktok(props: PostTiktokProps) {
  const [rowTableBody, setRowTableBody] = useState<tableBodyProps[]>([]);
  const [tableBody, setTableBody] = useState<tableBodyProps[]>([]);
  const [ascInfo, setAscInfo] = useState<ascInfoProps>({
    id: true,
    viewCount: true,
    engagementRate: true,
    engagementCount: true,
    goodCount: true,
    commentCount: true,
    shareCount: true,
  });

  const startPage = 1;

  // 初期画面に表示するテーブルデータ作成用ステート
  const [currentData, setCurrentData] = useState<tableBodyProps[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const tikTokContent: TikTokContent = {
    postImage: '',
  };
  const [modalInformation, setModalInformation] = useState<tableBodyCommonProps>({
    icon: '',
    id: '',
    applyingInfluencerId: '',
    postedAt: '',
    mediaType: '',
    image: '',
    engagementRate: '',
    numberOfEngagements: 0,
    goods: 0,
    comment: 0,
    repost: 0,
    sumViewNum: 0,
    postCount: 0,
    mediaProps: {
      snsType: SnsType.TikTok,
      content: tikTokContent,
    },
  });
  //何回目の投稿かを保持するステート
  const postTask = props.postTasks[0] ?? '';
  const task = postTask.task ?? '';
  const taskId = task.taskId ?? '';
  const [selectedPost, setSelectedPost] = useState<string>(taskId);

  const setPostTask = (taskId: string) => {
    setSelectedPost(taskId);
  };

  const handleSort = (name: keyof tableBodyProps, value: boolean) => {
    const tmp = rowTableBody;
    if (value) {
      tmp.sort((a: tableBodyProps, b: tableBodyProps) => (a[name] > b[name] ? 1 : -1));
    } else {
      tmp.sort((a: tableBodyProps, b: tableBodyProps) => (a[name] < b[name] ? 1 : -1));
    }
    setCurrentData(tmp);

    setAscInfo({ ...ascInfo, [name]: value });
  };

  const moveRef_header = useRef<HTMLDivElement>(null);
  const moveRef_body = useRef<HTMLDivElement>(null);
  const scrollLimit = 280;

  const scroll_event = (e: any) => {
    if (moveRef_header.current && moveRef_body.current) {
      moveRef_header.current.scrollLeft = e.target.scrollLeft < scrollLimit ? e.target.scrollLeft : scrollLimit;
      moveRef_body.current.scrollLeft = e.target.scrollLeft < scrollLimit ? e.target.scrollLeft : scrollLimit;
    }
  };

  const pages: number[] = [];
  const totalPages = rowTableBody.length;

  const pageNationCount = totalPages > 7 ? 7 : totalPages;

  for (let i = 0; i < pageNationCount; i++) {
    if (totalPages < startPage + i) break;
    pages.push(startPage + i);
  }

  const handleOpenModal = (info: tableBodyProps) => {
    setModalInformation(info);
    setOpen(true);
  };

  const tablebodies = (): tableBodyProps[] => {
    const tmpPostTask = props.postTasks.filter((p) => p.task.taskId == selectedPost)[0];
    console.log('tmpPostTask:' + JSON.stringify(tmpPostTask, null, 2));
    if (!tmpPostTask) return [];
    const result: tableBodyProps[] = [];
    tmpPostTask.posts.posts.forEach((post, index) => {
      const insight: AnalysisSpiritPostInsightTikTok = post.insight as AnalysisSpiritPostInsightTikTok;
      const content: AnalysisSpiritPostContentTikTok = post.content as AnalysisSpiritPostContentTikTok;
      result.push({
        icon: post.account.snsAccountThumbnail,
        id: post.account.snsAccountNo,
        applyingInfluencerId: post.account.applyingInfluencerId,
        postedAt: post.content?.postedAt ?? '',
        engagementRate: insight ? `${insight.engagementRate}` : '0',
        numberOfEngagements: insight ? insight.engagementCount : 0,
        goods: insight ? insight.goodCount : 0,
        comment: insight ? insight.commentCount : 0,
        repost: insight ? insight.engagementCount : 0,
        sumViewNum: insight ? insight.viewCount : 0,
        // MEMO： +1しないと0回目投稿から始まるため
        postCount: index + 1,
        mediaProps: {
          snsType: SnsType.TikTok,
          content: {
            postImage: getImageUrlIfRelative(content?.postImage ?? ''),
            caption: content?.caption ?? '',
          },
        },
      } as tableBodyProps);
    });
    return result;
  };

  useEffect(() => {
    const bodies = tablebodies();
    setTableBody(bodies);
    setRowTableBody(bodies);
    console.log('tableBody', tableBody);
    console.log('props', props);

    // 初期画面に表示するテーブルデータ作成
    // const data = bodies.slice(0, perPage);
    setCurrentData(bodies);
  }, []);

  const sortComponent = (isAsc: boolean, name: keyof tableBodyProps) => {
    return (
      <div className="rounded-[4px] bg-gray-200">
        <div className="flex h-5 w-5 items-center  justify-center ">
          {isAsc ? (
            <ChenverDown width={14} height={14} onClick={() => handleSort(name, false)} stroke="#9CA3AF" />
          ) : (
            <ChenverUp width={14} height={14} onClick={() => handleSort(name, true)} stroke="#9CA3AF" />
          )}
        </div>
      </div>
    );
  };

  const emptyGetAnalysisSpiritPostTaskPostsOutput = {
    posts: [
      {
        account: {
          snsAccountNo: '',
          snsAccountThumbnail: '',
          applyingInfluencerId: '',
        },
        content: {
          postImage: '',
          postVideo: '',
          postMedias: [],
          multiMediaImages: [],
          mediaType: 'video',
          caption: '',
          postedAt: '',
        },
        insight: {
          engagementRate: 0,
          engagementCount: 0,
          goodCount: 0,
          replyCount: 0,
          repostCount: 0,
          viewCount: 0,
        },
      },
    ],
  };

  const emptyPostProps = {
    task: {
      taskId: '',
      taskName: '',
      taskType: '',
      taskStatus: '',
      taskStatusText: '',
      taskStatusColor: '',
      taskStatusIcon: '',
      taskStatusIconColor: '',
    },
    posts: emptyGetAnalysisSpiritPostTaskPostsOutput,
  };

  const emptyAnalysisSpiritPostInsightTikTok = {
    engagementRate: 0,
    engagementCount: 0,
    goodCount: 0,
    replyCount: 0,
    repostCount: 0,
    viewCount: 0,
  };

  const selectPostTab = () => {
    const tmpPostTask = props.postTasks.filter((p) => p.task.taskId == selectedPost)[0];
    const tmpPosts = tmpPostTask ?? emptyPostProps;
    const tmpGetAnalysisSpiritPostTaskPostsOutput = tmpPosts.posts ?? emptyGetAnalysisSpiritPostTaskPostsOutput;
    const tmpSummary = tmpGetAnalysisSpiritPostTaskPostsOutput.summary as AnalysisSpiritPostInsightTikTok;
    const summary = tmpSummary ?? emptyAnalysisSpiritPostInsightTikTok;
    return SummaryPostCommon(
      'w-[366px]',
      SnsType.TikTok,
      summary.viewCount ? summary.viewCount.toLocaleString() : '集計中',
      summary.engagementRate ? summary.engagementRate.toLocaleString() : '集計中',
      summary.engagementCount ? summary.engagementCount.toLocaleString() : '集計中',
      summary.goodCount ? summary.goodCount.toLocaleString() : '集計中',
      summary.commentCount ? summary.commentCount.toLocaleString() : '集計中',
      summary.shareCount ? summary.shareCount.toLocaleString() : '集計中'
    );
  };

  return (
    <div className="flex flex-col items-start gap-6 self-stretch p-6">
      <div className="flex flex-col items-start gap-10 self-stretch">
        <div className="flex flex-col items-start gap-10 self-stretch">
          <div className="flex items-center gap-6">
            <div className="flex h-[34px] flex-col items-start">
              <div className="w-[177px]">
                <select
                  id="graph-type"
                  className="flex w-[190px] flex-1 items-center justify-center gap-2 rounded-md border border-gray-300 py-[9px] pl-[17px] pr-[13px] text-sm font-medium leading-5 text-gray-700"
                  defaultValue={selectedPost}
                  onChange={(e) => {
                    setPostTask(e.target.value);
                    // 投稿回数を変更する度にテーブルの内容を変更するために下記２つの関数を入れています。
                    setTableBody(tablebodies());
                    setRowTableBody(tablebodies());
                  }}
                >
                  {props.postTasks.map((postTask, index) => {
                    return (
                      <option key={postTask.task.taskId} value={postTask.task.taskId}>
                        {index + 1}回目投稿
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className="flex w-[740px] flex-col items-start gap-4">
            <div className="flex flex-col items-start gap-1 self-stretch">
              <span className="text-sm font-bold">投稿サマリー</span>
            </div>
            {selectPostTab()}
          </div>
          <div className="flex flex-col items-start gap-4 self-stretch">
            <span className="text-sm font-bold">投稿一覧</span>
            <div className="flex flex-col items-start self-stretch">
              <div className="flex flex-col items-start gap-2 rounded-sm border border-gray-200">
                <div>
                  <div className="divide-y divide-gray-200 rounded-t-lg bg-white">
                    <div
                      className="w-[1050px] overflow-auto scrollbar-hide"
                      ref={moveRef_header}
                      onScroll={(event) => scroll_event(event)}
                    >
                      <div className="sticky top-0 inline-block">
                        <div className="flex h-[56px]">
                          <div className="w-[100px] items-center bg-white px-6 py-[14px]">
                            <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                              アイコン
                            </span>
                          </div>
                          <div className="w-[152px] items-center bg-white px-[17px] py-5">
                            <div className="flex items-center gap-2">
                              <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                ID
                              </span>
                            </div>
                          </div>
                          <div className="w-[120px] items-center bg-white px-6 py-[14px]">
                            <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                              サムネイル
                            </span>
                          </div>
                          <div className="w-[152px] items-center bg-white px-6 py-4">
                            <div className="flex flex-1 items-center gap-2">
                              <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                再生回数
                              </span>
                              {sortComponent(ascInfo.viewCount, 'sumViewNum')}
                            </div>
                          </div>
                          <div className="w-[200px] items-center bg-white px-6 py-4">
                            <div className="flex flex-1 items-center gap-2">
                              <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                エンゲージメント率
                              </span>
                              {sortComponent(ascInfo.engagementRate, 'engagementRate')}
                            </div>
                          </div>
                          <div className="w-[200px] items-center bg-white px-6 py-4">
                            <div className="flex flex-1 items-center gap-2">
                              <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                エンゲージメント数
                              </span>
                              <div className="rounded-[4px] bg-gray-200">
                                <div className="flex h-5 w-5 items-center  justify-center ">
                                  {ascInfo.engagementCount ? (
                                    <ChenverDown
                                      width={14}
                                      height={14}
                                      onClick={() => handleSort('numberOfEngagements', false)}
                                      stroke="#9CA3AF"
                                    />
                                  ) : (
                                    <ChenverUp
                                      width={14}
                                      height={14}
                                      onClick={() => handleSort('numberOfEngagements', true)}
                                      stroke="#9CA3AF"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="w-[132px] items-center bg-white px-6 py-4">
                            <div className="flex flex-1 items-center gap-2">
                              <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                いいね数
                              </span>
                              <div className="rounded-[4px] bg-gray-200">
                                <div className="flex h-5 w-5 items-center  justify-center ">
                                  {ascInfo.goodCount ? (
                                    <ChenverDown
                                      width={14}
                                      height={14}
                                      onClick={() => handleSort('goods', false)}
                                      stroke="#9CA3AF"
                                    />
                                  ) : (
                                    <ChenverUp
                                      width={14}
                                      height={14}
                                      onClick={() => handleSort('goods', true)}
                                      stroke="#9CA3AF"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="w-[142px] items-center bg-white px-6 py-4">
                            <div className="flex flex-1 items-center gap-2">
                              <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                コメント数
                              </span>
                              <div className="rounded-[4px] bg-gray-200">
                                <div className="flex h-5 w-5 items-center  justify-center ">
                                  {ascInfo.commentCount ? (
                                    <ChenverDown
                                      width={14}
                                      height={14}
                                      onClick={() => handleSort('comment', false)}
                                      stroke="#9CA3AF"
                                    />
                                  ) : (
                                    <ChenverUp
                                      width={14}
                                      height={14}
                                      onClick={() => handleSort('comment', true)}
                                      stroke="#9CA3AF"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="w-[132px] items-center bg-white px-6 py-4">
                            <div className="flex flex-1 items-center gap-2">
                              <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                シェア数
                              </span>
                              <div className="rounded-[4px] bg-gray-200">
                                <div className="flex h-5 w-5 items-center  justify-center ">
                                  {ascInfo.shareCount ? (
                                    <ChenverDown
                                      width={14}
                                      height={14}
                                      onClick={() => handleSort('repost', false)}
                                      stroke="#9CA3AF"
                                    />
                                  ) : (
                                    <ChenverUp
                                      width={14}
                                      height={14}
                                      onClick={() => handleSort('repost', true)}
                                      stroke="#9CA3AF"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="max-h-[210px] w-[1050px] divide-y divide-gray-200 overflow-auto"
                      onScroll={(event) => scroll_event(event)}
                      ref={moveRef_body}
                    >
                      {/* {tableBody.map((body: tableBodyProps, index: number) => { */}
                      {currentData.every((item) => Object.values(item).every((value) => value === '')) ||
                      currentData.length === 0 ? (
                        <div className="flex justify-center py-20 text-2xl">
                          投稿一覧は、投稿が行われた後に表示されます
                        </div>
                      ) : (
                        currentData.map((body: tableBodyProps, index: number) => {
                          return (
                            <div
                              key={index}
                              onClick={() => handleOpenModal(body)}
                              onKeyDown={() => handleOpenModal(body)}
                              className={
                                'flex w-[1902px] items-center hover:cursor-pointer hover:bg-gray-100' +
                                (index % 2 == 0 ? '' : ' bg-gray-50')
                              }
                              role="presentation"
                            >
                              <div className="w-[98px]  px-6">
                                <img src={body.icon} alt="" />
                              </div>
                              <div className="w-[152px] truncate p-4">
                                <span>{body.id}</span>
                              </div>
                              <div className="w-[120px] px-6">
                                <img
                                  src={body.mediaProps.content.postImage ?? NoImage}
                                  alt=""
                                  className="h-10 w-10 rounded-md object-cover"
                                />
                              </div>
                              <div className="w-[152px] px-6 py-4">
                                <span>{body.sumViewNum.toLocaleString()}</span>
                              </div>
                              <div className="w-[200px] px-6 py-4">
                                <span>{body.engagementRate}%</span>
                              </div>

                              <div className="w-[200px] px-6 py-4">
                                <span>{body.numberOfEngagements.toLocaleString()}</span>
                              </div>
                              <div className="w-[132px] px-[30px] py-4">
                                <span>{body.goods.toLocaleString()}</span>
                              </div>
                              <div className="w-[142px] px-[30px] py-4">
                                <span>{body.comment.toLocaleString()}</span>
                              </div>
                              <div className="w-[132px] px-[30px] py-4">
                                <span>{body.repost.toLocaleString()}</span>
                              </div>
                            </div>
                          );
                        })
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalCommon
        open={open}
        setOpen={setOpen}
        accountInformation={modalInformation}
        initialTabKey="postInsight"
        modalType={'sns'}
        getInfluencerData={fetchInfluencerApplicantDetail}
      />
    </div>
  );
}
