import { useEffect, useReducer, useState } from 'react';

import {
  AnalysisSpiritPostContentYoutube,
  AnalysisSpiritPostInsightYoutube,
  AnalysisSpiritPostYoutube,
  GetAnalysisSpiritPostTaskPostsOutput,
} from '@/api/analysisSpirit';
import { ReactComponent as ChenverDown } from '@/assets/icons/chevron-down.svg';
import { ReactComponent as ChenverUp } from '@/assets/icons/chevron-up.svg';
// eslint-disable-next-line no-restricted-imports, import/order
import { SNS_TYPES } from '@/config';

//import ModalYoutube from '../modals/modalYoutube';
import { fetchInfluencerApplicantDetail } from '@/hooks/Influencer';
import { getImageUrl, getImageUrlIfRelative } from '@/utils/image';

import { postProps } from '../../../interfaces/analysisInterface';
import ModalCommon from '../modals/modalCommon';
import { MediaProps, tableBodyCommonProps, XContent } from '../modals/modalInterface';
import { SummaryPost } from '../youtube/summary';

type PostYoutubeProps = {
  postTasks: postProps[];
};

type Content = {
  icon: string;
  id: string;
  thumbnail: string;
  viewCount: number;
  engagementRate: number;
  engagementCount: number;
  goodCount: number;
  commentCount: number;
};

type Summary = {
  engagementCount: number;
  engagementRate: number;
  goodCount: number;
  commentCount: number;
  viewCount: number;
};
type SortKey = keyof Content;
type SortInfo = Record<SortKey, boolean>;

type PostInfo = {
  summary: Summary;
  contents: Content[];
  postCount: number;
  sort: SortInfo;
};
type PostAction =
  | { type: 'postCount'; payload: { postCount: number; post: GetAnalysisSpiritPostTaskPostsOutput } }
  | { type: 'sort'; payload: { key: SortKey; sort: boolean } };

type PostListHeaderItem = {
  itemName: SortKey;
  displayName: string;
  css: string;
  isSort: boolean;
};

type PostListHeaderItems = PostListHeaderItem[];

export interface tableBodyProps {
  icon: string;
  id: string;
  applyingInfluencerId: string;
  postedAt: string;
  mediaType: string;
  image: string;
  engagementRate: string;
  numberOfEngagements: number;
  goods: number;
  comment: number;
  repost: number;
  sumViewNum: number;
  postCount: number;
  mediaProps: MediaProps;
}

const setPostInfo = (post: GetAnalysisSpiritPostTaskPostsOutput, postCount: number): PostInfo => {
  const summary = post.summary as AnalysisSpiritPostInsightYoutube;
  const youtubeContents = post.posts as AnalysisSpiritPostYoutube[];
  const contents = youtubeContents.map((content: AnalysisSpiritPostYoutube) => {
    return {
      icon: content.account.snsAccountThumbnail ?? '',
      id: content.account.snsAccountNo,
      thumbnail: content.content?.postImage ? getImageUrl(content.content.postImage) : '',
      viewCount: content.insight?.viewCount ?? 0,
      engagementRate: content.insight?.engagementRate ?? 0,
      engagementCount: content.insight?.engagementCount ?? 0,
      goodCount: content.insight?.goodCount ?? 0,
      commentCount: content.insight?.commentCount ?? 0,
    } as Content;
  });
  return {
    summary: {
      engagementCount: summary.engagementCount ?? null,
      engagementRate: summary.engagementRate ?? null,
      goodCount: summary.goodCount ?? null,
      commentCount: summary.commentCount ?? null,
      viewCount: summary.viewCount ?? null,
    },
    contents: contents,
    postCount: postCount,
    sort: {
      icon: false,
      id: false,
      thumbnail: false,
      viewCount: false,
      engagementRate: false,
      engagementCount: false,
      goodCount: false,
      commentCount: false,
    },
  } as PostInfo;
};
const reducer = (state: PostInfo, action: PostAction) => {
  switch (action.type) {
    case 'postCount':
      return setPostInfo(action.payload.post, action.payload.postCount);
    case 'sort':
      Object.keys(state.sort).forEach((key) => {
        state.sort[key as SortKey] = false;
      });
      state.sort = { ...state.sort, ...{ [action.payload.key]: action.payload.sort } };
      // 降順
      if (action.payload.sort) {
        state.contents = [
          ...state.contents.sort((prev: Content, next: Content) =>
            prev[action.payload.key] < next[action.payload.key] ? 1 : -1
          ),
        ];
        return { ...state };
      }
      // 昇順
      state.contents = [
        ...state.contents.sort((prev: Content, next: Content) =>
          prev[action.payload.key] > next[action.payload.key] ? 1 : -1
        ),
      ];
      return { ...state };
    default:
      return {
        summary: {
          engagementCount: 0,
          engagementRate: 0,
          goodCount: 0,
          commentCount: 0,
          viewCount: 0,
        },
        contents: [],
        postCount: 0,
        sort: {
          icon: true,
          id: true,
          thumbnail: true,
          viewCount: true,
          engagementRate: true,
          engagementCount: true,
          goodCount: true,
          commentCount: true,
        },
      } as PostInfo;
  }
};

const postListHeaderItems: PostListHeaderItems = [
  {
    itemName: 'icon',
    displayName: 'アイコン',
    css: 'w-[100px] items-center bg-white px-6 py-4',
    isSort: false,
  },
  {
    itemName: 'id',
    displayName: 'ID',
    css: 'w-[152px] items-center bg-white px-6 py-[18px]',
    isSort: false,
  },
  {
    itemName: 'thumbnail',
    displayName: 'サムネイル',
    css: 'w-[120px] items-center bg-white px-6 py-4',
    isSort: false,
  },
  {
    itemName: 'viewCount',
    displayName: '視聴回数',
    css: 'flex w-[152px] items-center bg-white px-6 py-4',
    isSort: true,
  },
  {
    itemName: 'engagementRate',
    displayName: 'エンゲージメント率',
    css: 'flex w-[200px] items-start  bg-white px-6 py-4',
    isSort: true,
  },
  {
    itemName: 'engagementCount',
    displayName: 'エンゲージメント数',
    css: 'w-[200px] items-center bg-white px-6 py-4',
    isSort: true,
  },
  {
    itemName: 'goodCount',
    displayName: 'いいね数',
    css: 'w-[132px] items-center bg-white px-5 py-4',
    isSort: true,
  },
  {
    itemName: 'commentCount',
    displayName: 'コメント数',
    css: 'w-[142px] items-center bg-white px-6 py-4',
    isSort: true,
  },
];

export default function PostYoutube(props: PostYoutubeProps) {
  const [state, dispatch] = useReducer(reducer, setPostInfo(props.postTasks[0].posts, 0));
  const [currentData, setCurrentData] = useState<tableBodyProps[]>([]);

  const [open, setOpen] = useState<boolean>(false);

  const postTask = props.postTasks[0] ?? '';
  const task = postTask.task ?? '';
  const taskId = task.taskId ?? '';
  const [selectedPost, setSelectedPost] = useState<string>(taskId);

  const setPostTask = (taskId: string) => {
    setSelectedPost(taskId);
  };

  const xContent: XContent = {
    postImage: '',
    postVideo: '',
    postMedias: null,
    multiMediaImages: [],
    mediaType: 'video',
    caption: '',
  };
  const [modalInformation, setModalInformation] = useState<tableBodyCommonProps>({
    icon: '',
    id: '',
    applyingInfluencerId: '',
    postedAt: '',
    mediaType: '',
    image: '',
    engagementRate: '',
    numberOfEngagements: 0,
    goods: 0,
    comment: 0,
    repost: 0,
    sumViewNum: 0,
    postCount: 0,
    mediaProps: {
      snsType: 'youtube',
      content: xContent,
    },
  });

  const tablebodies = (): tableBodyProps[] => {
    const tmpPostTask = props.postTasks.filter((p) => p.task.taskId == selectedPost)[0];
    console.log('tmpPostTask:' + JSON.stringify(tmpPostTask, null, 2));
    const result: tableBodyProps[] = [];
    tmpPostTask.posts.posts.forEach((post, index) => {
      const insight: AnalysisSpiritPostInsightYoutube = post.insight as AnalysisSpiritPostInsightYoutube;
      const content: AnalysisSpiritPostContentYoutube = post.content as AnalysisSpiritPostContentYoutube;
      result.push({
        icon: post.account.snsAccountThumbnail,
        id: post.account.snsAccountNo,
        applyingInfluencerId: post.account.applyingInfluencerId,
        postedAt: post.content?.postedAt ?? '',
        // mediaType:
        //   content?.mediaType === 'photo'
        //     ? 'photo'
        //     : content?.mediaType === 'video'
        //     ? 'video'
        //     : content?.mediaType === 'multipleMedia' && content?.multiMediaImages?.[0].includes('mp4')
        //     ? 'video'
        //     : 'photo',
        // image:
        //   content?.mediaType === 'photo'
        //     ? getImageUrlIfRelative(content?.postImage ?? '')
        //     : content?.mediaType === 'video'
        //     ? generateThumbnail(content?.postVideo ?? '')
        //     : content?.mediaType === 'multipleMedia' && content?.multiMediaImages?.[0].includes('mp4')
        //     ? generateThumbnail(content?.multiMediaImages?.[0] ?? '')
        //     : getImageUrlIfRelative(content?.multiMediaImages?.[0] ?? ''),
        comment: insight ? insight.commentCount : 0,
        engagementRate: insight ? `${insight.engagementRate}%` : '0%',
        numberOfEngagements: insight ? insight.engagementCount : 0,
        goods: insight ? insight.goodCount : 0,
        // repost: insight ? insight.repostCount : 0,
        sumViewNum: insight ? insight.viewCount : 0,
        // MEMO： +1しないと0回目投稿から始まるため
        postCount: index + 1,
        mediaProps: {
          snsType: SNS_TYPES.YOUTUBE,
          content: {
            postImage: getImageUrlIfRelative(content?.postImage ?? ''),
            // postVideo: content?.postVideo ?? '',
            // postMedias: content?.postMedias,
            // multiMediaImages: content?.multiMediaImages ?? [],
            // mediaType: content?.mediaType ?? 'video',
            caption: content?.caption ?? '',
            // url: content?.url ?? '',
          },
        },
      } as tableBodyProps);
    });
    return result;
  };

  const handleOpenModal = (info: tableBodyProps) => {
    setModalInformation(info);
    setOpen(true);
  };

  useEffect(() => {
    const bodies = tablebodies();

    console.log('bodies:', bodies);

    // 初期画面に表示するテーブルデータ作成
    setCurrentData(bodies);
  }, [selectedPost]);

  return (
    <div className="flex w-[1110px] flex-col items-start gap-6 self-stretch p-6">
      <div className="flex flex-col items-start gap-10 self-stretch">
        <div className="flex flex-col items-start gap-10 self-stretch">
          <div className="flex items-center gap-6">
            <div className="flex h-[34px] flex-col items-start">
              <div className="w-[177px]">
                {/* 投稿回数セレクタ */}
                <select
                  id="graph-type"
                  className="flex w-[190px] flex-1 items-center justify-center gap-2 rounded-md border border-gray-300 py-[9px] pl-[17px] pr-[13px] text-sm font-medium leading-5 text-gray-700"
                  value={state.postCount}
                  onChange={(e) => {
                    setPostTask(e.target.value);
                    dispatch({
                      type: 'postCount',
                      payload: {
                        postCount: Number(e.target.value),
                        post: props.postTasks[Number(e.target.value)].posts,
                      },
                    });
                  }}
                >
                  {props.postTasks.map((postTask, index) => {
                    return (
                      <option key={postTask.task.taskId} value={postTask.task.taskId}>
                        {index + 1}回目投稿
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          {/* 投稿サマリー */}
          <div className="flex w-[740px] flex-col items-start gap-4">
            <div className="flex flex-col items-start gap-1 self-stretch">
              <span className="text-sm font-bold">投稿サマリー</span>
            </div>
            {SummaryPost(
              'w-[366px]',
              state.summary.viewCount ?? '集計中',
              state.summary.engagementRate ?? '集計中',
              state.summary.engagementCount ?? '集計中',
              state.summary.goodCount ?? '集計中',
              state.summary.commentCount ?? '集計中'
            )}
          </div>
          {/* 投稿一覧ヘッダー */}
          <div className="flex flex-col items-start gap-4 self-stretch">
            <span className="text-sm font-bold">投稿一覧</span>
            <div className="flex flex-col items-start self-stretch overflow-y-auto rounded-lg border">
              <div className="flex flex-col items-start gap-2">
                <div>
                  <div className="divide-y divide-gray-200 rounded-t-lg bg-white">
                    <div>
                      <div className="sticky top-0 inline-block">
                        <div className="flex h-[56px]">
                          {postListHeaderItems.map((item: PostListHeaderItem, index: number) => {
                            return (
                              <div key={index} className={item.css}>
                                <div className="flex flex-1 items-center gap-2">
                                  <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                    {item.displayName}
                                  </span>
                                  {item.isSort && (
                                    <div className="rounded-[4px] bg-gray-200">
                                      <div className="flex h-5 w-5 items-center  justify-center ">
                                        {state.sort[item.itemName] ? (
                                          <ChenverUp
                                            width={14}
                                            height={14}
                                            onClick={() =>
                                              dispatch({ type: 'sort', payload: { key: item.itemName, sort: false } })
                                            }
                                            stroke="#9CA3AF"
                                          />
                                        ) : (
                                          <ChenverDown
                                            width={14}
                                            height={14}
                                            onClick={() =>
                                              dispatch({ type: 'sort', payload: { key: item.itemName, sort: true } })
                                            }
                                            stroke="#9CA3AF"
                                          />
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="max-h-[210px] divide-y divide-gray-200 overflow-x-auto">
                      {/* 投稿一覧content */}
                      {currentData.every(
                        (content: tableBodyProps) =>
                          Object.values(content).every((value) => value === '') || state.contents.length === 0
                      ) ? (
                        <div className="flex justify-center py-20 text-2xl">
                          投稿一覧は、投稿が行われた後に表示されます
                        </div>
                      ) : (
                        currentData.map((body: tableBodyProps, index: number) => {
                          return (
                            <div
                              key={index}
                              onClick={() => handleOpenModal(body)}
                              onKeyDown={() => handleOpenModal(body)}
                              className={
                                'flex items-center hover:cursor-pointer hover:bg-gray-100' +
                                (index % 2 == 0 ? '' : ' bg-gray-50')
                              }
                              role="presentation"
                            >
                              <div className="w-[100px]  px-6">
                                <img src={body.icon} alt="" />
                              </div>
                              <div className="w-[152px] px-6 py-4">
                                <span>{body.id}</span>
                              </div>
                              <div className="w-[120px] px-6 py-4">
                                {body.mediaProps.content.postImage ? (
                                  <img
                                    src={body.mediaProps.content.postImage}
                                    alt=""
                                    className="h-[40px] w-[40px] rounded-[6px] object-cover"
                                  />
                                ) : (
                                  <></>
                                )}
                              </div>
                              <div className="w-[152px] px-6 py-4">
                                <span>{body.sumViewNum.toLocaleString()}</span>
                              </div>
                              <div className="w-[200px] px-6 py-4">
                                <span>{body.engagementRate.toLocaleString()}</span>
                              </div>
                              <div className="w-[200px] px-6 py-4">
                                <span>{body.numberOfEngagements.toLocaleString()}</span>
                              </div>
                              <div className="w-[132px] px-6 py-4">
                                <span>{body.goods.toLocaleString()}</span>
                              </div>
                              <div className="w-[142px] px-6 py-4">
                                <span>{body.comment.toLocaleString()}</span>
                              </div>
                            </div>
                          );
                        })
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalCommon
        open={open}
        setOpen={setOpen}
        accountInformation={modalInformation}
        initialTabKey="postInsight"
        modalType={'sns'}
        getInfluencerData={fetchInfluencerApplicantDetail}
      />
    </div>
  );
}
