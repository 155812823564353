import * as Sentry from '@sentry/react';
import { AxiosResponse } from 'axios';

import { apiRequest } from '@/api/ApiUtils';
import {
  GetJaneJohnApplicantsOutput,
  GetJaneJohnApplicantsOrderByEnum,
  GetJaneJohnApplicantsOrderEnum,
  GetJaneJohnApplicantsRowsEnum,
  GetJaneJohnApplicantsStatusEnum,
  GetJaneJohnDetailOutput,
  GetJaneJohnListOrderEnum,
  GetJaneJohnListOutput,
  GetJaneJohnListRowsEnum,
  GetJaneJohnListStatusEnum,
  JaneJohnApi,
  JaneJohnTask,
  ProjectSummaryBase,
  PutJaneJohnNoticeInput,
  GetJaneJohnListListTypeEnum,
} from '@/api/janeJohn/api';
import {
  ConfirmJaneJohnProjectAdopterDecisionRequest,
  ConfirmJaneJohnProjectAdopterDecisionResponse,
  FetchJaneJohnListResponse,
  FetchJaneJohnProjectApplicantsRecruitsListQueryParamater,
  FetchJaneJohnProjectDetailsResponse,
  FetchJaneJohnProjectSchedulesResponse,
  FetchJaneJohnQueryParamater,
  SetJaneJohnDetailImageRequest,
  SetJaneJohnDetailImageResponse,
  SetJaneJohnProjectSummaryRequest,
  SetJaneJohnProjectSummaryResponse,
  UpsertJaneJohnRecruitmentTitleRequest,
  UpsertJaneJohnRecruitmentTitleResponse,
} from '@/types/JaneJohn';
import { axiosInstance } from '@/utils/axiosInstance';

import { useApiClients } from './useApiClients';

const api = new JaneJohnApi(undefined, undefined, axiosInstance);

// 案件一覧取得API
export const fetchJaneJohnListInfo = async (query: FetchJaneJohnQueryParamater): Promise<FetchJaneJohnListResponse> => {
  const queryParam = {};
  query.status && Object.assign(queryParam, { status: query.status.toString() });
  Object.assign(queryParam, { rows: query.rows.toString() });
  Object.assign(queryParam, { page: query.page.toString() });
  Object.assign(queryParam, { order: query.order.toString() });
  if (query.noChache) {
    Object.assign(queryParam, { date: new Date().toISOString() });
  }
  query.orderBy && Object.assign(queryParam, { orderBy: query.orderBy.toString() });
  const queryString = new URLSearchParams(queryParam).toString();
  return apiRequest<FetchJaneJohnListResponse>(`/projects/jane-john?${queryString}`, {
    method: 'GET',
  });
};

export const getJaneJohnList = async (
  status?: GetJaneJohnListStatusEnum,
  listType?: GetJaneJohnListListTypeEnum,
  rows?: GetJaneJohnListRowsEnum,
  page?: number,
  order?: GetJaneJohnListOrderEnum,
  orderby?: string,
  noCache?: boolean
) => {
  try {
    const response: AxiosResponse<GetJaneJohnListOutput> = await api.getJaneJohnList(
      status,
      listType,
      rows,
      page,
      order,
      orderby,
      noCache
        ? {
            params: {
              date: new Date().toISOString(),
            },
          }
        : undefined
    );
    return response.data as GetJaneJohnListOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

// 案件詳細取得API
export const fetchJaneJohnProjectDetails = async (projectId: string): Promise<FetchJaneJohnProjectDetailsResponse> => {
  return apiRequest<FetchJaneJohnProjectDetailsResponse>(`/projects/jane-john/${projectId}`, {
    method: 'GET',
  });
};

// 案件詳細取得API
export const GetJaneJohnDetail = async (projectId: string): Promise<GetJaneJohnDetailOutput> => {
  const { janeJohnApi } = useApiClients();
  const response = await janeJohnApi.getJaneJohnDetail(projectId);
  return response.data;
};

// 案件スケジュール取得API
// export const fetchJaneJohnProjectSchedules = async (
//   projectId: string
// ): Promise<FetchJaneJohnProjectSchedulesResponse> => {
//   return apiRequest<FetchJaneJohnProjectSchedulesResponse>(`/projects/jane-john/${projectId}/schedules`, {
//     method: 'GET',
//   });
// };
export const getJaneJohnProjectSchedules = async (
  projectId: string
): Promise<FetchJaneJohnProjectSchedulesResponse> => {
  try {
    const response = await api.getJaneJohnSchedules(projectId);
    return response.data as FetchJaneJohnProjectSchedulesResponse;
  } catch (error: any) {
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

// 案件応募者/採用者一覧取得API
export const getJaneJoneApplicants = async (
  id: string,
  query?: FetchJaneJohnProjectApplicantsRecruitsListQueryParamater
): Promise<GetJaneJohnApplicantsOutput> => {
  try {
    const { rows, page, order, orderBy, status } = query || {};
    const response = await api.getJaneJohnApplicants(id, rows, page, order, orderBy, status);
    return response.data as GetJaneJohnApplicantsOutput;
  } catch (error: any) {
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

export const getJaneJohnProjectApplicants = async (
  projectId: string,
  rows?: number,
  page?: number,
  order?: string,
  orderBy?: string,
  status?: string
): Promise<GetJaneJohnApplicantsOutput> => {
  try {
    const response = await api.getJaneJohnApplicants(
      projectId,
      rows as GetJaneJohnApplicantsRowsEnum,
      page,
      order as GetJaneJohnApplicantsOrderEnum,
      orderBy as GetJaneJohnApplicantsOrderByEnum,
      status as GetJaneJohnApplicantsStatusEnum
    );
    return response.data as GetJaneJohnApplicantsOutput;
  } catch (error: any) {
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

// 案件募集タイトル作成/編集API
export const upsertJaneJohnRecruitmentTitle = async (
  projectId: string,
  request: UpsertJaneJohnRecruitmentTitleRequest
): Promise<UpsertJaneJohnRecruitmentTitleResponse> => {
  return apiRequest<UpsertJaneJohnRecruitmentTitleResponse, UpsertJaneJohnRecruitmentTitleRequest>(
    `/projects/jane-john/${projectId}/title`,
    { method: 'PUT', headers: { 'Content-Type': 'application/json' } },
    request
  );
};

// TODO 後でちゃんと実装する、案件募集タイトル作成/編集API(自動生成ツール版)
export const PutJaneJohnTitle = async (
  projectId: string,
  title: string,
  tags: Array<string>,
  featuredImage?: File
): Promise<GetJaneJohnDetailOutput> => {
  const { janeJohnApi } = useApiClients();
  const response = await janeJohnApi.putJaneJohnTitle(projectId, title, tags, featuredImage);
  return response.data;
};

// 詳細画像設定API
export const setJaneJohnDetailImage = async (
  projectId: string,
  request: SetJaneJohnDetailImageRequest
): Promise<SetJaneJohnDetailImageResponse> => {
  return apiRequest<SetJaneJohnDetailImageResponse, SetJaneJohnDetailImageRequest>(
    `/projects/jane-john/${projectId}/media`,
    { method: 'PUT', headers: { 'Content-Type': 'application/json' } },
    request
  );
};

// 画像設定API
export const PutJaneJohnMedia = async (
  projectId: string,
  uploads?: File[],
  deleteIds?: string[]
): Promise<GetJaneJohnDetailOutput> => {
  const { janeJohnApi } = useApiClients();
  const response = await janeJohnApi.putJaneJohnMedia(projectId, uploads, deleteIds);
  return response.data;
};

// 案件概要設定API
export const setJaneJohnProjectSummary = async (
  projectId: string,
  request: SetJaneJohnProjectSummaryRequest
): Promise<SetJaneJohnProjectSummaryResponse> => {
  return apiRequest<SetJaneJohnProjectSummaryResponse, SetJaneJohnProjectSummaryRequest>(
    `/projects/jane-john/${projectId}/summary`,
    { method: 'PUT', headers: { 'Content-Type': 'application/json' } },
    request
  );
};

// 案件概要設定API
export const PutJaneJohnSummary = async (
  projectId: string,
  projectSummaryBase?: ProjectSummaryBase
): Promise<GetJaneJohnDetailOutput> => {
  const { janeJohnApi } = useApiClients();
  const response = await janeJohnApi.putJaneJohnSummary(projectId, projectSummaryBase);
  return response.data;
};

// 案件稼働設定API
export const PutJaneJohnTask = async (
  projectId: string,
  janeJohnTask?: JaneJohnTask
): Promise<GetJaneJohnDetailOutput> => {
  const { janeJohnApi } = useApiClients();
  const response = await janeJohnApi.putJaneJohnTask(projectId, janeJohnTask);
  return response.data;
};

// 案件注意事項/公開設定API
export const PutJaneJohnNotice = async (
  projectId: string,
  isPublic: boolean,
  notice: string[]
): Promise<GetJaneJohnDetailOutput> => {
  const { janeJohnApi } = useApiClients();
  const putJaneJohnNoticeInput: PutJaneJohnNoticeInput = {
    isPublic: isPublic,
    notice: notice,
  };
  const response = await janeJohnApi.putJaneJohnNotice(projectId, putJaneJohnNoticeInput);
  return response.data;
};

// 案件採用者確定API
export const confirmJaneJohnProjectAdopterDecision = async (
  projectId: string,
  request: ConfirmJaneJohnProjectAdopterDecisionRequest
): Promise<ConfirmJaneJohnProjectAdopterDecisionResponse> => {
  return apiRequest<ConfirmJaneJohnProjectAdopterDecisionResponse, ConfirmJaneJohnProjectAdopterDecisionRequest>(
    `/projects/jane-john/${projectId}/adopterDecision`,
    { method: 'PATCH', headers: { 'Content-Type': 'application/json' } },
    request
  );
};
