import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import React, { Fragment, useEffect, useState } from 'react';

import { GetAnalysisOtherFeedbackAdoptersRowsEnum } from '@/api/analysisOther';
import { GetAnalysisSpiritSummaryOutput } from '@/api/analysisSpirit';
import { GetProjectOverviewOutput, ProjectType, SnsType } from '@/api/project';
import { ReactComponent as CommentIcon } from '@/assets/icons/bx-comment-detail.svg';
import { ReactComponent as FileIcon } from '@/assets/icons/bx-file.svg';
import { ReactComponent as GroupIcon } from '@/assets/icons/bx-group.svg';
import { ReactComponent as NotepadIcon } from '@/assets/icons/bx-notepad.svg';
import { getAnalysisOtherFeedbackAdopters } from '@/hooks/OtherProjectAnalysis';
import { getProjectOverviews } from '@/hooks/ProjectInfo';
import { isInstagram, isTikTok, isTwitter } from '@/utils/sns';

import AnalysisInstagram from '../Analysis/instargram';
import AnalysisResult from '../Analysis/result';
import AnalysisTikTok from '../Analysis/tiktok';
import AnalysisTwitter from '../Analysis/twiiter';
import AnalysisYoutube from '../Analysis/youtube';
import Feedback from '../feedback/feedback';
import FollowerAttributeInstagram from '../followerAttribute/instagram';
import FollowerAttributeTiktok from '../followerAttribute/tiktok';
import FollowerAttributeTwitter from '../followerAttribute/twiiter';
import FollowerAttributeYoutube from '../followerAttribute/youtube';
import { InfluencerList } from '../List/InfluencerList';
import ProjectDetails from '../projectDetails/projectDetails';

export interface SummaryProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  sns: string;
  projectType: ProjectType;
  id: string;
  project: string;
  projectName: string;
  span: string;
}

interface TabProps {
  name: string;
  key: string;
  icon: React.ReactElement<any, any>;
  activeIcon: React.ReactElement<any, any>;
}

export default function Summary(props: SummaryProps) {
  console.log('L49: props', props);
  const [tabKey, setTabKey] = useState<string>(props.projectType === ProjectType.Other ? 'result' : 'summary');
  const [summary, setSummary] = useState<GetAnalysisSpiritSummaryOutput>();
  const [isCompleteFeedback, setIsCompleteFeedback] = useState(false); //Feedback完了フラグ APIから取得 一旦ハードコーディング
  const [projectOverview, setProjectOverview] = useState<GetProjectOverviewOutput>({
    marketBackground: '',
    problem: '',
    purpose: '',
    measure: '',
    kpi: [],
    budget: 0,
    scheduleStartedAt: '',
    scheduleEndedAt: '',
    orderInfo: {
      companyName: '',
      address: '',
      represent: '',
      established: '',
      capital: '',
      business: '',
    },
  });

  const updateAdopters =
    (projectId: string) => async (rows?: GetAnalysisOtherFeedbackAdoptersRowsEnum, page?: number) => {
      return getAnalysisOtherFeedbackAdopters(projectId, rows, page);
    };

  useEffect(() => {
    if (props.id.length > 0) {
      (async () => {
        // const result = await getSummary(props.id);
        // setSummary(result);
        setSummary({} as GetAnalysisSpiritSummaryOutput);

        setProjectOverview(await getProjectOverviews(props.id));
      })();
    }
  }, [props.id]);

  const displanContent = () => {
    if (tabKey == 'summary') {
      return resultSummaryContent();
    } else if (tabKey == 'result') {
      return resultContent();
    } else if (tabKey == 'follwer') {
      return fllowerAttribute();
    } else if (tabKey == 'feedback') {
      return influencer_feedback();
    } else if (tabKey == 'influncerList') {
      return getInfluencerListContent();
    } else {
      return projectDetailContent();
    }
  };

  const resultSummaryContent = () => {
    if (summary == undefined) return <></>;
    if (isInstagram(props.sns)) {
      return <AnalysisInstagram summary={summary} />;
    } else if (isTwitter(props.sns)) {
      return <AnalysisTwitter summary={summary} />;
    } else if (isTikTok(props.sns)) {
      return <AnalysisTikTok summary={summary} />;
    } else {
      return <AnalysisYoutube summary={summary} />;
    }
  };

  const resultContent = () => {
    return <AnalysisResult projectId={props.id} />;
  };

  const getInfluencerListContent = () => {
    return <InfluencerList projectId={props.id} snsType={props.sns as SnsType} />;
  };

  const fllowerAttribute = () => {
    if (isInstagram(props.sns)) {
      return <FollowerAttributeInstagram project_title={ProjectType.Other} />;
    } else if (isTwitter(props.sns)) {
      return <FollowerAttributeTwitter project_title={ProjectType.Other} />;
    } else if (isTikTok(props.sns)) {
      return <FollowerAttributeTiktok project_title={ProjectType.Other} />;
    } else {
      return <FollowerAttributeYoutube project_title={ProjectType.Other} />;
    }
  };

  const influencer_feedback = () => {
    return (
      <Feedback
        projectType={props.projectType}
        sns={props.sns}
        isCompleteFeedback={isCompleteFeedback}
        setIsCompleteFeedback={setIsCompleteFeedback}
        getAnalysisFeedbackAdopters={updateAdopters(props.id)}
        projectId={props.id}
      />
    );
  };

  const projectDetailContent = () => {
    return (
      <ProjectDetails
        markeValue={projectOverview.marketBackground}
        theme={projectOverview.problem}
        purpose={projectOverview.purpose}
        description={projectOverview.measure}
        evaluationItem={projectOverview.kpi.join('\n')}
        budget={`￥${projectOverview.budget.toLocaleString()} （税別）`}
        supplierInformation={
          projectOverview.orderInfo.companyName +
          '\n' +
          projectOverview.orderInfo.address +
          '\n' +
          projectOverview.orderInfo.represent +
          '\n' +
          projectOverview.orderInfo.established +
          '\n' +
          projectOverview.orderInfo.capital +
          '\n' +
          projectOverview.orderInfo.business
        }
      />
    );
  };

  const selectTab = (): TabProps[] => {
    return [
      {
        name: '結果',
        key: 'result',
        icon: <FileIcon width={20} height={20} fill="#9CA3AF" />,
        activeIcon: <FileIcon width={20} height={20} fill="#007CC2" />,
      },
      // {
      //   name: 'フォロワー属性',
      //   key: 'follwer',
      //   icon: <GroupIcon width={20} height={20} fill="#9CA3AF" />,
      //   activeIcon: <GroupIcon width={20} height={20} fill="#007CC2" />,
      // },
      {
        name: 'インフルエンサー一覧',
        key: 'influncerList',
        icon: <GroupIcon width={20} height={20} fill="#9CA3AF" />,
        activeIcon: <GroupIcon width={20} height={20} fill="#007CC2" />,
      },
      {
        name: 'インフルエンサーからのフィードバック',
        key: 'feedback',
        icon: <CommentIcon width={20} height={20} fill="#9CA3AF" />,
        activeIcon: <CommentIcon width={20} height={20} fill="#007CC2" />,
      },
      {
        name: '案件概要',
        key: 'project',
        icon: <NotepadIcon width={20} height={20} fill="#9CA3AF" />,
        activeIcon: <NotepadIcon width={20} height={20} fill="#007CC2" />,
      },
    ];
  };

  const handleTabSelct = (key: string) => {
    setTabKey(key);
  };

  const onClose = () => {
    setTabKey('result');
    props.setOpen(false);
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-[1110px]">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="flex flex-col items-start gap-1 self-stretch p-6">
                      <div className="flex items-center gap-2 self-stretch">
                        <div className="flex flex-1 items-center justify-between self-stretch">
                          <div className="flex items-center gap-4">
                            <div className="flex flex-col items-start gap-1">
                              <div className="flex items-center gap-[10px]">
                                <span className="text-xs font-normal leading-none text-gray-700">{props.project}</span>
                              </div>
                              <div className="flex items-center gap-2">
                                <span className="text-base font-bold leading-6 text-gray-700">{props.projectName}</span>
                                <span className="inline-flex items-center rounded-md bg-gray-100 px-3 py-1 text-xs font-medium leading-4 text-gray-800">
                                  その他
                                </span>
                              </div>
                            </div>
                          </div>
                          <span className="text-sm font-normal leading-5 text-gray-500">実施期間：{props.span}</span>
                        </div>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500"
                            onClick={() => props.setOpen(false)}
                          >
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center self-stretch px-4 py-0">
                      <div className="flex h-[38px] items-center justify-center self-stretch">
                        <div className="flex h-[38px] w-[1041px] items-center gap-8 border-b-[1px]  border-gray-200 pl-0">
                          {selectTab().map((tab: TabProps) => {
                            return (
                              <button
                                key={tab.key}
                                className={clsx('flex flex-col items-center justify-center')}
                                disabled={tab.key == 'summary' ? false : +props.id % 2 == 0 ? true : false} // TODO 集計中の判定は別途行う必要あり
                                onClick={() => {
                                  handleTabSelct(tab.key);
                                }}
                              >
                                <div
                                  className={clsx(
                                    'flex items-center justify-center gap-2  px-1 pb-4 pt-0',
                                    tabKey == tab.key ? 'border-b-2 border-[#007CC2] text-[#007CC2]' : 'text-gray-500'
                                  )}
                                >
                                  {tabKey == tab.key ? tab.activeIcon : tab.icon}
                                  {tab.name}
                                </div>
                              </button>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div id="main-frame" className="flex w-[1041px] items-start gap-2 self-stretch p-[24px]">
                      {displanContent()}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
