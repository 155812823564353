export enum MessageNewPlanString {
  lowestPeriodMessage = '最低期間以上の数字を入力してください。',
  integralMessage = '整数を入力してください。',
  lowestBudgetMessage = '最低金額以上の数字を入力してください。',
  lowestMeasureMessage = '最低回数以上の数字を入力してください。',
  mailmessage = 'メールアドレスの形式で入力してください',
}

export const ValidationMessages = {
  strMaxLenMessage(label: string, maxNum: number): string {
    return `${label}は${maxNum}文字以下で入力してください`;
  },

  strMinLenMessage(label: string, minNum: number): string {
    return `${label}は${minNum}文字以上で入力してください`;
  },

  strTagMinLenMessage(minNum: number): string {
    return `${minNum}文字以上で入力してください`;
  },

  strImageMessage(label: string, maxSize: number): string {
    return `${label}は${maxSize}MB以下のjpeg / jpg / gif / pngファイルを指定してください`;
  },

  tagNumMessage(maxNum: number): string {
    return `タグは最大で${maxNum}つまでしか設定できません`;
  },

  tagAddMessage(label: string): string {
    return `${label}を１つ以上追加してください`;
  },

  attachmentFileNumMessage(label: string, maxNum: number): string {
    return `${label}は最大で${maxNum}までしか設定できません。`;
  },

  inputMessage(label: string): string {
    return label === '' ? '入力してください' : `${label}を入力してください`;
  },

  hyphenCheckMessage(label: string): string {
    return `${label}はハイフンなしで入力してください`;
  },

  numberCheckMessage(label: string): string {
    return `${label}は数字で入力してください`;
  },

  halfWidthCheckMessage(label: string): string {
    return `${label}は半角で入力してください`;
  },

  phoneNumberCheckMessage(label: string): string {
    return `${label}は電話番号形式で入力してください`;
  },
  invalidUrlMessage(label: string): string {
    return `${label}はURL形式で入力してください`;
  },

  formatMessage(label: string): string {
    return `${label}の形式で入力してください`;
  },

  selectMessage(label: string): string {
    return `${label}を選択してください`;
  },

  numMinMessage(minNum: number): string {
    return `${minNum}以上の数字を入力してください`;
  },

  foramtHypenMessage(label: string): string {
    return `${label}はハイフンなしで入力してください`;
  },

  foramtNumberOnlyMessage(label: string): string {
    return `${label}は数字で入力してください`;
  },

  foramtHalfMessage(label: string): string {
    return `${label}は半角で入力してください`;
  },

  foramtPhoneMessage(label: string): string {
    return `${label}は電話番号形式で入力してください`;
  },
  emailFormatErrorMessage(): string {
    return 'メールアドレスの形式で入力してください';
  },

  formatPeriodMessage(max: number): string {
    return `配信期間は最大${max}日間しか設定できません`;
  },
  invaildTodayPeriodMessage(): string {
    return `開始日は本日以降の日付を選択してください`;
  },

  moreThanTargetMessage(label: string, min: string | number): string {
    return `${label}は¥${min}以上でなければなりません`;
  },

  overSendLimitMessage(): string {
    return `発行可能なアカウント数を超過しています`;
  },
  overAdminRoleLimitMessage(): string {
    return `発行可能な権限アカウント数を超過しています`;
  },
};
