import React from 'react';

import { GetInfluencerSnsAccountOutput } from '@/api/influencer';
import { ReactComponent as CommentIcon } from '@/assets/icons/bx-comment-detail.svg';
import { ReactComponent as HeartIcon } from '@/assets/icons/bx-heart.svg';
import { ReactComponent as GoodIcon } from '@/assets/icons/bx-like.svg';
import { ReactComponent as RepostIcon } from '@/assets/icons/bx-repost.svg';
import NoImage from '@/assets/images/NoImage.png';
import { Stat } from '@/components/Elements/Stat';
import { formatNumber } from '@/utils/format';

import { PostInfoModalProps, AppealPostsProps, RecentPostsProps } from '../../Twitter/TwitterAll_Interface';
import Modal from '../Modal';
import { Slider } from '../Parts/Slider';
import comment_ballon from '../Pic/comment_ballon.png';
import Heartsvg from '../Pic/heart.svg';
import retweet from '../Pic/retweet.png';

interface modalInfoAllProps {
  appealPosts: AppealPostsProps[];
  recentPosts: RecentPostsProps[];
  postinfoModal: PostInfoModalProps;
  influencerInfo?: GetInfluencerSnsAccountOutput;
}

let applealPostsFlag = false;

export default function ApplicantInfo(props: modalInfoAllProps) {
  const handleOpen = (index: number, kind: string, isDeleteArrow?: boolean) => {
    applealPostsFlag = kind === 'appeal';
    props.postinfoModal.setModalIndex(index);
    props.postinfoModal.setPostType(kind);
    props.postinfoModal.setModalOpen(true);
    if (isDeleteArrow && props.postinfoModal.setDeleteFlag) {
      props.postinfoModal.setDeleteFlag(true);
    } else if (!isDeleteArrow && props.postinfoModal.setDeleteFlag) {
      props.postinfoModal.setDeleteFlag(false);
    }
  };

  let x_modal_element_component: JSX.Element[];

  if (props.postinfoModal.postType === 'appeal') {
    x_modal_element_component = [
      <Stat
        key={0}
        icon={<HeartIcon width={24} height={24} fill="#FFFFFF" />}
        lable="エンゲージメント率"
        value={props.appealPosts[props.postinfoModal.modalindex]?.engagementRate?.toString() + '%'}
        className="w-[350px] rounded-lg border border-slate-200"
      />,
      <Stat
        key={1}
        icon={<HeartIcon width={24} height={24} fill="#FFFFFF" />}
        lable="エンゲージメント数"
        value={formatNumber(props.appealPosts[props.postinfoModal.modalindex]?.engagementCounts || 0)}
        className="w-[350px] rounded-lg border border-slate-200"
      />,
      <Stat
        key={2}
        icon={<GoodIcon width={24} height={24} fill="#FFFFFF" />}
        lable="いいね数"
        value={formatNumber(props.appealPosts[props.postinfoModal.modalindex]?.likeCounts || 0)}
        className="w-[350px] rounded-lg border border-slate-200"
      />,
      <Stat
        key={3}
        icon={<CommentIcon width={24} height={24} fill="#FFFFFF" />}
        lable="返信数"
        value={formatNumber(props.appealPosts[props.postinfoModal.modalindex]?.replyCounts || 0)}
        className="w-[350px] rounded-lg border border-slate-200"
      />,
      <Stat
        key={4}
        icon={<RepostIcon width={24} height={24} fill="#FFFFFF" />}
        lable="リポスト数"
        value={formatNumber(props.appealPosts[props.postinfoModal.modalindex]?.repostCounts || 0)}
        className="w-[350px] rounded-lg border border-slate-200"
      />,
    ];
  } else {
    x_modal_element_component = [
      <Stat
        key={0}
        icon={<HeartIcon width={24} height={24} fill="#FFFFFF" />}
        lable="エンゲージメント率"
        value={props.recentPosts[props.postinfoModal.modalindex]?.engagementRate?.toString() + '%'}
        className="w-[350px] rounded-lg border border-slate-200"
      />,
      <Stat
        key={1}
        icon={<HeartIcon width={24} height={24} fill="#FFFFFF" />}
        lable="エンゲージメント数"
        value={formatNumber(props.recentPosts[props.postinfoModal.modalindex]?.engagementCounts || 0)}
        className="w-[350px] rounded-lg border border-slate-200"
      />,
      <Stat
        key={2}
        icon={<GoodIcon width={24} height={24} fill="#FFFFFF" />}
        lable="いいね数"
        value={formatNumber(props.recentPosts[props.postinfoModal.modalindex]?.likeCounts || 0)}
        className="w-[350px] rounded-lg border border-slate-200"
      />,
      <Stat
        key={3}
        icon={<CommentIcon width={24} height={24} fill="#FFFFFF" />}
        lable="返信数"
        value={formatNumber(props.recentPosts[props.postinfoModal.modalindex]?.replyCounts || 0)}
        className="w-[350px] rounded-lg border border-slate-200"
      />,
      <Stat
        key={4}
        icon={<RepostIcon width={24} height={24} fill="#FFFFFF" />}
        lable="リポスト数"
        value={formatNumber(props.recentPosts[props.postinfoModal.modalindex]?.repostCounts || 0)}
        className="w-[350px] rounded-lg border border-slate-200"
      />,
    ];
  }
  return (
    <>
      <div className="text-sm font-semibold leading-5">アピール投稿</div>

      {new Array(props.appealPosts.length).fill(0).map((value, index) => {
        return (
          <div className="pt-[10px]" key={index}>
            <div
              className="h-auto w-[651px] rounded-xl border-[1px] border-slate-200 px-[16px] pb-2"
              role="presentation"
              onClick={() => handleOpen(index, 'appeal')}
            >
              <div className="flex gap-[4px]">
                <div className="pt-[12px]">
                  <img src={props.influencerInfo?.thumbnailUrl || ''} alt="" className="h-[48px] w-[48px]" />
                </div>
              </div>
              <div className="h-auto break-all pb-3 pt-[8px] text-xl font-medium leading-7">
                {props.appealPosts[index]?.caption?.split(/[\s]/).map((str, index) => {
                  return (
                    <span key={index} className="block">
                      {str}
                    </span>
                  );
                })}
              </div>
              <div>
                {props.appealPosts[index] &&
                  (props.appealPosts[index].postImage ? (
                    <img src={props.appealPosts[index].postImage || NoImage} alt="" />
                  ) : props.appealPosts[index].postVideo ? (
                    <div className="pt-[16px]">
                      {/* <img src={props.appealPosts[index].postVideo || ''} alt="" className="h-[364px] w-[838px]" /> */}
                      <video
                        src={props.appealPosts[index]?.postVideo || NoImage}
                        className="h-[364px] w-[838px]"
                        controls
                      >
                        <track kind="captions" src="captions.vtt" label="English" />
                      </video>
                    </div>
                  ) : props.appealPosts[index].postMedias ? (
                    <div className="pt-[16px]">
                      <Slider mediaDatas={props.appealPosts[index].postMedias} index={index} width={617} height={400} />
                    </div>
                  ) : null)}
              </div>
              <div className="pt-3 text-base font-medium leading-6" style={{ color: '#566370' }}>
                {props.appealPosts[index]?.postedAt}
              </div>
              <div className="pt-[12px]">
                <div className="h-[1px] w-[619px] bg-gray-300"></div>
              </div>
              <div className="flex gap-[32px] pt-[8px]">
                <div className="flex items-center gap-[10px]">
                  <img src={comment_ballon} alt="" className="h-[24px] w-[24px]" />
                  <div className="text-sm font-normal leading-5" style={{ color: '#566370' }}>
                    {props.appealPosts[index]?.replyCounts}
                  </div>
                </div>
                <div className="flex items-center gap-[10px]">
                  <img src={retweet} alt="" className="h-[24px] w-[24px]" />
                  <div className="text-sm font-normal leading-5" style={{ color: '#566370' }}>
                    {props.appealPosts[index]?.repostCounts}
                  </div>
                </div>
                <div className="flex items-center gap-[10px]">
                  <img src={Heartsvg} alt="" className="h-[24px] w-[24px]" />
                  <div className="text-sm font-normal leading-5" style={{ color: '#566370' }}>
                    {props.appealPosts[index]?.likeCounts}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}

      {/*最新の投稿*/}
      {/* <div className="pt-[10px] text-sm font-semibold leading-5">{`最新の${props.recentPosts.length}投稿`}</div> */}
      <div className="pt-[10px] text-sm font-semibold leading-5">最新の9投稿</div>
      {new Array(props.recentPosts.length).fill(0).map((value, index) => {
        return (
          <div className="pt-[10px]" key={index}>
            <div
              className="h-auto w-[651px] rounded-xl border-[1px] border-slate-200 px-[16px] pb-3"
              role="presentation"
              onClick={() => handleOpen(index, 'recent')}
            >
              <div className="flex gap-[4px]">
                <div className="pt-[12px]">
                  <img src={props.influencerInfo?.thumbnailUrl || ''} alt="" className="h-[48px] w-[48px]" />
                </div>
              </div>
              <div className="h-auto break-all pt-[8px] text-xl font-medium leading-7">
                {props.recentPosts[index]?.caption?.split(/[\s]/).map((str, index) => {
                  return (
                    <span key={index} className="block">
                      {str}
                    </span>
                  );
                })}
              </div>
              <div>
                {props.recentPosts[index].postImage ? (
                  <img src={props.recentPosts[index]?.postImage || NoImage} alt="" />
                ) : props.recentPosts[index].postVideo ? (
                  <div className="pt-[16px]">
                    {/* <img src={props.recentPosts[index]?.postVideo || ''} alt="" className="h-[364px] w-[838px]" /> */}
                    <video
                      src={props.recentPosts[index]?.postVideo || NoImage}
                      className="h-[364px] w-[838px]"
                      controls
                    >
                      <track kind="captions" src="captions.vtt" label="English" />
                    </video>
                  </div>
                ) : props.recentPosts[index].postMedias ? (
                  <div className="pt-[16px]">
                    <Slider mediaDatas={props.recentPosts[index].postMedias} index={index} width={617} height={400} />
                  </div>
                ) : null}
              </div>
              <div className="pt-3 text-base font-medium leading-6" style={{ color: '#566370' }}>
                {props.recentPosts[index]?.postedAt}
              </div>
              <div className="pt-[12px]">
                <div className="h-[1px] w-[619px] bg-gray-300"></div>
              </div>
              <div className="flex gap-[32px] pt-[8px]">
                <div className="flex gap-[10px]">
                  <img src={comment_ballon} alt="" className="h-[24px] w-[24px]" />
                  <div className="text-sm font-normal leading-5" style={{ color: '#566370' }}>
                    {props.recentPosts[index]?.replyCounts}
                  </div>
                </div>
                <div className="flex gap-[10px]">
                  <img src={retweet} alt="" className="h-[24px] w-[24px]" />
                  <div className="text-sm font-normal leading-5" style={{ color: '#566370' }}>
                    {props.recentPosts[index]?.repostCounts}
                  </div>
                </div>
                <div className="flex gap-[10px]">
                  <img src={Heartsvg} alt="" className="h-[24px] w-[24px]" />
                  <div className="text-sm font-normal leading-5" style={{ color: '#566370' }}>
                    {props.recentPosts[index]?.likeCounts}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}

      {/* モーダル */}
      {props.postinfoModal.modalOpen && (
        <div>
          <Modal
            open={props.postinfoModal.modalOpen}
            setOpen={props.postinfoModal.setModalOpen}
            accountInfo={applealPostsFlag ? props.appealPosts : props.recentPosts}
            modalIndex={props.postinfoModal.modalindex}
            setModalIndex={props.postinfoModal.setModalIndex}
            Component_Element={x_modal_element_component}
            sns_name="twitter"
            deleteFlag={props.postinfoModal.deleteFlag}
          />
        </div>
      )}
    </>
  );
}
