import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { GetJaneJohnApplicantsRowsEnum, ProjectApplicantItem } from '@/api/janeJohn';
import { SnsType } from '@/api/project';
import { ReactComponent as ChenverDown } from '@/assets/icons/chevron-down.svg';
import { ReactComponent as ChenverUp } from '@/assets/icons/chevron-up.svg';
import NoImage from '@/assets/images/NoImage.png';
import { fetchInfluencerApplicantDetail } from '@/hooks/Influencer';
import { getJaneJoneApplicants } from '@/hooks/JaneJohn';

import ModalCommon from '../modals/modalCommon';
import { tableBodyCommonProps } from '../modals/modalInterface';

export interface tableBodyProps {
  applicantIndex: number;
  thumbnail: string;
  influencerId: string;
  evaluation: number;
  evaluationCounts: number;
  followers: number;
  engagementRate: string;
  engagements: number;
  snsType: string;
}

interface ascInfoProps {
  applicantIndex: boolean;
  evaluation: boolean;
  evaluationCounts: boolean;
  followers: boolean;
  engagementRate: boolean;
  engagements: boolean;
}

interface propsInterface {
  id: string;
  snsType: SnsType;
}

export default function JanejoneInfluencerList(props: propsInterface) {
  const search = useLocation().search;
  console.log('id', props.id);
  const [responsePageCount, setResponsePageCount] = useState<number>(0);

  const [rowTableBody, setRowTableBody] = useState<tableBodyProps[]>([]);
  //const [tableBody, setTableBody] = useState<tableBodyProps[]>([]);
  const [ascInfo, setAscInfo] = useState<ascInfoProps>({
    applicantIndex: true,
    evaluation: true,
    evaluationCounts: true,
    followers: true,
    engagementRate: true,
    engagements: true,
  });
  const [perPage, setPerPage] = useState<number>(30);
  const [startPage, setStartPage] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  // const [currentPage, setCurrentPage] = useState<number>(queryCurrentPage);
  // 初期画面に表示するテーブルデータ作成用ステート
  const [currentData, setCurrentData] = useState<tableBodyProps[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [modalInformation, setModalInformation] = useState<tableBodyCommonProps>({
    icon: '', // アイコン
    id: '', //ID
    applyingInfluencerId: '', // 応募者ID
    image: '', //サムネイル
    numberOfEngagements: 0, //エンゲージメント数
  });

  const handleSort = (name: keyof tableBodyProps, value: boolean) => {
    let tmp = rowTableBody;
    let sortedGroupedArray;

    if (name === 'evaluation') {
      tmp.sort((a, b) => (value ? a.evaluation - b.evaluation : b.evaluation - a.evaluation));

      // evaluation ごとにグループ化
      const groupedArray = tmp.reduce((acc: any[], item) => {
        // `acc` に `evaluation` が同じグループが既にあるかを探す
        const group = acc.find((subArray) => subArray[0]?.evaluation === item.evaluation);
        if (group) {
          // グループが既に存在する場合、そのグループに追加
          group.push(item);
        } else {
          // 新しいグループを作成して `acc` に追加
          acc.push([item]);
        }
        return acc;
      }, []);

      if (value) {
        sortedGroupedArray = groupedArray.map(
          (group) => group.sort((a: tableBodyProps, b: tableBodyProps) => a.evaluationCounts - b.evaluationCounts) // 降順の場合
        );
      } else {
        sortedGroupedArray = groupedArray.map(
          (group) => group.sort((a: tableBodyProps, b: tableBodyProps) => b.evaluationCounts - a.evaluationCounts) // 昇順の場合
        );
      }

      tmp = sortedGroupedArray.flat();
    } else {
      if (value) {
        tmp.sort((a: tableBodyProps, b: tableBodyProps) => (a[name] > b[name] ? 1 : -1));
      } else {
        tmp.sort((a: tableBodyProps, b: tableBodyProps) => (a[name] < b[name] ? 1 : -1));
      }
    }

    const newtmp = tmp.slice(0, perPage);
    setCurrentData(newtmp);

    setAscInfo({ ...ascInfo, [name]: value });
  };

  const moveRef_header = useRef<HTMLDivElement>(null);
  const moveRef_body = useRef<HTMLDivElement>(null);
  const scrollLimit = 0;

  const scroll_event = (e: any) => {
    if (moveRef_header.current && moveRef_body.current) {
      moveRef_header.current.scrollLeft = e.target.scrollLeft < scrollLimit ? e.target.scrollLeft : scrollLimit;
      moveRef_body.current.scrollLeft = e.target.scrollLeft < scrollLimit ? e.target.scrollLeft : scrollLimit;
    }
  };

  const pages: number[] = [];
  const totalPages = Math.ceil(responsePageCount / perPage);
  const pageNationCount = totalPages > 7 ? 7 : totalPages;
  const endpageNumber = pageNationCount + startPage;
  for (let i = 0; i < pageNationCount; i++) {
    if (totalPages < startPage + i) break;
    pages.push(startPage + i);
  }

  const handlePageFirst = () => {
    if (currentPage == 1) return;
    setStartPage(1);
    setCurrentPage(1);
    setCurrentData(rowTableBody.slice(0, 0 + perPage));
  };

  const handlePageDown = () => {
    if (currentPage == 1) return;
    if (currentPage == startPage) {
      setStartPage(startPage - 1);
    }
    const page = currentPage - 1;
    setCurrentPage(page);
    const index = (page - 1) * perPage;
    setCurrentData(rowTableBody.slice(index, index + perPage));
  };

  const handlePageUp = () => {
    if (currentPage == Math.ceil(responsePageCount / perPage)) return;
    if (currentPage == endpageNumber - 1) {
      setStartPage(startPage + 1);
    }
    const page = currentPage + 1;
    setCurrentPage(page);
    const index = (page - 1) * perPage;
    setCurrentData(rowTableBody.slice(index, index + perPage));
  };

  const handlePageLast = () => {
    if (currentPage == totalPages) return;
    if (totalPages - 7 > 0) {
      setStartPage(totalPages - 6);
    } else {
      setStartPage(1);
    }

    setCurrentPage(totalPages);
    const index = (totalPages - 1) * perPage;
    setCurrentData(rowTableBody.slice(index, index + perPage));
  };

  const handleOpenModal = (info: tableBodyProps) => {
    const modalData: tableBodyCommonProps = {
      icon: info.thumbnail ?? NoImage,
      id: info.influencerId,
      applyingInfluencerId: info.influencerId,
      image: info.thumbnail ?? NoImage,
      numberOfEngagements: 0,
    };
    setModalInformation(modalData);
    setOpen(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      // id を table 画面から取得する処理必要
      const response = await getJaneJoneApplicants(props.id, {
        rows: perPage as GetJaneJohnApplicantsRowsEnum,
        page: currentPage,
        order: 'asc',
        orderBy: 'id',
        status: 'approved',
      });
      console.log('response', response);
      setResponsePageCount(response.count.approved);
      const mappedRows = response.rows.map((row: ProjectApplicantItem) => ({
        applicantIndex: row.applicantIndex,
        thumbnail: row.thumbnail ?? '',
        influencerId: row.id,
        evaluation: row.evaluation ?? 0,
        evaluationCounts: row.evaluationCounts ?? 0,
        followers: row.followers ?? 0,
        engagementRate:
          typeof row.engagementRate === 'number' ? row.engagementRate.toString() : row.engagementRate ?? '',
        engagements: row.engagements ?? 0,
        snsType: row.snsType,
      }));
      // 初期画面に表示するテーブルデータ作成
      setCurrentData(mappedRows.slice(0, perPage));
      setRowTableBody(mappedRows);
    };
    fetchData();
  }, [currentPage, perPage]);

  return (
    <div className="flex flex-col items-start gap-6 self-stretch">
      <div className="flex flex-col items-start gap-10 self-stretch">
        <div className="flex flex-col items-start gap-10 self-stretch">
          <div className="flex flex-col items-start gap-4 self-stretch">
            <span className="text-sm font-bold">インフルエンサー一覧</span>
            <div className="flex flex-col items-start self-stretch">
              <div className="flex flex-col items-start gap-2 rounded-lg border-[1px] border-b-0 border-gray-200">
                <div>
                  <div className="divide-y divide-gray-200 rounded-t-lg bg-white">
                    <div
                      className="w-[1050px] overflow-auto scrollbar-hide"
                      ref={moveRef_header}
                      onScroll={(event) => scroll_event(event)}
                    >
                      <div className="sticky top-0 inline-block">
                        <div className="flex h-[56px]">
                          <div className="flex w-[114px] items-center rounded-[10px] bg-white px-[24px] py-4">
                            <div className="flex flex-1 items-center gap-2">
                              <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                応募順
                              </span>
                              <div className="rounded-[4px] bg-gray-200">
                                <div className="flex h-5 w-5 items-center  justify-center ">
                                  {ascInfo.applicantIndex ? (
                                    <ChenverDown
                                      width={14}
                                      height={14}
                                      onClick={() => handleSort('applicantIndex', false)}
                                      stroke="#9CA3AF"
                                    />
                                  ) : (
                                    <ChenverUp
                                      width={14}
                                      height={14}
                                      onClick={() => handleSort('applicantIndex', true)}
                                      stroke="#9CA3AF"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="w-[98px] items-center rounded-[10px] bg-white px-6 py-4">
                            <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                              アイコン
                            </span>
                          </div>
                          <div className="flex w-[152px] items-center rounded-[10px] bg-white px-[17px] py-4">
                            <div className="flex flex-1 items-center gap-2">
                              <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                ID
                              </span>
                            </div>
                          </div>
                          <div className="w-[126px] items-center rounded-[10px] bg-white px-3 py-4">
                            <div className="flex flex-1 items-center gap-2">
                              <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                評価
                              </span>
                              <div className="rounded-[4px] bg-gray-200">
                                <div className="flex h-5 w-5 items-center  justify-center ">
                                  {ascInfo.evaluation ? (
                                    <ChenverDown
                                      width={14}
                                      height={14}
                                      onClick={() => {
                                        handleSort('evaluation', false);
                                      }}
                                      stroke="#9CA3AF"
                                    />
                                  ) : (
                                    <ChenverUp
                                      width={14}
                                      height={14}
                                      onClick={() => {
                                        handleSort('evaluation', true);
                                      }}
                                      stroke="#9CA3AF"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="w-[160px] items-center rounded-[10px] bg-white px-6 py-4">
                            <div className="flex flex-1 items-center gap-2">
                              <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                フォロワー数
                              </span>
                              <div className="rounded-[4px] bg-gray-200">
                                <div className="flex h-5 w-5 items-center  justify-center ">
                                  {ascInfo.followers ? (
                                    <ChenverDown
                                      width={14}
                                      height={14}
                                      onClick={() => handleSort('followers', false)}
                                      stroke="#9CA3AF"
                                    />
                                  ) : (
                                    <ChenverUp
                                      width={14}
                                      height={14}
                                      onClick={() => handleSort('followers', true)}
                                      stroke="#9CA3AF"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="w-[200px] items-center rounded-[10px] bg-white px-6 py-4">
                            <div className="flex flex-1 items-center gap-2">
                              <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                エンゲージメント率
                              </span>
                              <div className="rounded-[4px] bg-gray-200">
                                <div className="flex h-5 w-5 items-center  justify-center ">
                                  {ascInfo.engagementRate ? (
                                    <ChenverDown
                                      width={14}
                                      height={14}
                                      onClick={() => handleSort('engagementRate', false)}
                                      stroke="#9CA3AF"
                                    />
                                  ) : (
                                    <ChenverUp
                                      width={14}
                                      height={14}
                                      onClick={() => handleSort('engagementRate', true)}
                                      stroke="#9CA3AF"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="w-[200px] items-center rounded-[10px] bg-white px-6 py-4">
                            <div className="flex flex-1 items-center gap-2">
                              <span className="w-[80px] text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                エンゲージメント数
                              </span>
                              <div className="rounded-[4px] bg-gray-200">
                                <div className="flex h-5 w-5 items-center  justify-center ">
                                  {ascInfo.engagements ? (
                                    <ChenverDown
                                      width={14}
                                      height={14}
                                      onClick={() => handleSort('engagements', false)}
                                      stroke="#9CA3AF"
                                    />
                                  ) : (
                                    <ChenverUp
                                      width={14}
                                      height={14}
                                      onClick={() => handleSort('engagements', true)}
                                      stroke="#9CA3AF"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="h-[489px] w-[1050px] divide-y divide-gray-200 overflow-auto"
                      onScroll={(event) => scroll_event(event)}
                      ref={moveRef_body}
                    >
                      {/* {tableBody.map((body: tableBodyProps, index: number) => { */}
                      {currentData.map((body: tableBodyProps, index: number) => {
                        return (
                          <div
                            key={index}
                            onClick={() => handleOpenModal(body)}
                            onKeyDown={() => handleOpenModal(body)}
                            className={
                              'flex h-[80px] w-[1930px] items-center border-none hover:cursor-pointer hover:bg-gray-200' +
                              (index % 2 == 0 ? '' : ' bg-gray-50')
                            }
                            role="presentation"
                          >
                            <div className="w-[114px]  px-6">
                              <span>{body.applicantIndex}</span>
                            </div>
                            <div className="w-[92px]  px-6">
                              <img src={body.thumbnail} alt="" />
                            </div>

                            <div className="w-[152px] truncate px-6 py-4">
                              <span>{body.influencerId}</span>
                            </div>
                            <div className="w-[126px] px-4">
                              <span className="flex w-[126px] items-center">
                                {Array(body.evaluation)
                                  .fill(0)
                                  .map((value, index) => {
                                    return (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        key={index}
                                      >
                                        <path
                                          d="M5.23916 1.34164C5.47864 0.60459 6.52136 0.604592 6.76085 1.34164L7.6165 3.97508C7.7236 4.3047 8.03077 4.52786 8.37735 4.52786H11.1463C11.9213 4.52786 12.2435 5.51956 11.6165 5.97508L9.3764 7.60263C9.09601 7.80635 8.97868 8.16744 9.08578 8.49706L9.94144 11.1305C10.1809 11.8675 9.33733 12.4804 8.71036 12.0249L6.47023 10.3974C6.18984 10.1937 5.81016 10.1937 5.52977 10.3974L3.28964 12.0249C2.66266 12.4804 1.81908 11.8675 2.05856 11.1305L2.91422 8.49706C3.02132 8.16744 2.90399 7.80635 2.6236 7.60263L0.383465 5.97508C-0.243506 5.51956 0.0787158 4.52786 0.853695 4.52786H3.62265C3.96924 4.52786 4.2764 4.3047 4.3835 3.97508L5.23916 1.34164Z"
                                          fill="#FCD34D"
                                        />
                                      </svg>
                                    );
                                  })}

                                {Array(5 - body.evaluation)
                                  .fill(0)
                                  .map((value, index) => {
                                    return (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16px"
                                        height="16px"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        key={index}
                                      >
                                        <path
                                          d="M5.23916 1.34164C5.47864 0.60459 6.52136 0.604592 6.76085 1.34164L7.6165 3.97508C7.7236 4.3047 8.03077 4.52786 8.37735 4.52786H11.1463C11.9213 4.52786 12.2435 5.51956 11.6165 5.97508L9.3764 7.60263C9.09601 7.80635 8.97868 8.16744 9.08578 8.49706L9.94144 11.1305C10.1809 11.8675 9.33733 12.4804 8.71036 12.0249L6.47023 10.3974C6.18984 10.1937 5.81016 10.1937 5.52977 10.3974L3.28964 12.0249C2.66266 12.4804 1.81908 11.8675 2.05856 11.1305L2.91422 8.49706C3.02132 8.16744 2.90399 7.80635 2.6236 7.60263L0.383465 5.97508C-0.243506 5.51956 0.0787158 4.52786 0.853695 4.52786H3.62265C3.96924 4.52786 4.2764 4.3047 4.3835 3.97508L5.23916 1.34164Z"
                                          fill="#D1D5DB"
                                        />
                                      </svg>
                                    );
                                  })}

                                <span className={'h-[20px] items-center text-center text-sm font-normal leading-5'}>
                                  ({body.evaluationCounts})
                                </span>
                              </span>
                            </div>
                            <div className="w-[160px] px-8 py-4">
                              <span>{body.followers}</span>
                            </div>
                            <div className="w-[200px] px-8 py-4">
                              <span>{body.engagementRate}</span>
                            </div>
                            <div className="w-[200px] px-[30px] py-4">
                              <span>{body.engagements}</span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-[1053px] items-center justify-between rounded-b-lg border border-t-0 border-gray-200 bg-white px-4 py-3 sm:px-6">
                <div className="flex flex-1 justify-between sm:hidden">
                  <a
                    href="#"
                    className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                  >
                    Previous
                  </a>
                  <a
                    href="#"
                    className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                  >
                    Next
                  </a>
                </div>
                <div className="sm:flex sm:flex-1 sm:items-center sm:justify-between">
                  <div className="flex flex-row items-center gap-2">
                    <span className="text-xs font-normal leading-4 text-gray-500">１ページあたりの行数:</span>
                    <div className="text-xs font-normal leading-4 sm:col-span-3">
                      <select
                        id="perPage"
                        name="perPage"
                        className="border-none text-xs font-normal leading-4 text-gray-500"
                        defaultValue={perPage}
                        onChange={(e) => {
                          setPerPage(+e.target.value);
                          setStartPage(1);
                          setCurrentPage(1);
                          const queryString = new URLSearchParams(search);
                          queryString.set('rows', e.target.value);
                          queryString.set('page', '1');
                          history.pushState(null, '', `?${queryString.toString()}`);
                        }}
                      >
                        <option>30</option>
                        <option>50</option>
                        <option>100</option>
                      </select>
                    </div>
                    <span className="text-sm text-gray-500">
                      {(currentPage - 1) * perPage + 1} -{' '}
                      {(currentPage - 1) * perPage + perPage > responsePageCount
                        ? responsePageCount
                        : (currentPage - 1) * perPage + perPage}{' '}
                      of {responsePageCount}
                    </span>
                  </div>
                  {responsePageCount > 30 && (
                    <div>
                      <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                        <button
                          onClick={handlePageFirst}
                          // className="relative inline-flex items-center rounded-l-md p-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                          className={clsx(
                            'relative inline-flex items-center rounded-l-md p-2 text-gray-400 ring-1 ring-inset ring-gray-300',
                            currentPage == 1 ? '' : 'hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                          )}
                        >
                          <ChevronDoubleLeftIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                            fill={currentPage == 1 ? '#D1D5DB' : '#6B7280'}
                          />
                        </button>
                        <button
                          onClick={handlePageDown}
                          // className="relative inline-flex items-center p-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                          className={clsx(
                            'relative inline-flex items-center p-2 text-gray-400 ring-1 ring-inset ring-gray-300 ',
                            currentPage == 1 ? '' : 'hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                          )}
                        >
                          <ChevronLeftIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                            fill={currentPage == 1 ? '#D1D5DB' : '#6B7280'}
                          />
                        </button>
                        {pages.map((page) => {
                          if (page == currentPage) {
                            return (
                              <button
                                key={page}
                                className="relative z-10 inline-flex items-center border border-[#007CC2] bg-[#E0F4FF] px-4 py-2"
                              >
                                <span className="text-sm font-medium leading-5 text-gray-500">{page}</span>
                              </button>
                            );
                          } else {
                            return (
                              <button
                                key={page}
                                className="relative inline-flex items-center px-4 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                onClick={() => {
                                  setCurrentPage(page);
                                  const index = (page - 1) * perPage;
                                  setCurrentData(rowTableBody.slice(index, index + perPage));
                                }}
                              >
                                <span className="text-sm font-medium leading-5 text-gray-500">{page}</span>
                              </button>
                            );
                          }
                        })}
                        <button
                          onClick={handlePageUp}
                          className={clsx(
                            'relative inline-flex items-center p-2 text-gray-400 ring-1 ring-inset ring-gray-300 ',
                            currentPage == totalPages ? '' : 'hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                          )}
                        >
                          <ChevronRightIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                            fill={currentPage == totalPages ? '#D1D5DB' : '#6B7280'}
                          />
                        </button>
                        <button
                          onClick={handlePageLast}
                          className={clsx(
                            'relative inline-flex items-center rounded-r-md p-2 text-gray-400 ring-1 ring-inset ring-gray-300 ',
                            currentPage == totalPages ? '' : 'hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                          )}
                        >
                          <ChevronDoubleRightIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                            fill={currentPage == totalPages ? '#D1D5DB' : '#6B7280'}
                          />
                        </button>
                      </nav>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* モーダル。ここも一個に統一できるなら今後修正*/}
      <ModalCommon
        open={open}
        snsType={props.snsType}
        setOpen={setOpen}
        accountInformation={modalInformation}
        initialTabKey="accountInsight"
        modalType={'sns'}
        getInfluencerData={fetchInfluencerApplicantDetail}
      />
    </div>
  );
}
