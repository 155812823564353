import { CalendarIcon, DocumentTextIcon, PencilIcon } from '@heroicons/react/24/outline';
import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
// import { set } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { DefaultApi as OverviewDefaultApi } from '@/api/project';
import {
  DefaultApiFactory,
  GetPrstApplicantsOrderEnum,
  GetPrstApplicantsRowsEnum,
  GetPrstApplicantsStatusEnum,
  ProjectEstimate,
  ProjectImageRow,
  ProjectKpi,
  PrstSummary,
} from '@/api/prst';
import { ReactComponent as ApplicantIcon } from '@/assets/icons/bx-group.svg';
import { MainLayout } from '@/components/Layout/MainLayout';
import { ApplicantSchedule } from '@/components/ProjectManagements/Applicant/Contents/common/Types/common_types';
import { PhaseNavigation } from '@/components/ProjectManagements/PhaseNavigation/Components/PhaseNavigation';
import { TabView } from '@/components/ProjectManagements/PhaseNavigation/Components/TabView';
import { phaseInfo } from '@/components/ProjectManagements/PhaseNavigation/Interfaces/PhaseNavigationInterfaces';
import { TabInfo } from '@/components/ProjectManagements/PhaseNavigation/Interfaces/TabViewInterfaces';
import ProjectOverview from '@/components/ProjectManagements/ProjectOverview/Components/ProjectOverview';
import { projectOverview } from '@/components/ProjectManagements/ProjectOverview/Interfaces/ProjectOverViewInterfaces';
import ScheduleContent, { ScheduleProps } from '@/components/ProjectManagements/ProjectSchedule/Components/Schedule';
import {
  ScheduleList,
  projectSchedule,
} from '@/components/ProjectManagements/ProjectSchedule/Interface/ProjectScheduleInterface';
import { PROJECT_STATUS, PROJECT_TYPES } from '@/config';
import { firstSidebarSelect, secondSidebarSelect } from '@/config/sidebar';
import { axiosInstance } from '@/utils/axiosInstance';
import { formatYYYYMMDD } from '@/utils/format';
import {
  convertStatusToStepForPrst,
  convertStatusToDisplayLabelForPrst,
  convertStatusToStep,
} from '@/utils/projectUtils';

import ApplicantContent, {
  ApplicantProps,
  GetProjectApplicantsOutput,
} from '../../../components/ProjectManagements/Applicant/Contents/ApplicantContent';
import Recruitment from '../components/details/Recruitment';
import { PageHeader, HeaderProps } from '../components/layouts/headers';
import { popupInputData } from '../interface/prstProjectInterface';

export function PrstProject(): React.ReactElement {
  const search = useLocation().search;

  const queryString = new URLSearchParams(search);
  const queryTab: string = queryString.get('tab') || 'overview';
  const projectId = queryString.get('projectId') || '';

  const [status, setStatus] = useState<string>('');

  const [outlineInfo, setOutlineInfo] = useState<projectOverview>({
    marketBackground: '',
    problem: '',
    purpose: '',
    measure: '',
    scheduleStartedAt: null,
    scheduleEndedAt: null,
    budget: 0,
    kpi: [],
    orderInfo: {
      companyName: '',
      address: '',
      capital: '',
      represent: '',
      established: '',
      business: '',
    },
  });
  const [projectSchedule, setProjectSchedule] = useState<projectSchedule>();
  const [applicantListCurrentPage, setApplicantListCurrentPage] = useState<number>(1);
  const [sortData, setSortData] = useState<GetPrstApplicantsOrderEnum>('asc');
  const [perPage, setPerPage] = useState<number>(10);
  const [oderWord, setOrderWord] = useState<string>('idx');
  const [selectionStatus, setSelectionStatus] = useState<GetPrstApplicantsStatusEnum>();

  const [header, setHeader] = useState<HeaderProps>({
    headerSmallTitle: '',
    headerBigTitle: '',
    implementationPeriod: {
      from: '',
      to: '',
    },
  });
  const [tabInfo, setTabInfo] = useState<TabInfo[]>([]);
  const [title, setTitle] = useState<string | undefined>(undefined);
  const [image, setImage] = useState<string | undefined>(undefined);
  const [selectSNS, setSelectSNS] = useState<string | undefined>(undefined);
  const [titleTags, setTitleTags] = useState<Array<string> | undefined>(undefined);
  const [notice, setNotice] = useState<string[]>([]);
  const [isSecret, setIsSecret] = useState<boolean>(false);

  const [images, setImages] = useState<ProjectImageRow[]>([]);

  const [headcount, setHeadcount] = useState<number | undefined>(undefined);
  const [compensationAmount, setCompensationAmount] = useState<number | undefined>(undefined);
  // const [compensationPaymentUnit, setCompensationPaymentUnit] = useState<string | undefined>(undefined);
  const [preferredQualifications, setPreferredQualifications] = useState<Array<string> | undefined>(undefined);
  const [description, setDescription] = useState<string | undefined>(undefined);
  const [creatorImage, setCreatorImage] = useState<string | undefined>(undefined);
  const [start, setStart] = useState<string | undefined>(undefined);
  const [end, setEnd] = useState<string | undefined>(undefined);

  const [visibilitySettings, setVisibilitySettings] = useState<number | undefined>(undefined);
  const [companyName, setCompanyName] = useState<string | undefined>(undefined);
  const [cautionsList, setCautionsList] = useState<Array<string>>([]);
  const [operationType, setOperationType] = useState<string>('');
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const [kpiList, setKpiList] = useState<ProjectKpi>();
  const [summary, setSummary] = useState<PrstSummary | undefined>(undefined);
  const [estimate, setEstimate] = useState<ProjectEstimate | undefined>(undefined);

  const [applicant, setApplicant] = useState<GetProjectApplicantsOutput | undefined>({
    from: 0,
    to: 0,
    perPage: 0,
    totalRows: 0,
    totalPages: 0,
    rows: [],
    count: {
      applicant: 0,
      approved: 0,
      reserved: 0,
      canBeReserver: 0,
    },
    rewards: 0,
    total: 0,
    adoptionPlan: 0,
    taxRate: 0,
    fractionType: '',
  });

  const pahse: phaseInfo[] = [
    {
      name: convertStatusToDisplayLabelForPrst(PROJECT_STATUS.POST_DRAFT),
      isCurrent:
        PROJECT_STATUS.DRAFT == status || PROJECT_STATUS.POST_DRAFT == status || PROJECT_STATUS.POST_REVIEW == status,
      isDone: 1 < convertStatusToStepForPrst(status),
    },
    {
      name: convertStatusToDisplayLabelForPrst(PROJECT_STATUS.APPROVED),
      isCurrent: PROJECT_STATUS.APPROVED == status,
      isDone: convertStatusToStepForPrst(status) > convertStatusToStepForPrst(PROJECT_STATUS.APPROVED),
    },
    {
      name: convertStatusToDisplayLabelForPrst(PROJECT_STATUS.FINAL_CONFIRMATION),
      isCurrent: PROJECT_STATUS.FINAL_CONFIRMATION == status,
      isDone: convertStatusToStepForPrst(status) > convertStatusToStepForPrst(PROJECT_STATUS.FINAL_CONFIRMATION),
    },
    {
      name: convertStatusToDisplayLabelForPrst(PROJECT_STATUS.COMPLETION),
      isCurrent: PROJECT_STATUS.COMPLETION == status,
      isDone: convertStatusToStepForPrst(status) > convertStatusToStepForPrst(PROJECT_STATUS.COMPLETION),
    },
  ];

  const fetchDataAsync = async () => {
    try {
      const defaultApi = DefaultApiFactory(undefined, undefined, axiosInstance);
      const response = await defaultApi.getPrstDetail(projectId);
      return response;
    } catch (error) {
      Sentry.captureException(error);
      console.error('detailTabDataデータの取得に失敗しました。', error);
    }
  };

  const fetchProjectInfo = async () => {
    fetchDataAsync().then((detailTabData) => {
      // kpiは申請ボタン有効化のため、どのステータスでも内容を表示する状態にする
      setKpiList(
        detailTabData && detailTabData.data && detailTabData.data.kpi
          ? detailTabData.data.kpi
          : {
              items: [],
            }
      );
      setSummary(detailTabData?.data.summary || undefined);

      setStatus(detailTabData?.data.header.projectStatus || '');

      setTitle(detailTabData && detailTabData.data.title ? detailTabData.data.title : '');
      setTitleTags(detailTabData && detailTabData.data.tags ? detailTabData.data.tags : []);
      setImage(detailTabData && detailTabData.data.featuredImage ? detailTabData.data.featuredImage : '');

      setNotice(detailTabData?.data.details.notice || []);
      setIsSecret(detailTabData?.data.details.isSecret || false);

      setImages(detailTabData && detailTabData.data.detailImages ? detailTabData.data.detailImages : []);

      setHeader({
        headerSmallTitle: detailTabData?.data.header.projectName
          ? detailTabData?.data.header.projectName
          : '案件名が入ります',
        headerBigTitle: detailTabData?.data.header.projectGroupName
          ? detailTabData?.data.header.projectGroupName
          : '案件名が入ります',
        implementationPeriod: {
          from: detailTabData?.data.header.scheduleEndedAt ? detailTabData?.data.header.scheduleEndedAt : '',
          to: detailTabData?.data.header.scheduleEndedAt ? detailTabData?.data.header.scheduleEndedAt : '',
        },
      });

      setSelectSNS(detailTabData?.data?.snsType || '');

      setEstimate(detailTabData?.data.estimate || undefined);
    });
  };

  const getApplicantData = async (rows: GetPrstApplicantsRowsEnum = 10, page: number) => {
    try {
      const defaultApi = DefaultApiFactory(undefined, undefined, axiosInstance);
      const response = await defaultApi.getPrstApplicants(
        projectId,
        rows,
        page,
        sortData as GetPrstApplicantsOrderEnum,
        oderWord,
        selectionStatus as GetPrstApplicantsStatusEnum
      );
      console.log('応募者データの取得に成功しました。', response);
      return response;
    } catch (error) {
      Sentry.captureException(error);
      console.error('応募者データの取得に失敗しました。', error);
    }
  };

  const fetchPrstProjectDetail = async () => {
    try {
      const defaultApi = DefaultApiFactory(undefined, undefined, axiosInstance);
      const response = await defaultApi.getPrstDetail(projectId);
      console.log('案件詳細データの取得に成功しました。', response);
      return response;
    } catch (error) {
      Sentry.captureException(error);
      console.error('案件詳細データの取得に失敗しました。', error);
    }
  };

  const getApplicantProp = async () => {
    const applicantData = await getApplicantData(10, applicantListCurrentPage);
    const projectDetailData = await fetchPrstProjectDetail();
    if (applicantData && applicantData.data && projectDetailData && projectDetailData.data) {
      const applicantDataWithRewards: GetProjectApplicantsOutput = {
        from: applicantData.data.from,
        to: applicantData.data.to,
        perPage: applicantData.data.perPage,
        totalRows: applicantData.data.totalRows,
        totalPages: applicantData.data.totalPages,
        rows: applicantData.data.rows,
        count: applicantData.data.count,
        rewards: projectDetailData.data.summary.rewards,
        total: projectDetailData.data.estimate.total,
        adoptionPlan: projectDetailData.data.summary.adoptionPlan,
        taxRate: projectDetailData.data.estimate.taxRate,
        fractionType: projectDetailData.data.estimate.fractionType,
      };
      setApplicant((prev) => {
        return {
          ...prev,
          ...applicantDataWithRewards,
        };
      });
    }
  };

  // 募集内容タブ・スケジュールタブデータ取得・設定
  useEffect(() => {
    const getProjectOverview = async () => {
      try {
        const defaultApi = new OverviewDefaultApi(undefined, undefined, axiosInstance);
        const response = await defaultApi.getProjectOverview(projectId);
        return response;
      } catch (error) {
        Sentry.captureException(error);
        console.error('overviewデータの取得に失敗しました。', error);
      }
    };

    const getSchedules = async () => {
      try {
        const defaultApi = DefaultApiFactory(undefined, undefined, axiosInstance);
        const response = await defaultApi.getPrstSchedules(projectId);
        return response.data;
      } catch (error) {
        Sentry.captureException(error);
        console.error('スケジュールの取得に失敗しました。', error);
      }
    };

    getProjectOverview().then((overviewData) => {
      setOutlineInfo({
        marketBackground: overviewData?.data?.marketBackground || '',
        problem: overviewData?.data?.problem || '',
        purpose: overviewData?.data?.purpose || '',
        measure: overviewData?.data?.measure || '',
        kpi: overviewData?.data?.kpi || [],
        budget: overviewData?.data?.budget || 0,
        scheduleStartedAt: overviewData?.data?.scheduleStartedAt || '',
        scheduleEndedAt: overviewData?.data?.scheduleEndedAt || '',
        orderInfo: {
          companyName: overviewData?.data?.orderInfo?.companyName || '',
          address: overviewData?.data?.orderInfo?.address || '',
          represent: overviewData?.data?.orderInfo?.represent || '',
          established: overviewData?.data?.orderInfo?.established || '',
          capital: overviewData?.data?.orderInfo?.capital || '',
          business: overviewData?.data?.orderInfo?.business || '',
        },
      });
    });

    fetchProjectInfo();

    getSchedules().then((projectSchedule) => {
      if (projectSchedule) {
        setProjectSchedule({
          projectStatus: convertStatusToStep(status),
          scheduleData: projectSchedule,
          projectType: PROJECT_TYPES.PRST,
        });
      }
    });

    // fetchDataAsync();
  }, [projectId, status]);

  useEffect(() => {
    getApplicantProp();
  }, [applicantListCurrentPage, sortData, selectionStatus]);

  // 応募者選定期間抽出用
  // from: インフルエンサーの募集期間の開始
  // to: インフルエンサーの選定期間の終了
  function filterByPrstSchedule(items: ScheduleList): ApplicantSchedule {
    const recruitmentPeriod = items.find((item) => item.label.trim() == 'インフルエンサー募集期間');
    const selectionPeriod = items.find((item) => item.label.trim() == 'インフルエンサー選定期間');
    const schedule: ApplicantSchedule = {
      from: formatYYYYMMDD(recruitmentPeriod?.from || ''),
      to: formatYYYYMMDD(selectionPeriod?.to || ''),
    };
    return schedule;
  }

  // タブ切り替え時更新
  useEffect(() => {
    setTabInfo(() => [
      {
        title: '案件概要',
        icon: DocumentTextIcon,
        component: (props: projectOverview) => <ProjectOverview {...props} />,
        componentProps: outlineInfo,
        query: 'overview',
        isActive: queryTab === 'overview',
      },
      {
        title: '募集内容詳細',
        icon: PencilIcon,
        component: (props: popupInputData) => <Recruitment {...props} />,
        componentProps: {
          status: status,
          title: title,
          setTitle: setTitle,
          summary: summary,
          setSummary: setSummary,
          image: image,
          setImage: setImage,
          selectSNS: selectSNS,
          setSelectSNS: setSelectSNS,
          images: images,
          setImages: setImages,
          titleTags: titleTags,
          setTitleTags: setTitleTags,
          headcount: headcount,
          setHeadcount: setHeadcount,
          compensationAmount: compensationAmount,
          setCompensationAmount: setCompensationAmount,
          preferredQualifications: preferredQualifications,
          setPreferredQualifications: setPreferredQualifications,
          description: description,
          setDescription: setDescription,
          visibilitySettings: visibilitySettings,
          setVisibilitySettings: setVisibilitySettings,
          companyName: companyName,
          setCompanyName: setCompanyName,
          cautionsList: cautionsList,
          setCautionsList: setCautionsList,
          operationType: operationType,
          setOperationType: setOperationType,
          creatorImage: creatorImage,
          setCreatorImage: setCreatorImage,
          start: start,
          setStart: setStart,
          end: end,
          setEnd: setEnd,
          isRequesting: isRequesting,
          setIsRequesting: setIsRequesting,
          projectId: projectId,
          notice: notice,
          setNotice: setNotice,
          isSecret: isSecret,
          setIsSecret: setIsSecret,
          fetchProjectInfo: fetchProjectInfo,
          kpiList: kpiList,
          estimate: estimate,
        } as popupInputData,
        query: 'detail',
        isActive: queryTab === 'detail',
      },
      {
        title: 'スケジュール',
        icon: CalendarIcon,
        component: (props: ScheduleProps) => <ScheduleContent {...props} />,
        componentProps: {
          scheduleData: projectSchedule || [],
          projectStatus: convertStatusToStepForPrst(status),
        },
        query: 'schedule',
        isActive: queryTab === 'schedule',
      },
      {
        title: '応募者',
        icon: ApplicantIcon,
        component: (props: ApplicantProps) => <ApplicantContent {...props} />,
        componentProps: {
          applicant: applicant,
          setApplicant: setApplicant,
          projectId: projectId,
          getApplicantData: getApplicantProp,
          applicantListCurrentPage,
          setApplicantListCurrentPage,
          schedule: projectSchedule?.scheduleData ? filterByPrstSchedule(projectSchedule.scheduleData) : undefined,
          sortData,
          setSortData,
          perPage: perPage,
          setPerPage: setPerPage,
          setOrderWord: setOrderWord,
          setSelectionStatus: setSelectionStatus,
        },
        query: 'applicant',
        isActive: queryTab === 'applicant',
        className: 'fill-current',
      },
    ]);
  }, [
    outlineInfo,
    status,
    projectSchedule,
    title,
    image,
    selectSNS,
    titleTags,
    images,
    headcount,
    compensationAmount,
    // compensationPaymentUnit,
    preferredQualifications,
    description,
    visibilitySettings,
    companyName,
    creatorImage,
    start,
    end,
    cautionsList,
    queryTab,
    operationType,
    kpiList,
    isRequesting,
    applicant?.rows,
  ]);

  return (
    <MainLayout
      sidebarProps={{
        firstSideBarselected: firstSidebarSelect.calendar,
        secondSideBarselected: secondSidebarSelect.prst,
      }}
    >
      <div className="flex h-[auto] w-[1440px] bg-gray-50">
        <div className="h-full w-[1181px] bg-gray-50">
          <div>{PageHeader(header)}</div>
          <div>{PhaseNavigation(pahse)}</div>
          <div className="pt-[10px] ">
            <div className="h-full w-[1181px] border-[1px] border-gray-200">
              {tabInfo.length > 0 && <TabView tabInfo={tabInfo} />}
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
