import { useEffect, useState, useRef } from 'react';

import {
  AnalysisSpiritPostInsightInstagram,
  AnalysisSpiritPostInstagram,
  AnalysisSpiritPostContentInstagramMediaTypeEnum,
  SnsType,
} from '@/api/analysisSpirit';
import { ReactComponent as ChenverDown } from '@/assets/icons/chevron-down.svg';
import { ReactComponent as ChenverUp } from '@/assets/icons/chevron-up.svg';
import { fetchInfluencerApplicantDetail } from '@/hooks/Influencer';
import { InstagramPostType } from '@/types/index';
import { getImageUrlIfRelative } from '@/utils/image';
import { convertInstgramPostTaskType, isPostTaskTypeFeed, isPostTaskTypeStories, isPostTypeReel } from '@/utils/sns';

import { postProps } from '../../../interfaces/analysisInterface';
import { tableBodyInstagramProps } from '../common/interfaces';
import { SummaryFeed, SummaryStories, SummaryReal } from '../instgram/summary';
import ModalCommon from '../modals/modalCommon';
import { InstagramContent, tableBodyCommonProps } from '../modals/modalInterface';

interface SortInfoProps {
  id: boolean;
  numberOfImpressions: boolean;
  uniqueAccount: boolean;
  engagementRate: boolean;
  numberOfEngagements: boolean;
  goods: boolean;
  comments: boolean;
  share: boolean;
  save: boolean;
  frequency: boolean;
  tapForward: boolean;
  tapBack: boolean;
  end: boolean;
  playCounts: boolean;
}

export interface PostInstagramProps {
  postTasks: postProps[];
}

export default function PostInstagram(props: PostInstagramProps) {
  const [rowTableBody, setRowTableBody] = useState<tableBodyInstagramProps[]>([]);
  const [ascInfo, setAscInfo] = useState<SortInfoProps>({
    id: true,
    engagementRate: true,
    numberOfEngagements: true,
    goods: true,
    comments: true,
    save: true,
    share: true,
    numberOfImpressions: true,
    uniqueAccount: true,
    frequency: true,
    tapForward: true,
    tapBack: true,
    end: true,
    playCounts: true,
  });

  // 初期画面に表示するテーブルデータ作成用ステート
  const [currentData, setCurrentData] = useState<tableBodyInstagramProps[]>([]);
  const [open, setOpen] = useState<boolean>(false);

  const [modalInformation, setModalInformation] = useState<tableBodyCommonProps>({
    icon: '', // アイコン
    id: '', //ID
    applyingInfluencerId: '', // 応募者ID
    image: '', //サムネイル
    engagementRate: '', //エンゲージメント率
    numberOfEngagements: 0, //エンゲージメント数
    goods: 0, //いいね数
    comment: 0, //コメント数
    shareCount: 0, //シェア数
    saveCount: 0, //保存数
    impressionCount: 0, //
    uniqueAccountCount: 0, //ユニークアカウント数
    frequency: 0, //フリークエンシー
    tapsForwardCount: 0, // タップ進む数
    tapsBackCount: 0, // タップもドル数
    exitsCount: 0, // 退出数
    viewCount: 0, //再生数
    postCount: 0, // 投稿回数
    mediaType: '', // 投稿種別
    // 投稿内容
    mediaProps: {
      snsType: SnsType.Instagram,
      content: { mediaType: 'IMAGE' } as InstagramContent,
    },
    postedAt: '', // 投稿日時
  });

  let selectInitialPostsId = '';
  let selectInitialPostTask: postProps = {} as postProps;
  if (props.postTasks.length > 0) {
    selectInitialPostsId = props.postTasks[0].task.taskId;
    selectInitialPostTask = props.postTasks[0];
  }
  const [selectedPost, setSelectedPost] = useState<string>(selectInitialPostsId);
  const [selectedPostTask, setSelectedPostTask] = useState<postProps>(selectInitialPostTask);

  const setPostTask = (taskId: string) => {
    setSelectedPost(taskId);
    setSelectedPostTask(props.postTasks.filter((p) => p.task.taskId == taskId)[0]);
  };

  const tablebodies = (taskId: string, postCount = 1): tableBodyInstagramProps[] => {
    const tmpPostTask = props.postTasks.filter((p) => p.task.taskId == taskId)[0];
    if (!tmpPostTask) return [];
    const result: tableBodyInstagramProps[] = [];
    postCount = props.postTasks.findIndex((post) => post.task.taskId == taskId) + 1;
    tmpPostTask.posts.posts.forEach((post) => {
      const tmpPost: AnalysisSpiritPostInstagram = post as AnalysisSpiritPostInstagram;
      const insight: AnalysisSpiritPostInsightInstagram = post.insight as AnalysisSpiritPostInsightInstagram;
      let image = tmpPost.content ? getImageUrlIfRelative(tmpPost.content.postImage ?? '') : '';
      if (tmpPost.content?.mediaType == AnalysisSpiritPostContentInstagramMediaTypeEnum.CarouselAlbum) {
        image = tmpPost.content
          ? getImageUrlIfRelative(tmpPost.content.carouselImages ? tmpPost.content.carouselImages[0] : '')
          : '';
      } else if (tmpPost.content?.mediaType == AnalysisSpiritPostContentInstagramMediaTypeEnum.Video) {
        image = tmpPost.content ? getImageUrlIfRelative(tmpPost.content.thumbnail ?? '') : '';
      }
      result.push({
        icon: getImageUrlIfRelative(tmpPost.account.snsAccountThumbnail ?? ''),
        id: tmpPost.account.snsAccountNo,
        image: image,
        numberOfImpressions: insight ? insight.impressionCount : 0,
        uniqueAccount: insight ? insight.uniqueAccountCount : 0,
        engagementRate: insight ? `${insight.engagementRate}%` : '0%',
        numberOfEngagements: insight ? insight.engagementCount : 0,
        goods: insight ? insight.goodCount : 0,
        comments: insight ? insight.commentCount : 0,
        share: insight ? insight.shareCount : 0,
        save: insight ? insight.saveCount : 0,
        frequency: insight ? insight.frequency : 0,
        tapForward: insight ? insight.tapsForwardCount : 0,
        tapBack: insight ? insight.tapsBackCount : 0,
        end: insight ? insight.exitsCount : 0,
        playCounts: insight ? insight.viewCount : 0,
        caption: tmpPost.content ? tmpPost.content.caption : '',
        applyingInfluencerId: tmpPost.account.applyingInfluencerId,
        postCount: postCount,
        mediaType: tmpPost.content?.mediaType || '',
        content: {
          postImage: getImageUrlIfRelative(tmpPost.content?.postImage || ''),
          postVideo: getImageUrlIfRelative(tmpPost.content?.postVideo || ''),
          postCarousel: getImageUrlIfRelative(tmpPost.content?.postCarousel || ''),
          carouselImages: tmpPost.content?.carouselImages
            ? tmpPost.content?.carouselImages.map((val) => getImageUrlIfRelative(val))
            : null,
          mediaType: tmpPost.content?.mediaType || '',
          caption: tmpPost.content?.caption || '',
        },
        postedAt: tmpPost.content?.postedAt || '',
      } as tableBodyInstagramProps);
    });
    return result;
  };

  const handleSort = (name: keyof tableBodyInstagramProps, value: boolean) => {
    const tmp = rowTableBody;
    if (value) {
      tmp.sort((a: tableBodyInstagramProps, b: tableBodyInstagramProps) => (a[name] > b[name] ? 1 : -1));
    } else {
      tmp.sort((a: tableBodyInstagramProps, b: tableBodyInstagramProps) => (a[name] < b[name] ? 1 : -1));
    }
    // setTableBody(tmp);
    setCurrentData(tmp);
    setAscInfo({ ...ascInfo, [name]: value });
  };
  const moveRef_header = useRef<HTMLDivElement>(null);
  const moveRef_body = useRef<HTMLDivElement>(null);

  const scrollLimit = (selectedPost: InstagramPostType) => {
    if (selectedPost === 'Feed') {
      return 820;
    } else if (selectedPost === 'Story') {
      return 360;
    } else {
      return 820;
    }
  };

  const ThumbnailElement = (path: string) => {
    if (path.split('.').pop() === 'mp4') {
      // eslint-disable-next-line jsx-a11y/media-has-caption
      return <video src={path}></video>;
    } else {
      return <img src={path} alt="" className="h-10 w-10 rounded-md object-cover" />;
    }
  };

  const scroll_event = (e: any, selectedPost: InstagramPostType) => {
    if (moveRef_header.current && moveRef_body.current) {
      moveRef_header.current.scrollLeft =
        e.target.scrollLeft < scrollLimit(selectedPost) ? e.target.scrollLeft : scrollLimit(selectedPost);
      moveRef_body.current.scrollLeft =
        e.target.scrollLeft < scrollLimit(selectedPost) ? e.target.scrollLeft : scrollLimit(selectedPost);
    }
  };

  const handleOpenModal = (info: tableBodyInstagramProps) => {
    const modalData: tableBodyCommonProps = {
      icon: info.icon,
      id: info.id,
      applyingInfluencerId: info.applyingInfluencerId,
      image: info.image,
      engagementRate: info.engagementRate,
      engagementCount: info.numberOfEngagements,
      numberOfEngagements: info.numberOfEngagements,
      goodCount: info.goods,
      commentCount: info.comments,
      shareCount: info.share,
      saveCount: info.save,
      impressionCount: info.numberOfImpressions,
      uniqueAccountCount: info.uniqueAccount,
      frequency: info.frequency,
      tapsForwardCount: info.tapForward,
      tapsBackCount: info.tapBack,
      exitsCount: info.end,
      postCount: info.postCount,
      mediaType: info.mediaType,
      postedAt: info.postedAt,
      mediaProps: {
        snsType: SnsType.Instagram,
        content: info.content,
      },
    };
    setModalInformation(modalData);
    setOpen(true);
    //setIndex(index);
  };

  useEffect(() => {
    const bodies = tablebodies(selectedPost);
    // setTableBody(bodies);
    setRowTableBody(bodies);

    // 初期画面に表示するテーブルデータ作成
    setCurrentData(bodies);
  }, []);

  const selectPostTab = () => {
    const tmpPostTask = props.postTasks.filter((p) => p.task.taskId == selectedPost)[0];
    const tmpTaskType = convertInstgramPostTaskType(tmpPostTask.task.taskType);
    const summary = tmpPostTask.posts.summary as AnalysisSpiritPostInsightInstagram;
    if (tmpTaskType === 'Feed') {
      return SummaryFeed(
        'w-[366px]',
        summary.impressionCount ? summary.impressionCount.toLocaleString() : '集計中',
        summary.uniqueAccountCount ? summary.uniqueAccountCount.toLocaleString() : '集計中',
        summary.engagementRate ? `${summary.engagementRate.toLocaleString()}%` : '集計中',
        summary.engagementCount ? summary.engagementCount.toLocaleString() : '集計中',
        summary.goodCount ? summary.goodCount.toLocaleString() : '集計中',
        summary.commentCount ? summary.commentCount.toLocaleString() : '集計中',
        summary.shareCount ? summary.shareCount.toLocaleString() : '集計中',
        summary.saveCount ? summary.saveCount.toLocaleString() : '集計中',
        summary.frequency ? summary.frequency.toLocaleString() : '集計中'
      );
    } else if (tmpTaskType === 'Story') {
      return SummaryStories(
        'w-[366px]',
        summary.impressionCount ? summary.impressionCount.toLocaleString() : '集計中',
        summary.uniqueAccountCount ? summary.uniqueAccountCount.toLocaleString() : '集計中',
        summary.tapsForwardCount ? summary.tapsForwardCount.toLocaleString() : '集計中',
        summary.tapsBackCount ? summary.tapsBackCount.toLocaleString() : '集計中',
        summary.exitsCount ? summary.exitsCount.toLocaleString() : '集計中'
      );
    } else {
      return SummaryReal(
        'w-[366px]',
        summary.viewCount ? summary.viewCount.toLocaleString() : '集計中',
        summary.uniqueAccountCount ? summary.uniqueAccountCount.toLocaleString() : '集計中',
        summary.engagementRate ? `${summary.engagementRate.toLocaleString()}%` : '集計中',
        summary.engagementCount ? summary.engagementCount.toLocaleString() : '集計中',
        summary.goodCount ? summary.goodCount.toLocaleString() : '集計中',
        summary.commentCount ? summary.commentCount.toLocaleString() : '集計中',
        summary.saveCount ? summary.saveCount.toLocaleString() : '集計中',
        summary.shareCount ? summary.shareCount.toLocaleString() : '集計中',
        summary.frequency ? summary.frequency.toLocaleString() : '集計中'
      );
    }
  };

  return (
    <div className="flex flex-col items-start gap-6 self-stretch p-6">
      <div className="flex flex-col items-start gap-10 self-stretch">
        <div className="flex flex-col items-start gap-10 self-stretch">
          <div className="flex items-center gap-6">
            <div className="flex h-[34px] flex-col items-start">
              <div className="w-[177px]">
                <select
                  id="graph-type"
                  className="flex w-[190px] flex-1 items-center justify-center gap-2 rounded-md border border-gray-300 py-[9px] pl-[17px] pr-[13px] text-sm font-medium leading-5 text-gray-700"
                  defaultValue={selectedPost}
                  onChange={(e) => {
                    setPostTask(e.target.value);
                    const tmpBodies = tablebodies(e.target.value);
                    setRowTableBody(tmpBodies);
                    setCurrentData(tmpBodies);
                  }}
                >
                  {props.postTasks.map((postTask, index) => {
                    return (
                      <option key={postTask.task.taskId} value={postTask.task.taskId}>
                        {index + 1}回目投稿
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className="flex w-[740px] flex-col items-start gap-4">
            <div className="flex flex-col items-start gap-1 self-stretch">
              <span className="text-sm font-bold">投稿サマリー</span>
            </div>
            {selectPostTab()}
          </div>
          <div className="flex flex-col items-start gap-4 self-stretch">
            <span className="text-sm font-bold">投稿一覧</span>
            {isPostTaskTypeFeed(selectedPostTask.task.taskType) && (
              <div className="flex flex-col items-start self-stretch rounded-lg border border-gray-200">
                <div className="flex flex-col items-start gap-2 ">
                  <div>
                    <div className="divide-y divide-gray-200 rounded-lg bg-white">
                      <div
                        className="w-[1050px] overflow-auto rounded-t-lg scrollbar-hide"
                        ref={moveRef_header}
                        onScroll={(event) => scroll_event(event, 'Feed')}
                      >
                        <div className="sticky top-0 inline-block">
                          <div className="flex h-[56px]">
                            <div className="w-[100px] items-center bg-white px-6 py-[14px]">
                              <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                アイコン
                              </span>
                            </div>
                            <div className="w-[152px] items-center bg-white px-6 py-4">
                              <div className="flex flex-1 items-center gap-2">
                                <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                  ID
                                </span>
                              </div>
                            </div>
                            <div className="w-[120px] items-center bg-white px-6 py-[14px]">
                              <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                サムネイル
                              </span>
                            </div>
                            <div className="w-[200px] items-center bg-white px-6 py-4">
                              <div className="flex flex-1 items-center gap-2">
                                <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                  インプレッション数
                                </span>
                                <div className="rounded-[4px] bg-gray-200">
                                  <div className="flex h-5 w-5 items-center  justify-center ">
                                    {ascInfo.numberOfImpressions ? (
                                      <ChenverDown
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('numberOfImpressions', false)}
                                        stroke="#9CA3AF"
                                      />
                                    ) : (
                                      <ChenverUp
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('numberOfImpressions', true)}
                                        stroke="#9CA3AF"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="w-[212px] items-center bg-white px-6 py-4">
                              <div className="flex flex-1 items-center gap-2">
                                <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                  ユニークアカウント数
                                </span>
                                <div className="rounded-[4px] bg-gray-200">
                                  <div className="flex h-5 w-5 items-center  justify-center ">
                                    {ascInfo.uniqueAccount ? (
                                      <ChenverDown
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('uniqueAccount', false)}
                                        stroke="#9CA3AF"
                                      />
                                    ) : (
                                      <ChenverUp
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('uniqueAccount', true)}
                                        stroke="#9CA3AF"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="w-[200px] items-center bg-white px-6 py-4">
                              <div className="flex flex-1 items-center gap-2">
                                <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                  エンゲージメント率
                                </span>
                                <div className="rounded-[4px] bg-gray-200">
                                  <div className="flex h-5 w-5 items-center  justify-center ">
                                    {ascInfo.engagementRate ? (
                                      <ChenverDown
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('engagementRate', false)}
                                        stroke="#9CA3AF"
                                      />
                                    ) : (
                                      <ChenverUp
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('engagementRate', true)}
                                        stroke="#9CA3AF"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="w-[200px] items-center bg-white px-6 py-4">
                              <div className="flex flex-1 items-center gap-2">
                                <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                  エンゲージメント数
                                </span>
                                <div className="rounded-[4px] bg-gray-200">
                                  <div className="flex h-5 w-5 items-center  justify-center ">
                                    {ascInfo.numberOfEngagements ? (
                                      <ChenverDown
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('numberOfEngagements', false)}
                                        stroke="#9CA3AF"
                                      />
                                    ) : (
                                      <ChenverUp
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('numberOfEngagements', true)}
                                        stroke="#9CA3AF"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="w-[132px] items-center bg-white px-6 py-4">
                              <div className="flex flex-1 items-center gap-2">
                                <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                  いいね数
                                </span>
                                <div className="rounded-[4px] bg-gray-200">
                                  <div className="flex h-5 w-5 items-center  justify-center ">
                                    {ascInfo.goods ? (
                                      <ChenverDown
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('goods', false)}
                                        stroke="#9CA3AF"
                                      />
                                    ) : (
                                      <ChenverUp
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('goods', true)}
                                        stroke="#9CA3AF"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="w-[139px] items-center bg-white px-6 py-4">
                              <div className="flex flex-1 items-center gap-2">
                                <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                  コメント数
                                </span>
                                <div className="rounded-[4px] bg-gray-200">
                                  <div className="flex h-5 w-5 items-center  justify-center ">
                                    {ascInfo.comments ? (
                                      <ChenverDown
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('comments', false)}
                                        stroke="#9CA3AF"
                                      />
                                    ) : (
                                      <ChenverUp
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('comments', true)}
                                        stroke="#9CA3AF"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="w-[142px] items-center bg-white px-6 py-4">
                              <div className="flex flex-1 items-center gap-2">
                                <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                  シェア数
                                </span>
                                <div className="rounded-[4px] bg-gray-200">
                                  <div className="flex h-5 w-5 items-center  justify-center ">
                                    {ascInfo.share ? (
                                      <ChenverDown
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('share', false)}
                                        stroke="#9CA3AF"
                                      />
                                    ) : (
                                      <ChenverUp
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('share', true)}
                                        stroke="#9CA3AF"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="w-[114px] items-center bg-white px-6 py-4">
                              <div className="flex flex-1 items-center gap-2">
                                <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                  保存数
                                </span>
                                <div className="rounded-[4px] bg-gray-200">
                                  <div className="flex h-5 w-5 items-center  justify-center ">
                                    {ascInfo.save ? (
                                      <ChenverDown
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('save', false)}
                                        stroke="#9CA3AF"
                                      />
                                    ) : (
                                      <ChenverUp
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('save', true)}
                                        stroke="#9CA3AF"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="w-[182px] items-center bg-white px-6 py-4">
                              <div className="flex flex-1 items-center gap-2">
                                <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                  フリークエンシー
                                </span>
                                <div className="rounded-[4px] bg-gray-200">
                                  <div className="flex h-5 w-5 items-center  justify-center ">
                                    {ascInfo.frequency ? (
                                      <ChenverDown
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('frequency', false)}
                                        stroke="#9CA3AF"
                                      />
                                    ) : (
                                      <ChenverUp
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('frequency', true)}
                                        stroke="#9CA3AF"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="w-[1050px] divide-y divide-gray-200 overflow-auto rounded-b-lg"
                        onScroll={(event) => scroll_event(event, 'Feed')}
                        ref={moveRef_body}
                      >
                        {currentData.every((item) => Object.values(item).every((value) => value === '')) ||
                        currentData.length === 0 ? (
                          <div className="flex justify-center py-20 text-2xl">
                            投稿一覧は、投稿が行われた後に表示されます
                          </div>
                        ) : (
                          currentData.map((body: tableBodyInstagramProps, index: number) => {
                            return (
                              <div
                                key={index}
                                onClick={() => handleOpenModal(body)}
                                onKeyDown={() => handleOpenModal(body)}
                                className={
                                  'flex w-[1902px] items-center hover:cursor-pointer hover:bg-gray-100' +
                                  (index % 2 == 0 ? '' : ' bg-gray-50')
                                }
                                role="presentation"
                              >
                                <div className="w-[98px]  px-6">
                                  <img src={body.icon} alt="" />
                                </div>
                                <div className="w-[152px] truncate px-6 py-4">
                                  <span>{body.id}</span>
                                </div>
                                <div className="w-[120px] px-6">{ThumbnailElement(body.image)}</div>
                                <div className="w-[200px] px-6 py-4">
                                  <span>{body.numberOfImpressions}</span>
                                </div>
                                <div className="w-[212px] px-6 py-4">
                                  <span>{body.uniqueAccount}</span>
                                </div>
                                <div className="w-[200px] px-6 py-4">
                                  <span>{body.engagementRate}</span>
                                </div>
                                <div className="w-[200px] px-6 py-4">
                                  <span>{body.numberOfEngagements}</span>
                                </div>
                                <div className="w-[132px] px-6 py-4">
                                  <span>{body.goods}</span>
                                </div>
                                <div className="w-[139px] px-6 py-4">
                                  <span>{body.comments}</span>
                                </div>
                                <div className="w-[142px] px-6 py-4">
                                  <span>{body.share}</span>
                                </div>
                                <div className="w-[114px] px-6 py-4">
                                  <span>{body.save}</span>
                                </div>
                                <div className="w-[152px] px-6  py-4">
                                  <span>{body.frequency}</span>
                                </div>
                              </div>
                            );
                          })
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {isPostTaskTypeStories(selectedPostTask.task.taskType) && (
              <div className="flex flex-col items-start self-stretch rounded-lg border border-gray-200">
                <div className="flex flex-col items-start gap-2">
                  <div>
                    <div className="divide-y divide-gray-200 rounded-lg bg-white">
                      <div
                        className="w-[1050px] overflow-auto rounded-t-lg scrollbar-hide"
                        ref={moveRef_header}
                        onScroll={(event) => scroll_event(event, 'Story')}
                      >
                        <div className="sticky top-0 inline-block">
                          <div className="flex h-[56px]">
                            <div className="w-[98px] items-center bg-white px-6 py-[14px]">
                              <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                アイコン
                              </span>
                            </div>
                            <div className="w-[152px] items-center bg-white px-6 py-4">
                              <div className="flex flex-1 items-center gap-2">
                                <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                  ID
                                </span>
                              </div>
                            </div>
                            <div className="w-[120px] items-center bg-white px-6 py-[14px]">
                              <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                サムネイル
                              </span>
                            </div>
                            <div className="w-[200px] items-center bg-white px-6 py-4">
                              <div className="flex flex-1 items-center gap-2">
                                <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                  インプレッション数
                                </span>
                                <div className="rounded-[4px] bg-gray-200">
                                  <div className="flex h-5 w-5 items-center  justify-center ">
                                    {ascInfo.numberOfImpressions ? (
                                      <ChenverDown
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('numberOfImpressions', false)}
                                        stroke="#9CA3AF"
                                      />
                                    ) : (
                                      <ChenverUp
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('numberOfImpressions', true)}
                                        stroke="#9CA3AF"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="w-[212px] items-center bg-white px-6 py-4">
                              <div className="flex flex-1 items-center gap-2">
                                <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                  ユニークアカウント数
                                </span>
                                <div className="rounded-[4px] bg-gray-200">
                                  <div className="flex h-5 w-5 items-center  justify-center ">
                                    {ascInfo.uniqueAccount ? (
                                      <ChenverDown
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('uniqueAccount', false)}
                                        stroke="#9CA3AF"
                                      />
                                    ) : (
                                      <ChenverUp
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('uniqueAccount', true)}
                                        stroke="#9CA3AF"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="w-[212px] items-center bg-white px-6 py-4">
                              <div className="flex flex-1 items-center gap-2">
                                <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                  「次へ」のタップ回数
                                </span>
                                <div className="rounded-[4px] bg-gray-200">
                                  <div className="flex h-5 w-5 items-center  justify-center ">
                                    {ascInfo.tapForward ? (
                                      <ChenverDown
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('tapForward', false)}
                                        stroke="#9CA3AF"
                                      />
                                    ) : (
                                      <ChenverUp
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('tapForward', true)}
                                        stroke="#9CA3AF"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="w-[212px] items-center bg-white px-6 py-4">
                              <div className="flex flex-1 items-center gap-2">
                                <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                  「戻る」のタップ回数
                                </span>
                                <div className="rounded-[4px] bg-gray-200">
                                  <div className="flex h-5 w-5 items-center  justify-center ">
                                    {ascInfo.tapBack ? (
                                      <ChenverDown
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('tapBack', false)}
                                        stroke="#9CA3AF"
                                      />
                                    ) : (
                                      <ChenverUp
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('tapBack', true)}
                                        stroke="#9CA3AF"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="w-[232px] items-center bg-white px-6 py-4">
                              <div className="flex flex-1 items-center gap-2">
                                <span className="whitespace-pre-line text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                  {`メディアが\n閉じられた回数`}
                                </span>
                                <div className="rounded-[4px] bg-gray-200">
                                  <div className="flex h-5 w-5 items-center  justify-center ">
                                    {ascInfo.end ? (
                                      <ChenverDown
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('end', false)}
                                        stroke="#9CA3AF"
                                      />
                                    ) : (
                                      <ChenverUp
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('end', true)}
                                        stroke="#9CA3AF"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="w-[1050px] divide-y divide-gray-200 overflow-auto rounded-b-lg"
                        onScroll={(event) => scroll_event(event, 'Story')}
                        ref={moveRef_body}
                      >
                        {currentData.every((item) => Object.values(item).every((value) => value === '')) ||
                        currentData.length === 0 ? (
                          <div className="flex justify-center py-20 text-2xl">
                            投稿一覧は、投稿が行われた後に表示されます
                          </div>
                        ) : (
                          currentData.map((body: tableBodyInstagramProps, index: number) => {
                            return (
                              <div
                                key={index}
                                onClick={() => handleOpenModal(body)}
                                onKeyDown={() => handleOpenModal(body)}
                                className={
                                  'flex w-[1902px] items-center hover:cursor-pointer hover:bg-gray-100' +
                                  (index % 2 == 0 ? '' : ' bg-gray-50')
                                }
                                role="presentation"
                              >
                                <div className="w-[98px]  px-6">
                                  <img src={body.icon} alt="" />
                                </div>
                                <div className="w-[152px] truncate px-6 py-4">
                                  <span>{body.id}</span>
                                </div>
                                <div className="w-[120px] px-6">{ThumbnailElement(body.image)}</div>
                                <div className="w-[200px] px-6 py-4">
                                  <span>{body.numberOfImpressions}</span>
                                </div>
                                <div className="w-[212px] px-6 py-4">
                                  <span>{body.uniqueAccount}</span>
                                </div>
                                <div className="w-[212px] px-6 py-4">
                                  <span>{body.tapForward}</span>
                                </div>
                                <div className="w-[212px] px-6 py-4">
                                  <span>{body.tapBack}</span>
                                </div>
                                <div className="w-[232px] px-6 py-4">
                                  <span>{body.end}</span>
                                </div>
                              </div>
                            );
                          })
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {isPostTypeReel(selectedPostTask.task.taskType) && (
              <div className="flex flex-col items-start self-stretch rounded-lg border border-gray-200">
                <div className="flex flex-col items-start gap-2">
                  <div>
                    <div className="divide-y divide-gray-200 rounded-lg bg-white">
                      <div
                        className="w-[1050px] overflow-auto rounded-t-lg scrollbar-hide"
                        ref={moveRef_header}
                        onScroll={(event) => scroll_event(event, 'Reel')}
                      >
                        <div className="sticky top-0 inline-block">
                          <div className="flex h-[56px]">
                            <div className="w-[98px] items-center bg-white px-6 py-[14px]">
                              <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                アイコン
                              </span>
                            </div>
                            <div className="w-[152px] items-center bg-white px-6 py-4">
                              <div className="flex flex-1 items-center gap-2">
                                <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                  ID
                                </span>
                              </div>
                            </div>
                            <div className="w-[120px] items-center bg-white px-6 py-[14px]">
                              <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                サムネイル
                              </span>
                            </div>
                            <div className="w-[152px] items-center bg-white px-6 py-4">
                              <div className="flex flex-1 items-center gap-2">
                                <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                  再生回数
                                </span>
                                <div className="rounded-[4px] bg-gray-200">
                                  <div className="flex h-5 w-5 items-center  justify-center ">
                                    {ascInfo.playCounts ? (
                                      <ChenverDown
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('playCounts', false)}
                                        stroke="#9CA3AF"
                                      />
                                    ) : (
                                      <ChenverUp
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('playCounts', true)}
                                        stroke="#9CA3AF"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="w-[212px] items-center bg-white px-6 py-4">
                              <div className="flex flex-1 items-center gap-2">
                                <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                  ユニークアカウント数
                                </span>
                                <div className="rounded-[4px] bg-gray-200">
                                  <div className="flex h-5 w-5 items-center  justify-center ">
                                    {ascInfo.uniqueAccount ? (
                                      <ChenverDown
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('uniqueAccount', false)}
                                        stroke="#9CA3AF"
                                      />
                                    ) : (
                                      <ChenverUp
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('uniqueAccount', true)}
                                        stroke="#9CA3AF"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="w-[200px] items-center bg-white px-6 py-4">
                              <div className="flex flex-1 items-center gap-2">
                                <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                  エンゲージメント率
                                </span>
                                <div className="rounded-[4px] bg-gray-200">
                                  <div className="flex h-5 w-5 items-center  justify-center ">
                                    {ascInfo.engagementRate ? (
                                      <ChenverDown
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('engagementRate', false)}
                                        stroke="#9CA3AF"
                                      />
                                    ) : (
                                      <ChenverUp
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('engagementRate', true)}
                                        stroke="#9CA3AF"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="w-[200px] items-center bg-white px-6 py-4">
                              <div className="flex flex-1 items-center gap-2">
                                <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                  エンゲージメント数
                                </span>
                                <div className="rounded-[4px] bg-gray-200">
                                  <div className="flex h-5 w-5 items-center  justify-center ">
                                    {ascInfo.numberOfEngagements ? (
                                      <ChenverDown
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('numberOfEngagements', false)}
                                        stroke="#9CA3AF"
                                      />
                                    ) : (
                                      <ChenverUp
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('numberOfEngagements', true)}
                                        stroke="#9CA3AF"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="w-[132px] items-center bg-white px-6 py-4">
                              <div className="flex flex-1 items-center gap-2">
                                <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                  いいね数
                                </span>
                                <div className="rounded-[4px] bg-gray-200">
                                  <div className="flex h-5 w-5 items-center  justify-center ">
                                    {ascInfo.goods ? (
                                      <ChenverDown
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('goods', false)}
                                        stroke="#9CA3AF"
                                      />
                                    ) : (
                                      <ChenverUp
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('goods', true)}
                                        stroke="#9CA3AF"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="w-[152px] items-center bg-white px-6 py-4">
                              <div className="flex flex-1 items-center gap-2">
                                <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                  コメント数
                                </span>
                                <div className="rounded-[4px] bg-gray-200">
                                  <div className="flex h-5 w-5 items-center  justify-center ">
                                    {ascInfo.comments ? (
                                      <ChenverDown
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('comments', false)}
                                        stroke="#9CA3AF"
                                      />
                                    ) : (
                                      <ChenverUp
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('comments', true)}
                                        stroke="#9CA3AF"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="w-[132px] items-center bg-white px-6 py-4">
                              <div className="flex flex-1 items-center gap-2">
                                <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                  シェア数
                                </span>
                                <div className="rounded-[4px] bg-gray-200">
                                  <div className="flex h-5 w-5 items-center  justify-center ">
                                    {ascInfo.share ? (
                                      <ChenverDown
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('share', false)}
                                        stroke="#9CA3AF"
                                      />
                                    ) : (
                                      <ChenverUp
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('share', true)}
                                        stroke="#9CA3AF"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="w-[132px] items-center bg-white px-6 py-4">
                              <div className="flex flex-1 items-center gap-2">
                                <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                  保存数
                                </span>
                                <div className="rounded-[4px] bg-gray-200">
                                  <div className="flex h-5 w-5 items-center  justify-center ">
                                    {ascInfo.save ? (
                                      <ChenverDown
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('save', false)}
                                        stroke="#9CA3AF"
                                      />
                                    ) : (
                                      <ChenverUp
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('save', true)}
                                        stroke="#9CA3AF"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="w-[212px] items-center bg-white px-6 py-4">
                              <div className="flex flex-1 items-center gap-2">
                                <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                  フリークエンシー
                                </span>
                                <div className="rounded-[4px] bg-gray-200">
                                  <div className="flex h-5 w-5 items-center  justify-center ">
                                    {ascInfo.frequency ? (
                                      <ChenverDown
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('frequency', false)}
                                        stroke="#9CA3AF"
                                      />
                                    ) : (
                                      <ChenverUp
                                        width={14}
                                        height={14}
                                        onClick={() => handleSort('frequency', true)}
                                        stroke="#9CA3AF"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="w-[1050px] divide-y divide-gray-200 overflow-auto rounded-b-lg"
                        onScroll={(event) => scroll_event(event, 'Reel')}
                        ref={moveRef_body}
                      >
                        {currentData.every((item) => Object.values(item).every((value) => value === '')) ||
                        currentData.length === 0 ? (
                          <div className="flex justify-center py-20 text-2xl">
                            投稿一覧は、投稿が行われた後に表示されます
                          </div>
                        ) : (
                          currentData.map((body: tableBodyInstagramProps, index: number) => {
                            return (
                              <div
                                key={index}
                                onClick={() => handleOpenModal(body)}
                                onKeyDown={() => handleOpenModal(body)}
                                className={
                                  'flex w-[1902px] items-center hover:cursor-pointer hover:bg-gray-100' +
                                  (index % 2 == 0 ? '' : ' bg-gray-50')
                                }
                                role="presentation"
                              >
                                <div className="w-[98px]  px-6">
                                  <img src={body.icon} alt="" />
                                </div>
                                <div className="w-[152px] truncate px-6 py-4">
                                  <span>{body.id}</span>
                                </div>
                                <div className="w-[120px] px-6">{ThumbnailElement(body.image)}</div>
                                <div className="w-[152px] px-6 py-4">
                                  <span>{body.playCounts}</span>
                                </div>
                                <div className="w-[212px] px-6 py-4">
                                  <span>{body.uniqueAccount}</span>
                                </div>
                                <div className="w-[200px] px-6 py-4">
                                  <span>{body.engagementRate}</span>
                                </div>
                                <div className="w-[200px] px-6 py-4">
                                  <span>{body.numberOfEngagements}</span>
                                </div>
                                <div className="w-[132px] px-6 py-4">
                                  <span>{body.goods}</span>
                                </div>
                                <div className="w-[152px] px-6 py-4">
                                  <span>{body.comments}</span>
                                </div>
                                <div className="w-[132px] px-6 py-4">
                                  <span>{body.share}</span>
                                </div>
                                <div className="w-[132px] px-6 py-4">
                                  <span>{body.save}</span>
                                </div>
                                <div className="w-[212px] px-6 py-4">
                                  <span>{body.frequency}</span>
                                </div>
                              </div>
                            );
                          })
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <ModalCommon
        open={open}
        setOpen={setOpen}
        accountInformation={modalInformation}
        modalType="sns"
        initialTabKey="postInsight"
        snsType={SnsType.Instagram}
        postType={convertInstgramPostTaskType(selectedPostTask.task.taskType)}
        getInfluencerData={fetchInfluencerApplicantDetail}
      />
    </div>
  );
}
