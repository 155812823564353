import * as Sentry from '@sentry/react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { GetAccountOutput } from '@/api/member';
import { GetTeamsOutput, Team as apiTeamProps, InviteTeam } from '@/api/team';
import { ReactComponent as GroupIcon } from '@/assets/icons/bx-group.svg';
import { FeeadBack } from '@/components/Elements/FeedBack';
import { fetchAccountInfo } from '@/hooks/AccountInfo';
import { fetchTeamsInfo } from '@/hooks/TeamInfo';
// import { FetchTeamsInfoResponse } from '@/types/TeamInfo';
import { getImageUrl } from '@/utils/image';

import group from '../assets/group.png';
import { Avatar } from '../components/Avatar';
import { Team, teamProps } from '../components/Team';

export function TeamSelect() {
  const navigate = useNavigate();
  const [belongTeams, setBelongTeams] = useState<teamProps>();
  const [invitedTeams, setInvitedTeams] = useState<teamProps>();
  const [accountInfo, setAccountInfo] = useState<GetAccountOutput | null>(null);

  const [teamsInfo, setTeamsInfo] = useState<GetTeamsOutput | null>(null);

  useEffect(() => {
    const getTeamsInfo = async () => {
      try {
        const data: GetTeamsOutput = await fetchTeamsInfo();
        console.info('teamsInfodata:', data);
        setTeamsInfo(data);

        // データを使ってsetBelongTeamsとsetInvitedTeamsにデータをセット
        const belongTeamsData = data.joinings.map((teamInfoElement: apiTeamProps) => ({
          teamId: teamInfoElement.teamId || '',
          teamName: teamInfoElement.teamName || '',
          image: teamInfoElement.thumbnail || '',
          members: teamInfoElement.memberCount || 0,
          isBelong: true,
          invitation: '',
        }));
        const invitedTeamsData = data.invitations.map((teamInfoElement: InviteTeam) => ({
          teamId: teamInfoElement.teamId || '',
          teamName: teamInfoElement.teamName || '',
          image: teamInfoElement.thumbnail || '',
          members: teamInfoElement.memberCount || 0,
          isBelong: false,
          invitation: teamInfoElement.invitation || '',
        }));
        setBelongTeams({ teams: belongTeamsData, handleAddTeam: handleAddTeam });
        setInvitedTeams({ teams: invitedTeamsData, handleAddTeam: handleAddTeam });
        console.info('belongTeamsData:', belongTeamsData);
        console.info('invitedTeamsData:', invitedTeamsData);
      } catch (error) {
        Sentry.captureException(error);
        console.error('API call failed:', error);
      }
    };
    // コンポーネントがマウントされたときに一度だけ実行
    if (!teamsInfo) {
      getTeamsInfo();
    }
  }, [teamsInfo]);
  const handleAddTeam = (teamName: string) => {
    navigate('/home', { state: { shouldOpenModal: true, modalMessage: `「${teamName}」に参加しました。` } });
  };

  // アカウント情報取得APIリクエスト
  useEffect(() => {
    const executeAccountData = async () => {
      try {
        const data = await fetchAccountInfo();
        setAccountInfo(data);
        console.log('executeGetAccountInfo:', data);
      } catch (error) {
        Sentry.captureException(error);
        console.error('executeGetAccountInfo failed:', error);
      }
    };

    executeAccountData();
  }, []);

  const belongTeamsDataIsExists = belongTeams && belongTeams.teams.length > 0;
  const loginUserEmail = accountInfo?.email || '';
  const loginUserAvator = accountInfo?.thumbnail || '';

  return (
    <div className="w-[1440px] bg-gray-50">
      <div>
        <div className="flex h-10 items-center justify-center bg-[#111827]">
          <img src={getImageUrl('assets/logo/row_white_2.png')} alt="" width="113.19" height="34.37" />
        </div>
        <div className="flex justify-center !bg-gray-50 pb-24">
          <div className="flex flex-col px-2 py-4">
            <div className="flex flex-row items-center justify-center">
              {belongTeamsDataIsExists == true ? (
                <div className="flex flex-row items-center justify-center">
                  <div>
                    <Avatar userName={loginUserEmail} avator={loginUserAvator} />
                  </div>
                  <div className="flex h-[47px] flex-row items-center pl-[13px]">
                    <span className="text-xl font-bold leading-7">今日もお疲れ様です。</span>
                  </div>
                </div>
              ) : (
                <div>
                  <Avatar userName={loginUserEmail} avator={loginUserAvator} />
                  <div className="mt-[15px] flex items-center justify-center">
                    <div className="flex flex-row items-center text-3xl font-bold">INFLUFECTへようこそ！</div>
                  </div>
                </div>
              )}
            </div>
            <div className="flex flex-col gap-16">
              {/* {belongTeamsDataIsExists == false && ( */}
              <div className="pt-16">
                <div>
                  <div>
                    <div className="flex h-[106px] w-full flex-row items-center bg-white">
                      <div className="flex h-[106px] w-[159px] items-center justify-center gap-4 rounded-l-lg border-y border-l border-[#E8E8E8] bg-[#005686E5]">
                        <img src={group} alt="" width={'107.54'} />
                      </div>
                      <div className="flex h-full w-[1057px] flex-row items-center justify-between gap-4 rounded-r-lg border-y border-r border-[#E8E8E8] bg-white pl-6">
                        <div className="flex flex-col gap-1">
                          <div>
                            <span className="font-bold text-[#262626]">チームを新規作成</span>
                          </div>
                          <div>
                            <span className="font-normal text-[#262626]">
                              マーケティング領域におけるコラボレーションを可能にします。
                            </span>
                          </div>
                        </div>
                        <div className="gap-[10px]">
                          <button
                            type="button"
                            className="mr-[70px] rounded-md bg-[#007CC2] px-3 py-2 text-base font-medium leading-6 text-white shadow-sm ring-1 ring-inset"
                            onClick={() => navigate('/create_team')}
                          >
                            新しくチームを作成する
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-16">
                <div>
                  <div>
                    <span className="text-2xl font-bold text-[#262626]">所属しているチーム</span>
                  </div>
                  {belongTeamsDataIsExists == true ? (
                    <div>{belongTeams && Team(belongTeams, navigate)}</div>
                  ) : (
                    <FeeadBack
                      icon={<GroupIcon fill="#9CA3AF"></GroupIcon>}
                      actionText="所属しているチームはありません"
                    ></FeeadBack>
                  )}
                </div>
                <div className="pt-10">
                  <div>
                    <span className="text-2xl font-bold text-[#262626]">招待されているチーム</span>
                  </div>
                  <div>{invitedTeams && Team(invitedTeams, navigate)}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
