import * as Sentry from '@sentry/react';
import { AxiosResponse } from 'axios';

import {
  AffectOutput,
  DefaultApi,
  PostProjectsInput,
  GetProjectGroupsRowsEnum,
  GetProjectGroupsOrderEnum,
  GetProjectGroupsOutput,
  GetProjectOverviewOutput,
  CalendarItem,
  ProjectAttachmentsResult,
  PostProjectAttachmentsInput,
} from '@/api/project';
import { axiosInstance } from '@/utils/axiosInstance';
const api = new DefaultApi(undefined, undefined, axiosInstance);

/**
 * 案件作成API
 * @param request
 * @returns
 */
export const createProjects = async (request: PostProjectsInput): Promise<AffectOutput> => {
  try {
    const response: AxiosResponse<AffectOutput> = await api.postProjects(request);
    return response.data as AffectOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * プロジェクトグループ一覧取得API
 * @param query プロジェクトグループ一覧取得APIのクエリストリング情報
 * @returns
 */
export const fetchProjectGroups = async (
  rows: GetProjectGroupsRowsEnum,
  page: number,
  order: GetProjectGroupsOrderEnum,
  orderby?: string
): Promise<GetProjectGroupsOutput> => {
  try {
    const response: AxiosResponse<GetProjectGroupsOutput> = await api.getProjectGroups(rows, page, order, orderby);
    return response.data as GetProjectGroupsOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * 案件概要取得API
 * @param request
 * @returns
 */
export const getProjectOverviews = async (projectId: string): Promise<GetProjectOverviewOutput> => {
  try {
    const response: AxiosResponse<GetProjectOverviewOutput> = await api.getProjectOverview(projectId);
    return response.data as GetProjectOverviewOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

// カレンダー取得
export const fetchProjectCalenderInfo = async (year: number, month: number): Promise<CalendarItem[]> => {
  try {
    const response: AxiosResponse<CalendarItem[]> = await api.getProjectCalendar(year, month);
    return response.data as CalendarItem[];
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

// 案件採用者確定API
export const patchProjectAdopterDecision = async (projectId: string): Promise<AffectOutput> => {
  try {
    const response: AxiosResponse<AffectOutput> = await api.patchProjectAdopterDecision(projectId);
    return response.data;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * 案件添付ファイル一覧取得API
 * @param request
 * @returns
 */
export const getAttachmentFilesData = async (projectId: string): Promise<ProjectAttachmentsResult> => {
  try {
    const response: AxiosResponse<ProjectAttachmentsResult> = await api.getProjectAttachments(projectId);
    return response.data as ProjectAttachmentsResult;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * 案件添付ファイル削除API
 * @param request
 * @returns
 */
export const deleteAttachmentFilesData = async (
  projectId: string,
  attachmentId: string
): Promise<ProjectAttachmentsResult> => {
  try {
    const response: AxiosResponse<ProjectAttachmentsResult> = await api.deleteProjectAttachments(
      projectId,
      attachmentId
    );
    return response.data as ProjectAttachmentsResult;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * 案件添付ファイルを登録するAPI
 * @param request
 * @returns
 */
export const postAttachmentFilesData = async (
  projectId: string,
  postProjectAttachmentsInput?: PostProjectAttachmentsInput
): Promise<ProjectAttachmentsResult> => {
  try {
    const response: AxiosResponse<ProjectAttachmentsResult> = await api.postProjectAttachments(
      projectId,
      postProjectAttachmentsInput
    );
    return response.data as ProjectAttachmentsResult;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};
