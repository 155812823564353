import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { useEffect, useState } from 'react';

import { AnalysisSpiritFeedbackAdopterRow, GetAnalysisSpiritFeedbackAdoptersRowsEnum } from '@/api/analysisSpirit/api';
import {
  GetInfluencerSnsAccountOutput,
  ProjectType,
  PutInfluencerEvaluationInputEvaluationEnum,
} from '@/api/influencer';
import { GetOtherDetailOutput } from '@/api/other';
import { GetSpiritDetailOutput, FeedbackStatus, ProjectStatus } from '@/api/spirit';
import NoImage from '@/assets/images/NoImage.png';
import { Button } from '@/components/Elements/Button';
import { ButtonTab } from '@/components/Elements/ButtonTab';
import { fetchInfluencerApplicantDetail, patchInfluencerBonus, putInfluencerEvaluation } from '@/hooks/Influencer';
import { GetJaneJohnDetail } from '@/hooks/JaneJohn';
import { getOtherDetail } from '@/hooks/Other';
import { feedbackConfirm } from '@/hooks/project';
import { getSpiritDetail } from '@/hooks/SPIRIT';

import { followersInit } from './AccountInfo';
import { CompleteFeedbackModal } from './CompleteFeedbackModal';
import { AnalysisFeedbackAdopterRow, FeedbackProps, GetAnalysisFeedbackAdoptersOutput } from './feedback';
import {
  TableHeaderIncompleated,
  TableHeaderCompleated,
  TableBodyIncompleate,
  TableBodyCompleated,
} from './feedbackTable';
import { PreFeedbackModal } from './PreFeedbackModal';
import QuestionnaireResults from './questionnaireResults';

export interface SelectFeedback {
  adoptedInfluencerId: string;
  snsAccountId?: string;
  evaluation?: number;
  isGoodQualityCandidate?: boolean;
  feedbackComment?: string;
}

export interface AdopterSlider {
  canNext: boolean;
  canPrev: boolean;
  nextAdopter: (adoptedInfluencerId: string) => void;
  prevAdopter: (adoptedInfluencerId: string) => void;
}

const numToAdoptersRows = (num: number): GetAnalysisSpiritFeedbackAdoptersRowsEnum => {
  switch (num) {
    case 10:
      return GetAnalysisSpiritFeedbackAdoptersRowsEnum.NUMBER_10;
    case 30:
      return GetAnalysisSpiritFeedbackAdoptersRowsEnum.NUMBER_30;
    case 50:
      return GetAnalysisSpiritFeedbackAdoptersRowsEnum.NUMBER_50;
    case 100:
      return GetAnalysisSpiritFeedbackAdoptersRowsEnum.NUMBER_100;
    default:
      return GetAnalysisSpiritFeedbackAdoptersRowsEnum.NUMBER_30;
  }
};

const initFeedback: AnalysisSpiritFeedbackAdopterRow = {
  feedbackId: '',
  adoptedInfluencerId: '',
  applyingInfluencerId: '',
  snsAccountId: '',
  snsAccountNo: '',
  snsAccountThumbnail: NoImage,
  influencerId: '',
  evaluationId: '',
  evaluation: 4,
  feedbackComment: '',
  isGoodQualityCandidate: false,
  GoodQualityReason: '',
};

export default function FeedbackCommon(props: FeedbackProps) {
  const [selectTab, setSelectTab] = useState<string>('list');
  const [isAsc, setIsAsc] = useState<boolean | undefined>(undefined);
  const [open, setOpen] = useState<boolean>(false);
  const [applyingInfluencer, setApplyingInfluencer] = useState<GetInfluencerSnsAccountOutput>();
  const [feedbackRows, setFeedbackRows] = useState<GetAnalysisSpiritFeedbackAdoptersRowsEnum>(30);
  const [feedbackPage, setFeedbackPage] = useState<number>(1);
  const [feedbackAdopters, setFeedbackAdopters] = useState<
    GetAnalysisFeedbackAdoptersOutput & {
      goodQualityCount: { candidateCount: number; possibleCount: number };
    }
  >({
    from: 0,
    to: 0,
    perPage: 0,
    totalRows: 0,
    totalPages: 0,
    rows: [] as AnalysisFeedbackAdopterRow[],
    goodQualityCount: {
      candidateCount: 0,
      possibleCount: 0,
    },
  });
  const [modalInfluencer, setModalInfluencer] = useState<AnalysisSpiritFeedbackAdopterRow>(initFeedback);
  // インフルエンサーからのフィードバックが完了しているかを判定するフラグ
  const [influencerFeedbackCompleted, setInfluencerFeedbackCompleted] = useState<boolean>(false);
  const pages = Array.from({ length: feedbackAdopters.totalPages }, (_, i) => i + 1);

  const updateAdopters = async (rows: GetAnalysisSpiritFeedbackAdoptersRowsEnum, page: number) => {
    const result = await props.getAnalysisFeedbackAdopters(rows, page);
    setFeedbackAdopters(
      'goodQualityCount' in result ? result : { ...result, goodQualityCount: { candidateCount: 0, possibleCount: 0 } }
    );
  };

  // インフルエンサー応募者取得
  const setInfluencerSnsAccount = async (applyingInfluencerId: string) => {
    const result = await fetchInfluencerApplicantDetail(applyingInfluencerId);
    setApplyingInfluencer(result);
  };

  // 採用者フィードバック情報設定
  const setModalOpen = async (adoptedInfluencerId: string) => {
    if (!feedbackAdopters.rows.length) return;
    const selectAdopter = feedbackAdopters.rows.find((row) => row.adoptedInfluencerId === adoptedInfluencerId);
    if (!selectAdopter) return;
    setModalInfluencer(selectAdopter);
    await setInfluencerSnsAccount(selectAdopter.applyingInfluencerId);
    setOpen(true);
  };

  const nextAdopter = async (adoptedInfluencerId: string) => {
    const index = feedbackAdopters.rows.findIndex((row) => row.adoptedInfluencerId === adoptedInfluencerId);
    if (index === feedbackAdopters.rows.length - 1) return;
    setModalInfluencer(feedbackAdopters.rows[index + 1]);
    await setInfluencerSnsAccount(feedbackAdopters.rows[index + 1].applyingInfluencerId);
  };

  const prevAdopter = async (adoptedInfluencerId: string) => {
    const index = feedbackAdopters.rows.findIndex((row) => row.adoptedInfluencerId === adoptedInfluencerId);
    if (index === 0) return;
    setModalInfluencer(feedbackAdopters.rows[index - 1]);
    await setInfluencerSnsAccount(feedbackAdopters.rows[index - 1].applyingInfluencerId);
  };

  const selectFeedback = async (feedback: SelectFeedback) => {
    if (feedback.evaluation !== undefined) {
      await putInfluencerEvaluation(feedback.adoptedInfluencerId, {
        evaluation: feedback.evaluation as PutInfluencerEvaluationInputEvaluationEnum,
      });
    }
    if (feedback.snsAccountId !== undefined) {
      await patchInfluencerBonus(feedback.snsAccountId, {
        adoptedInfluencerId: feedback.adoptedInfluencerId,
        isGoodQuality: feedback.isGoodQualityCandidate,
        reason: feedback.feedbackComment,
      });
    }
    updateAdopters(feedbackRows, feedbackPage);
    if (open) setOpen(false);
  };

  useEffect(() => {
    updateAdopters(feedbackRows, feedbackPage);
  }, [feedbackRows, feedbackPage]);

  useEffect(() => {
    (async () => {
      let projectDetailOutput: GetSpiritDetailOutput | GetOtherDetailOutput | null = null;
      if (props.projectType === ProjectType.Spirit) {
        projectDetailOutput = await getSpiritDetail(props.projectId);
      } else if (props.projectType === ProjectType.JaneJohn) {
        projectDetailOutput = await GetJaneJohnDetail(props.projectId);
      } else if (props.projectType === ProjectType.Other) {
        projectDetailOutput = await getOtherDetail(props.projectId);
      }

      if (!projectDetailOutput) return;
      // フィードバックが完了しているかの判定を行う
      if (
        projectDetailOutput.header.feedbackStatus === FeedbackStatus.Client ||
        projectDetailOutput.header.feedbackStatus === FeedbackStatus.Admin
      ) {
        props.setIsCompleteFeedback(true);
        // else句でフラグを更新しないと他プロジェクトにも影響が出るため適宜更新を行う
      } else {
        props.setIsCompleteFeedback(false);
      }

      // インフルエンサーからのフィードバックが完了しているかの判定を行う。完了している場合のみフィードバックを確定できる
      if (projectDetailOutput.header.projectStatus === ProjectStatus.Completion) {
        setInfluencerFeedbackCompleted(true);
        // else句でフラグを更新しないと他プロジェクトにも影響が出るため適宜更新を行う
      } else {
        setInfluencerFeedbackCompleted(false);
      }
    })();
  }, []);

  const [confirmButtonDisabledFlag, setConfirmButtonDisabledFlag] = useState<boolean>(false);
  useEffect(() => {
    if (!influencerFeedbackCompleted) {
      setConfirmButtonDisabledFlag(true);
      return;
    }
    if (
      'goodQualityCount' in feedbackAdopters &&
      feedbackAdopters.goodQualityCount.candidateCount > feedbackAdopters.goodQualityCount.possibleCount
    ) {
      setConfirmButtonDisabledFlag(true);
      return;
    }

    setConfirmButtonDisabledFlag(false);
  }, [feedbackAdopters, influencerFeedbackCompleted]);

  const handlePageFirst = () => {
    if (feedbackPage == 1) return;
    setFeedbackPage(1);
  };

  const handlePageDown = () => {
    if (feedbackPage === 1) return;
    setFeedbackPage((prev) => prev - 1);
  };

  const handlePageUp = () => {
    if (feedbackPage === feedbackAdopters.totalPages) return;
    setFeedbackPage((prev) => prev - 1);
  };

  const handlePageLast = () => {
    if (feedbackPage === feedbackAdopters.totalPages) return;
    setFeedbackPage(feedbackAdopters.totalPages);
  };

  const handlePageParameter = (page: number) => {
    if (feedbackPage === page) return;
    setFeedbackPage(page);
  };

  const handleCompleFeedback = () => {
    feedbackConfirm(props.projectId);
    props.setIsCompleteFeedback(true);
  };

  const chg_row = () => {
    if (props.projectType == ProjectType.JaneJohn) {
      return 'flex flex-col items-start gap-2 w-[800px]';
    } else {
      const mozi_css = props.isCompleteFeedback
        ? ' w-[800px]'
        : 'goodQualityCount' in feedbackAdopters && feedbackAdopters.goodQualityCount.possibleCount
        ? ' w-[876px]'
        : ' w-[557px]';
      return 'flex flex-col items-start gap-2' + mozi_css;
    }
  };

  return (
    <div className="flex flex-col items-start gap-6 self-stretch p-6">
      <div className="flex flex-col items-start gap-10">
        <div className="flex flex-col items-start gap-5">
          <div id="view-switch" className="w-[403px] p-[2px]">
            <ButtonTab
              value={selectTab}
              buttons={[
                {
                  label: 'インフルエンサーリスト',
                  value: 'list',
                  className:
                    'w-[228px] h-[38px] rounded-md text-sm leading-5 font-medium py-[9px] flex items-center justify-center',
                },
                {
                  label: 'アンケート結果',
                  value: 'result',
                  className:
                    'w-[167px] h-[38px] rounded-md text-sm leading-5 font-medium py-[9px] flex items-center justify-center',
                },
              ]}
              className="flex h-[40px] w-[403px] items-center justify-center gap-1 rounded-md bg-gray-100 p-[2px]"
              onChange={(value: any) => {
                if (props.isCompleteFeedback) {
                  setSelectTab(value);
                }
              }}
            />
          </div>
          <div className="flex flex-1 flex-col items-start gap-6">
            <div className="flex items-start gap-10 self-stretch">
              {selectTab !== 'result' && (
                <div className="flex flex-1 flex-col items-start gap-2">
                  <span className="text-sm font-semibold leading-5 text-gray-700">
                    {props.isCompleteFeedback
                      ? 'インフルエンサーからのフィードバック'
                      : 'インフルエンサーからの評価を見るには、インフルエンサーへのフィードバックをしてください'}
                  </span>
                  <div className="w-[572px]">
                    <span className="whitespace-pre-wrap text-sm font-medium leading-5 text-gray-500">
                      {props.projectType === ProjectType.JaneJohn || ProjectType.Other ? (
                        <div>
                          {props.isCompleteFeedback
                            ? 'インフルエンサーによる案件のフィードバックです'
                            : 'インフルエンサーへの評価はデフォルトで5段階評価中4で設定されています。'}
                        </div>
                      ) : (
                        <div>
                          {props.isCompleteFeedback
                            ? 'インフルエンサーによる案件のフィードバックです'
                            : 'goodQualityCount' in feedbackAdopters &&
                              feedbackAdopters.goodQualityCount.possibleCount != 0
                            ? `インフルエンサーへの評価はデフォルトで5段階評価中4で設定されています。\nGood Qualityボーナスを${feedbackAdopters.goodQualityCount.possibleCount}人まで付与することができます。付与されたインフルエンサーはプラスで報酬を得られます。`
                            : 'インフルエンサーへの評価はデフォルトで5段階評価中4で設定されています。'}
                        </div>
                      )}
                    </span>
                  </div>
                </div>
              )}
              {!props.isCompleteFeedback && (
                <div id="feedback-confirm" className="flex w-[261px] flex-col items-end gap-2">
                  <Button
                    size="smNopadding"
                    className="h-[36px] w-[210px] px-2 py-[9px]"
                    disabled={confirmButtonDisabledFlag}
                    onClick={handleCompleFeedback}
                  >
                    フィードバックを確定する
                  </Button>
                  {'goodQualityCount' in feedbackAdopters &&
                    feedbackAdopters.goodQualityCount.possibleCount != 0 &&
                    props.projectType === ProjectType.Spirit && (
                      <div className="flex flex-col items-start justify-center gap-[10px]">
                        <div className="flex items-center justify-end gap-8">
                          <div className="flex items-start gap-4">
                            <div className="flex items-center gap-2">
                              <div className="flex items-start gap-1">
                                <span className="text-sm font-normal leading-5 text-gray-500">
                                  Good Qualityボーナス付与可能数
                                </span>
                              </div>
                              <div className="flex items-end gap-1">
                                <span className="text-base font-bold leading-6 text-gray-500">
                                  {feedbackAdopters.goodQualityCount.candidateCount}/
                                  {feedbackAdopters.goodQualityCount.possibleCount}
                                </span>
                                <span className="text-xs font-semibold uppercase leading-4 tracking-wider text-gray-500">
                                  人
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              )}
            </div>
          </div>
          {selectTab === 'result' ? (
            <QuestionnaireResults projectId={props.projectId} />
          ) : (
            <div id="influencer-table" className="flex items-start">
              <div className={chg_row()}>
                <div className="max-h-[282px] overflow-auto rounded-lg border border-[#E5E7EB]">
                  <table
                    className={clsx(
                      'overflow-x-hidden overflow-y-scroll rounded-lg',
                      'goodQualityCount' in feedbackAdopters &&
                        feedbackAdopters.goodQualityCount.possibleCount &&
                        props.projectType === ProjectType.Spirit
                        ? 'w-[876px]'
                        : 'w-[557px]'
                    )}
                  >
                    {props.isCompleteFeedback
                      ? TableHeaderCompleated(setIsAsc, props.projectType, isAsc)
                      : props.projectType === ProjectType.Spirit
                      ? 'goodQualityCount' in feedbackAdopters &&
                        TableHeaderIncompleated(feedbackAdopters.goodQualityCount.possibleCount !== 0)
                      : TableHeaderIncompleated(false)}
                    {props.isCompleteFeedback
                      ? TableBodyCompleated({
                          influencers: feedbackAdopters.rows,
                          setModalOpen: setModalOpen,
                          isAsc,
                        })
                      : props.projectType === ProjectType.Spirit
                      ? TableBodyIncompleate({
                          influencers: feedbackAdopters.rows,
                          setModalOpen: setModalOpen,
                          isGoodQuantity:
                            'goodQualityCount' in feedbackAdopters &&
                            feedbackAdopters.goodQualityCount.possibleCount !== 0,
                          selectFeedback: selectFeedback,
                        })
                      : TableBodyIncompleate({
                          influencers: feedbackAdopters.rows,
                          setModalOpen: setModalOpen,
                          isGoodQuantity: false,
                          selectFeedback: selectFeedback,
                        })}
                  </table>
                </div>
                <div
                  className={clsx(
                    'flex items-center justify-between bg-white px-4 py-3 sm:px-6',
                    props.isCompleteFeedback ? 'w-[800px]' : 'w-[876px]'
                  )}
                >
                  <div className="flex flex-1 justify-between sm:hidden">
                    <a
                      href="#"
                      className="relative inline-flex items-center rounded-md  bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                    >
                      Previous
                    </a>
                    <a
                      href="#"
                      className="relative ml-3 inline-flex items-center rounded-md bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                    >
                      Next
                    </a>
                  </div>
                  <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                    <div className="flex flex-row items-center gap-2">
                      <span className="text-xs font-normal leading-4 text-gray-500">１ページあたりの行数:</span>
                      <div className="text-xs font-normal leading-4 sm:col-span-3">
                        <select
                          id="perPage"
                          name="perPage"
                          className="border-none text-xs font-normal leading-4 text-gray-500"
                          defaultValue={feedbackRows}
                          onChange={(e) => {
                            const rows = numToAdoptersRows(Number(e.target.value));
                            if (rows === feedbackRows) return;
                            setFeedbackRows(rows);
                          }}
                        >
                          <option>10</option>
                          <option>30</option>
                          <option>50</option>
                          <option>100</option>
                        </select>
                      </div>
                      <span className="text-sm font-normal leading-5 text-gray-500">
                        {(feedbackPage - 1) * feedbackRows + 1} - {(feedbackPage - 1) * feedbackRows + feedbackRows} of{' '}
                        {feedbackAdopters.totalRows}
                      </span>
                    </div>
                    {feedbackAdopters.totalPages > 30 && (
                      <div>
                        <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                          <button
                            onClick={handlePageFirst}
                            className={clsx(
                              'relative inline-flex items-center rounded-l-md p-2 text-gray-400 ring-1 ring-inset ring-gray-300',
                              feedbackPage == 1 ? '' : 'hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                            )}
                          >
                            <ChevronDoubleLeftIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                              fill={feedbackPage == 1 ? '#D1D5DB' : '#6B7280'}
                            />
                          </button>
                          <button
                            onClick={handlePageDown}
                            className={clsx(
                              'relative inline-flex items-center p-2 text-gray-400 ring-1 ring-inset ring-gray-300 ',
                              feedbackPage == 1 ? '' : 'hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                            )}
                          >
                            <ChevronLeftIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                              fill={feedbackPage == 1 ? '#D1D5DB' : '#6B7280'}
                            />
                          </button>
                          {pages.map((page) => {
                            if (page == feedbackPage) {
                              return (
                                <button
                                  key={page}
                                  className="relative z-10 inline-flex items-center border border-[#007CC2] bg-[#E0F4FF] px-4 py-2"
                                >
                                  <span className="text-sm font-medium leading-5 text-gray-500">{page}</span>
                                </button>
                              );
                            } else {
                              return (
                                <button
                                  key={page}
                                  className="relative inline-flex items-center px-4 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                  onClick={() => {
                                    handlePageParameter(page);
                                  }}
                                >
                                  <span className="text-sm font-medium leading-5 text-gray-500">{page}</span>
                                </button>
                              );
                            }
                          })}
                          <button
                            onClick={handlePageUp}
                            // className="relative inline-flex items-center p-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                            className={clsx(
                              'relative inline-flex items-center p-2 text-gray-400 ring-1 ring-inset ring-gray-300 ',
                              feedbackPage == feedbackAdopters.totalPages
                                ? ''
                                : 'hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                            )}
                          >
                            <ChevronRightIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                              fill={feedbackPage == feedbackAdopters.totalPages ? '#D1D5DB' : '#6B7280'}
                            />
                          </button>
                          <button
                            onClick={handlePageLast}
                            // className="relative inline-flex items-center rounded-r-md p-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                            className={clsx(
                              'relative inline-flex items-center rounded-r-md p-2 text-gray-400 ring-1 ring-inset ring-gray-300 ',
                              feedbackPage == feedbackAdopters.totalPages
                                ? ''
                                : 'hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                            )}
                          >
                            <ChevronDoubleRightIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                              fill={feedbackPage == feedbackAdopters.totalPages ? '#D1D5DB' : '#6B7280'}
                            />
                          </button>
                        </nav>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {props.isCompleteFeedback ? (
          <CompleteFeedbackModal
            open={open}
            setOpen={setOpen}
            feedbackId={modalInfluencer.feedbackId}
            adopterInfluencerId={modalInfluencer.adoptedInfluencerId}
            evaluation={modalInfluencer.evaluation}
            possibleGoodQualityCount={
              'goodQualityCount' in feedbackAdopters ? feedbackAdopters.goodQualityCount.possibleCount : 0
            }
            isGoodQualityCanadiate={modalInfluencer.isGoodQualityCandidate ?? false}
            goodQualityReason={modalInfluencer.GoodQualityReason ?? ''}
            accountInfo={{
              projectType: props.projectType as ProjectType,
              thumbnail: applyingInfluencer?.thumbnailUrl ?? NoImage,
              evaluation: (applyingInfluencer?.evaluation.evaluation ?? 0) as number,
              evaluationCount: applyingInfluencer?.evaluation.evaluationCounts ?? 0,
              snsType: applyingInfluencer?.snsType ?? 'other',
              followers: applyingInfluencer?.followerAttributes.followers ?? followersInit,
              isReserved: applyingInfluencer?.applyingComment.reserved ?? false,
              snsAccountNumber: applyingInfluencer?.snsAccountNo ?? '',
            }}
            adopterSlider={{
              canNext:
                feedbackAdopters.rows.findIndex(
                  (row) => row.adoptedInfluencerId === modalInfluencer.adoptedInfluencerId
                ) !==
                feedbackAdopters.rows.length - 1,
              canPrev:
                feedbackAdopters.rows.findIndex(
                  (row) => row.adoptedInfluencerId === modalInfluencer.adoptedInfluencerId
                ) !== 0,
              nextAdopter: nextAdopter,
              prevAdopter: prevAdopter,
            }}
          />
        ) : (
          <PreFeedbackModal
            open={open}
            setOpen={setOpen}
            projectType={props.projectType}
            goodQualityCount={
              'goodQualityCount' in feedbackAdopters
                ? feedbackAdopters.goodQualityCount
                : { possibleCount: 0, candidateCount: 0 }
            }
            snsAccount={applyingInfluencer}
            adopter={modalInfluencer}
            selectFeedback={selectFeedback}
            setAdopter={setModalInfluencer}
            adopterSlider={{
              canNext:
                feedbackAdopters?.rows?.findIndex(
                  (row) => row.adoptedInfluencerId === modalInfluencer.adoptedInfluencerId
                ) !==
                feedbackAdopters?.rows?.length - 1,
              canPrev:
                feedbackAdopters?.rows?.findIndex(
                  (row) => row.adoptedInfluencerId === modalInfluencer.adoptedInfluencerId
                ) !== 0,
              nextAdopter: nextAdopter,
              prevAdopter: prevAdopter,
            }}
            accountInfo={{
              projectType: props.projectType as ProjectType,
              thumbnail: applyingInfluencer?.thumbnailUrl ?? NoImage,
              evaluation: (applyingInfluencer?.evaluation.evaluation ?? 0) as number,
              evaluationCount: applyingInfluencer?.evaluation.evaluationCounts ?? 0,
              snsType: applyingInfluencer?.snsType ?? 'other',
              followers: applyingInfluencer?.followerAttributes.followers ?? followersInit,
              isReserved: applyingInfluencer?.applyingComment.reserved ?? false,
              snsAccountNumber: applyingInfluencer?.snsAccountNo ?? '',
            }}
          />
        )}
      </div>
    </div>
  );
}
