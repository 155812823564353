import * as Sentry from '@sentry/react';
import { AxiosResponse } from 'axios';

import {
  AffectOutput,
  CreationAccountRow,
  DefaultApi,
  GetCreationAccountMeasuresOrderEnum,
  GetCreationAccountMeasuresOutput,
  GetCreationAccountMeasuresRowsEnum,
  PostCreationAccountInput,
  PostCreationInput,
  PostMeasureInput,
} from '@/api/creation/api';
import { axiosInstance } from '@/utils/axiosInstance';

/**
 * 制作管理アカウント一覧を取得するAPIです。 <br>制作管理アカウント一覧を返却します。
 * @summary 【Mock】制作管理アカウント一覧取得API
 */
export const getCreationAccounts = async () => {
  let response: AxiosResponse<CreationAccountRow[]> = {} as AxiosResponse<CreationAccountRow[]>;
  try {
    const api = new DefaultApi(undefined, undefined, axiosInstance);
    response = await api.getCreationAccounts();
    return response.data as CreationAccountRow[];
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * 制作管理アカウントを登録するAPIです。 <br>制作管理アカウントを登録します。
 * @summary 【Mock】制作管理アカウント登録API
 */
export const postCreationAccount = async (postCreationAccountInput?: PostCreationAccountInput) => {
  let response: AxiosResponse<AffectOutput> = {} as AxiosResponse<AffectOutput>;
  try {
    const api = new DefaultApi(undefined, undefined, axiosInstance);
    response = await api.postCreationAccount(postCreationAccountInput);
    return response.data as AffectOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * 制作管理施策一覧を取得するAPIです。 <br>制作管理施策一覧を返却します。
 * @summary 【Mock】制作管理施策一覧取得API
 */
export const getCreationAccountMeasures = async (
  accountId: string,
  rows?: GetCreationAccountMeasuresRowsEnum,
  page?: number,
  order?: GetCreationAccountMeasuresOrderEnum,
  orderBy?: string
) => {
  let response: AxiosResponse<GetCreationAccountMeasuresOutput> = {} as AxiosResponse<GetCreationAccountMeasuresOutput>;
  try {
    const api = new DefaultApi(undefined, undefined, axiosInstance);
    response = await api.getCreationAccountMeasures(accountId, rows, page, order, orderBy);
    return response.data as GetCreationAccountMeasuresOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * 制作管理施策を登録するAPIです。 <br>制作管理施策を登録します。
 * @summary 【Mock】制作管理施策登録API
 * @param {PostMeasureInput} [postMeasureInput]
 */
export const postMeasure = async (postMeasureInput?: PostMeasureInput) => {
  let response: AxiosResponse<AffectOutput> = {} as AxiosResponse<AffectOutput>;
  try {
    const api = new DefaultApi(undefined, undefined, axiosInstance);
    response = await api.postMeasure(postMeasureInput);
    return response.data as AffectOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * 投稿制作を作成するAPIです。 <br>投稿制作を作成します。
 * @summary 【Mock】投稿制作作成API
 */
export const postCreation = async (measureId: string, postCreationInput?: PostCreationInput) => {
  let response: AxiosResponse<AffectOutput> = {} as AxiosResponse<AffectOutput>;
  try {
    const api = new DefaultApi(undefined, undefined, axiosInstance);
    response = await api.postCreation(measureId, postCreationInput);
    return response.data as AffectOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};
