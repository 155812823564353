import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import * as Sentry from '@sentry/react';
import { Fragment } from 'react';
import { useForm } from 'react-hook-form';

import { ProjectAttachment, ProjectAttachmentsResult } from '@/api/project';
import { postAttachmentFilesData } from '@/hooks/ProjectInfo';

interface Props {
  open: boolean;
  onClose: (isSave: boolean) => void;
  files?: ProjectAttachmentsResult | undefined;
  setAttachmentFileData: React.Dispatch<React.SetStateAction<ProjectAttachmentsResult | undefined>>;
  projectId: string | undefined;
}
interface AttachmentFormData {
  name: string;
  url: string;
}

export const AttachmentFiles = (props: Props) => {
  const AttachmentFileForm = () => {
    const {
      handleSubmit,
      register,
      formState: { errors },
    } = useForm<AttachmentFormData>();

    const onSubmit = async (data: AttachmentFormData) => {
      if (!props.files?.attachments) return;

      if (!props.projectId) return;
      const addAttachmentFileData = await postAttachmentFilesData(props.projectId, data);

      props.setAttachmentFileData((prev: ProjectAttachmentsResult | undefined) => {
        if (!prev || !prev.attachments) {
          return { attachments: [addAttachmentFileData as ProjectAttachment] };
        }

        if (!addAttachmentFileData.attachments) return;
        return {
          ...prev,
          attachments: [...prev.attachments, addAttachmentFileData.attachments[0] as unknown as ProjectAttachment],
        };
      });

      props.onClose(true);
    };

    return (
      <>
        <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
          <button
            type="button"
            className="rounded-md bg-white text-gray-400 hover:text-gray-500"
            onClick={() => {
              props.onClose(false);
            }}
          >
            <span className="sr-only">Close</span>
            <XMarkIcon className="h-[24px] w-[24px]" aria-hidden="true" />
          </button>
        </div>
        <div className="text-center sm:text-left">
          <Dialog.Title as="h2" className="h-[24px] w-full pr-[40px] text-lg font-bold leading-6 text-gray-900">
            ファイル名およびファイル格納先URL登録
          </Dialog.Title>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="col-span-full pb-[24px]">
              <div className="mt-[20px]">
                <p className="text-sm font-semibold leading-5">ファイル名</p>
                <input
                  className="ph-left mt-[4px] w-full rounded-md border-[1px] border-gray-300 py-[9px] pl-[13px] text-[14px] font-normal leading-5 text-gray-900 placeholder:text-left"
                  {...register('name', {
                    maxLength: 50,
                  })}
                />
                {errors.name && <p className="pt-2 text-base font-bold text-red-400">50文字以内で入力してください。</p>}
              </div>
              <div className="mt-[28px]">
                <p className="text-sm font-semibold leading-5 ">ファイル格納先URLを入力する</p>
                <input
                  inputMode="url"
                  className="ph-left mt-[4px] w-full rounded-md border-[1px] border-gray-300 py-[9px] pl-[13px] text-[14px] font-normal leading-5 text-gray-900 placeholder:text-left"
                  {...register('url', {
                    validate: (value) => {
                      if (value == '') return 'URLの入力は必須です';
                      try {
                        new URL(value);
                      } catch (e) {
                        Sentry.captureException(e);
                        return 'URLの形式が正しくありません。';
                      }
                    },
                  })}
                />
                {errors.url && <p className="pt-2 text-base font-bold text-red-400">{errors.url.message}</p>}
              </div>
            </div>

            <div className="sm:mt-6 sm:flex sm:flex-row-reverse">
              <button
                type="submit"
                className="inline-flex w-full justify-center rounded-md px-[17px] py-[9px] text-sm font-medium leading-5 text-white shadow-sm sm:ml-3 sm:w-auto"
                style={{ background: '#007CC2' }}
              >
                登録
              </button>

              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-[17px] py-[9px] text-sm font-medium leading-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                onClick={() => {
                  props.onClose(false);
                }}
              >
                キャンセル
              </button>
            </div>
          </form>
        </div>
      </>
    );
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => props.onClose(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 opacity-30 transition-opacity" style={{ background: 'rgba(107, 114, 128)' }} />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-[496px] overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-3xl sm:p-6">
                <AttachmentFileForm />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
