import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import { JaneJohn } from '@/api/janeJohn';
import { Other } from '@/api/other';
import { ProjectType } from '@/api/project';
import { GetSpiritListRowsEnum, Spirit } from '@/api/spirit';
import { ReactComponent as RightIcon } from '@/assets/icons/bx-chevron-right.svg';
import { Pagenation } from '@/components/Elements/Pagenation/Pagenation';
import TableScrollToTop from '@/components/Scroll/TableScrollToTop';
import { scheduleToString } from '@/utils/format';
import { snsIcon } from '@/utils/sns';

import { ProjectInformation } from '../interfaces/analysisInterface';

import EmeraldSummary from './summary/EmeraldSummary';
import JanejoneSummary from './summary/JanejoneSummary';
import OtherSummary from './summary/OtherSummary';
import SpiritSummary from './summary/SpiritSummary';

interface analysisTableProps {
  projectType: ProjectType; // JaneJohn等のIF分岐残すため
  projects?: ProjectInformation;
  updateProjectList: (perPage: 30 | 50 | 100 | 10, currentPage: number) => void;
}
interface tableHeaderProps {
  title: string;
  width: string;
}

export default function AnalysisTable(props: analysisTableProps): React.ReactElement {
  const projectList = props.projects?.list;
  const convertQueryPerPage = (rows: number | undefined): 10 | 30 | 50 | 100 => {
    switch (rows) {
      case 10:
        return GetSpiritListRowsEnum.NUMBER_10;
      case 50:
        return GetSpiritListRowsEnum.NUMBER_50;
      case 100:
        return GetSpiritListRowsEnum.NUMBER_100;
      default:
        return GetSpiritListRowsEnum.NUMBER_30;
    }
  };
  const [perPage, setPerPage] = useState<10 | 30 | 50 | 100>(convertQueryPerPage(projectList?.perPage));
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [summarySpritOpen, setSummarySpritOpen] = useState<boolean>(false);
  const [summaryEmeraldOpen, setSummaryEmeraldOpen] = useState<boolean>(false);
  const [summaryJaneJohnOpen, setSummaryJaneJohnOpen] = useState<boolean>(false);
  const [summaryOtherOpen, setSummaryOtherOpen] = useState<boolean>(false);
  const [summaryId, setSummaryId] = useState<string>('');
  const [summarySns, setSummarySns] = useState<string>('');
  const [summaryProject, setSummaryProject] = useState<string>('');
  const [summaryProjectName, setSummaryProjectName] = useState<string>('');
  const [summarySpan, setSummarySpan] = useState<string>('');
  const isGoodQuantity = false;

  const { updateProjectList, projectType } = props;

  useEffect(() => {
    updateProjectList(perPage, currentPage);
  }, [perPage, currentPage, projectType, updateProjectList]);

  const headers = (): tableHeaderProps[] => {
    if (props.projectType === ProjectType.Spirit) {
      return [
        { title: 'No', width: 'w-[87px]' },
        { title: 'プロジェクト', width: 'w-[320px]' },
        { title: '案件名', width: 'w-[300px]' },
        { title: 'SNS', width: 'w-[100px]' },
        { title: '実施期間', width: 'w-[271px]' },
        { title: '', width: 'w-[57px]' },
      ];
    } else if (props.projectType === ProjectType.EmeraldPost) {
      return [
        { title: 'No', width: 'w-[87px]' },
        { title: '案件名', width: 'w-[320px]' },
        { title: 'タグ', width: 'w-[300px]' },
        { title: '実施期間', width: 'w-[271px]' },
        { title: '', width: 'w-[57px]' },
      ];
    } else if (props.projectType === ProjectType.JaneJohn) {
      return [
        { title: 'No', width: 'w-[87px]' },
        { title: 'プロジェクト', width: 'w-[320px]' },
        { title: '案件名', width: 'w-[300px]' },
        { title: 'SNS', width: 'w-[100px]' },
        { title: '実施期間', width: 'w-[271px]' },
        { title: '', width: 'w-[57px]' },
      ];
    } else if (props.projectType === ProjectType.Other) {
      return [
        { title: 'No', width: 'w-[87px]' },
        { title: 'プロジェクト', width: 'w-[300px]' },
        { title: '案件名', width: 'w-[350px]' },
        { title: '実施期間', width: 'w-[271px]' },
        { title: '', width: 'w-[57px]' },
      ];
    } else {
      return [];
    }
  };

  const handleSummaryOpen = (
    projectInfo:
      | {
          type: typeof ProjectType.Spirit;
          project: Spirit;
        }
      | {
          type: typeof ProjectType.JaneJohn;
          project: JaneJohn;
        }
      | {
          type: typeof ProjectType.Other;
          project: Other;
        }
      | {
          type: typeof ProjectType.JaneJohn;
          project: JaneJohn;
        }
  ) => {
    if (projectInfo.type === ProjectType.Spirit) {
      setSummarySpritOpen(true);
      setSummarySns(projectInfo.project.sns);
    } else if (projectInfo.type === ProjectType.JaneJohn) {
      setSummaryJaneJohnOpen(true);
      setSummarySns(projectInfo.project.sns);
      console.log(projectInfo.project.projectId);
    } else if (projectInfo.type === ProjectType.Other) {
      setSummaryOtherOpen(true);
    }
    setSummaryId(projectInfo.project.projectId);
    setSummaryProject(projectInfo.project?.projectGroupName ?? '');
    setSummarySpan(scheduleToString(projectInfo.project?.startDate, projectInfo.project?.endDate));
    setSummaryProjectName(projectInfo.project?.projectName ?? '');
  };

  TableScrollToTop();

  const replaceDate = (date: string) => {
    const newDate = date?.replace(/-/g, '/');
    return newDate;
  };

  return (
    <div className="mt-8 flow-root">
      <div className="-my-2 mx-4 w-[1133px]">
        <div className="inline-block min-w-full py-2 align-middle">
          <div
            className={clsx(
              'rounded-t-lg border-x border-t border-gray-200',
              projectList && projectList?.rows?.length ? '' : 'rounded-b-lg border-b'
            )}
          >
            <table
              className={clsx(
                'w-[1133px] divide-y divide-gray-200 rounded-t-lg bg-white',
                projectList && projectList?.rows?.length ? '' : 'rounded-b-lg'
              )}
            >
              <thead className="block">
                <tr>
                  {headers().map((header: tableHeaderProps, index: number) => {
                    return (
                      <th key={index} scope="col" className={clsx('px-6 py-4 text-left', header.width)}>
                        <span className="text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                          {header.title}
                        </span>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="block h-[413px] w-[1133px] divide-y divide-gray-200 overflow-x-hidden overflow-y-scroll bg-white">
                {props.projects && props.projects.list.rows.length > 0 ? (
                  props.projects.list.rows
                    .map((row) => {
                      switch (props.projects?.type) {
                        case ProjectType.Spirit:
                          return { type: ProjectType.Spirit, project: row as Spirit };
                        case ProjectType.Other:
                          return { type: ProjectType.Other, project: row as Other };
                        case ProjectType.JaneJohn:
                          return { type: ProjectType.JaneJohn, project: row as JaneJohn };
                        default:
                          throw new Error('Unknown project type');
                      }
                    })
                    .map(({ type, project }, index) => {
                      return (
                        <tr
                          key={index}
                          className="cursor-pointer hover:bg-gray-100"
                          onClick={() => {
                            type === ProjectType.Spirit
                              ? handleSummaryOpen({ type, project: project as Spirit })
                              : type === ProjectType.JaneJohn
                              ? handleSummaryOpen({ type, project: project as JaneJohn })
                              : handleSummaryOpen({ type, project: project as Other });
                          }}
                        >
                          <td>
                            <button className="h-[72px] w-[87px]">
                              <div className="flex items-center px-6 py-4">
                                <div>
                                  <div className="text-sm font-normal leading-5 text-gray-900">{project?.no}</div>
                                </div>
                              </div>
                            </button>
                          </td>
                          <td>
                            <button
                              className={`h-[72px] ${
                                type === ProjectType.Spirit || type === ProjectType.JaneJohn
                                  ? ' w-[320px]'
                                  : 'w-[300px]'
                              }`}
                            >
                              <div className="flex items-start px-6 py-4 text-left">
                                <div>
                                  <div className="line-clamp-2 text-sm font-normal leading-5 text-gray-900">
                                    {project?.projectGroupName}
                                  </div>
                                </div>
                              </div>
                            </button>
                          </td>
                          <td>
                            <button
                              className={`h-[72px] ${
                                type === ProjectType.Spirit || type === ProjectType.JaneJohn
                                  ? ' w-[300px]'
                                  : 'w-[350px]'
                              }`}
                            >
                              <div className="flex items-start py-4 pl-[18px] pr-6 text-left">
                                <div>
                                  <div className="line-clamp-2 text-sm font-normal leading-5 text-gray-900">
                                    {project?.projectName}
                                  </div>
                                </div>
                              </div>
                            </button>
                          </td>
                          {(type === ProjectType.Spirit || type === ProjectType.JaneJohn) && (
                            <td>
                              <div className="w-[100px] pl-[18px]">
                                {project?.sns && <div className="flex items-center py-4">{snsIcon(project?.sns)}</div>}
                              </div>
                            </td>
                          )}
                          <td>
                            <button className="h-[72px] w-[271px]">
                              <div className="flex items-center pl-[13px] pr-4">
                                <div>
                                  <div className="text-sm font-normal leading-5 text-gray-500">
                                    {project.startDate && project.endDate ? (
                                      <>
                                        {replaceDate(project.startDate)} 〜 {replaceDate(project.endDate)}
                                      </>
                                    ) : (
                                      '-'
                                    )}
                                  </div>
                                </div>
                              </div>
                            </button>
                          </td>
                          <td className="w-[57px] whitespace-nowrap px-3 py-5 text-sm">
                            <RightIcon
                              onClick={() => {
                                type === ProjectType.Spirit
                                  ? handleSummaryOpen({ type, project: project as Spirit })
                                  : type === ProjectType.JaneJohn
                                  ? handleSummaryOpen({ type, project: project as JaneJohn })
                                  : handleSummaryOpen({ type, project: project as Other });
                              }}
                              height={24}
                              width={24}
                              fill="#9CA3AF"
                            ></RightIcon>
                          </td>
                        </tr>
                      );
                    })
                ) : (
                  <tr>
                    <td colSpan={headers().length}>
                      <div className="flex h-[410px] w-[1133px] items-center justify-center">
                        <span className="text-base font-normal leading-6 text-gray-500">まだ案件がありません。</span>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {projectList && projectList?.rows?.length && (
            <Pagenation
              currentPageNumber={currentPage}
              currentPageSize={perPage}
              togalDataCount={projectList.totalRows}
              totalPageNumber={projectList.totalPages}
              setPageNumber={setCurrentPage}
              setPageSize={setPerPage}
            />
          )}
        </div>

        {props.projects?.type === ProjectType.Spirit && (
          <SpiritSummary
            open={summarySpritOpen}
            setOpen={setSummarySpritOpen}
            sns={summarySns}
            projectType={props.projectType}
            id={summaryId}
            project={summaryProject ?? ''}
            projectName={summaryProjectName ?? ''}
            span={summarySpan ?? ''}
            isGoodQuantity={isGoodQuantity}
          />
        )}
        {props.projectType === ProjectType.JaneJohn && (
          <JanejoneSummary
            open={summaryJaneJohnOpen}
            setOpen={setSummaryJaneJohnOpen}
            sns={summarySns}
            projectType={props.projectType}
            id={summaryId}
            project={summaryProject ?? ''}
            projectName={summaryProjectName ?? ''}
            span={summarySpan ?? ''}
          />
        )}
        {props.projects?.type === ProjectType.Other && (
          <OtherSummary
            open={summaryOtherOpen}
            setOpen={setSummaryOtherOpen}
            sns={summarySns}
            projectType={props.projectType}
            id={summaryId}
            project={summaryProject ?? ''}
            projectName={summaryProjectName ?? ''}
            span={summarySpan ?? ''}
          />
        )}
        {props.projectType === ProjectType.EmeraldPost && (
          <EmeraldSummary
            open={summaryEmeraldOpen}
            setOpen={setSummaryEmeraldOpen}
            sns={summarySns}
            projectType={props.projectType}
            id={summaryId}
            project={summaryProject ?? ''}
            projectName={summaryProjectName ?? ''}
            span={summarySpan ?? ''}
          />
        )}
      </div>
    </div>
  );
}
