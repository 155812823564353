import { Transition, Dialog } from '@headlessui/react';
import React, { Fragment, useState, useEffect } from 'react';

import { PostCreationAccountInput, SnsType } from '@/api/creation';
import { ReactComponent as CloseIcon } from '@/assets/icons/bx-x.svg';
import { Button } from '@/components/Elements';

import { useAccount } from './AccountContext';
import { ReactComponent as PlusCircleImage } from './assets/bx-plus-circle.svg';
import { ReactComponent as InstagramIcon } from './assets/Instagram_Glyph_Gradient_RGB.svg';
import { ReactComponent as TwitterIcon } from './assets/Twitter_icon.svg';

export const AccountListView = () => {
  const [open, setOpen] = useState<boolean>(false);
  const handleClick = () => {
    setOpen(true);
  };

  const { accounts } = useAccount();

  const [accountName, setAccountName] = useState('');
  const [type, setType] = useState<SnsType>(SnsType.Instagram);
  const { addAccount, selectedAccount, selectAccount } = useAccount();

  const handleSNSTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setType(e.target.value as SnsType);
  };

  const handleAccountNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAccountName(e.target.value);
  };

  const handleSubmit = () => {
    const newAccount: PostCreationAccountInput = {
      sns: type,
      name: accountName,
    };
    selectAccount(newAccount);
    addAccount(newAccount);
    setAccountName('');
    console.log('---AccountListView.tsx---');
    console.log('新しく作成されたアカウント（newAccount）:', newAccount);
    setOpen(false);
  };

  useEffect(() => {
    console.log('選択されたアカウント（selectedAccount）:', selectedAccount);
    console.log('作成されたアカウント全て（accounts）:', accounts);
  }, [selectedAccount, accounts]);

  const SNSAccountRegisterModal = () => {
    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#6B7280BF] bg-opacity-[/75] transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative h-[318px] w-[384px] overflow-hidden rounded-lg bg-white p-[24px]">
                  <div className="h-[270px] w-[336px] rounded-lg bg-white">
                    <div className="mb-[32px] h-[200px] w-[336px]">
                      <div className="mb-[24px] flex flex-col p-0">
                        <div className="flex flex-row items-center">
                          <div className="flex w-full flex-col">
                            <div className="flex text-center">
                              <span className="text-lg font-medium leading-6">SNSアカウント登録</span>
                              <div className="ml-auto">
                                <CloseIcon width={24} height={24} fill="#9CA3AF" onClick={() => setOpen(false)} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <form className="h-[152px] w-[312px]" action="" method="post">
                        <div className="mb-[24px] h-[66px] w-[312px]">
                          <div>
                            <label
                              htmlFor="SNSタイプ"
                              className="text-left text-sm font-semibold leading-5 text-gray-700"
                            >
                              SNSタイプ
                            </label>
                            <select
                              value={type}
                              onChange={handleSNSTypeChange}
                              className="mt-2 block w-full rounded-md border-0 px-[13px] py-[9px] ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            >
                              <option selected>選択して下さい</option>
                              <option value={SnsType.Instagram}>Instagram</option>
                              <option value={SnsType.X}>X</option>
                              <option value={SnsType.Youtube}>YouTube</option>
                              <option value={SnsType.TikTok}>TikTok</option>
                              <option value={SnsType.Other}>その他</option>
                            </select>
                          </div>
                        </div>
                        <div className="h-[62px] w-[312px]">
                          <div>
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                              アカウント名
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                value={accountName}
                                onChange={handleAccountNameChange}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="@"
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="flex flex-row items-center justify-end gap-3">
                      <Button
                        variant="white"
                        size="md"
                        className="h-[38px] w-[104px] whitespace-nowrap text-sm font-medium leading-5"
                        onClick={() => setOpen(false)}
                      >
                        キャンセル
                      </Button>
                      <Button
                        variant="primary"
                        size="md"
                        className="h-[38px] w-[90px] whitespace-nowrap text-sm font-medium leading-5"
                        onClick={handleSubmit}
                      >
                        登録する
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  return (
    <>
      <div className="ml-[16px] mt-[17px] w-[203px]">
        <div className="w-[170px]">
          {accounts.map((account) => (
            <button
              key={account.id}
              onClick={() => selectAccount({ name: account.name, sns: account.sns })}
              className={`${
                selectedAccount && account.name === selectedAccount.name
                  ? 'mb-[8px] flex items-center justify-around rounded-lg bg-gray-200 py-[8px] pl-[8px] pr-[10px]'
                  : 'mb-[8px] flex items-center justify-around rounded-lg py-[8px] pl-[8px] pr-[10px] hover:bg-gray-200'
              }`}
            >
              {account.sns === SnsType.Instagram && <InstagramIcon width={20} height={20} />}
              {account.sns === SnsType.X && <TwitterIcon width={20} height={20} />}
              <p className="w-[124px] text-sm font-medium leading-5 text-gray-900">{account.name}</p>
            </button>
          ))}
          <button onClick={handleClick}>
            <div className="mt-[8px] flex w-[147px] items-center justify-around py-[2px] pr-[17px]">
              <PlusCircleImage width={24} height={24} />
              <p className="text-sm font-medium leading-5 text-gray-400">アカウント追加</p>
            </div>
          </button>
        </div>
      </div>
      <div className="relative">{SNSAccountRegisterModal()}</div>
    </>
  );
};
